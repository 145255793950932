import React from 'react';
import { Box } from '@mui/material';
import { ResponsiveContainer } from 'recharts';

function SmallGraphContainer(props) {
    return (
        <Box sx={{ width: props.width, height: props.height, ...props.sx }} style={{ userSelect: "none" }} >
            <ResponsiveContainer width="100%" height={props.overflow ? props.height : "100%"}>
                {props.children}
            </ResponsiveContainer>        
        </Box>);
}

export default SmallGraphContainer;