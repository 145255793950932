import { useEffect, useRef, useReducer } from 'react'

const domain = process.env.REACT_APP_API_URL || 'https://nlazuprd646app02.azurewebsites.net/api'

export const fetchFromApi = async ({queryKey}) => {
    const response = await fetch(domain + queryKey)
    if (!response.ok) {
        throw new Error('Network response was not ok')
    }

    return response.json()
}