import { Box, CircularProgress } from "@mui/material";
import { Outlet, useNavigate, useParams } from "react-router";
import { useApi } from "../../util/useApiHook";
import { fetchFromApi, useFetch } from "../../util/fetchFromApi";
import { useQueries, useQuery } from "react-query";
import LoadingPage from "../LoadingPage";

function PageLayout() {
    // Throw user back to home page if not using a valid gameID
    const { gameId } = useParams()
    const navigate = useNavigate()

    const { status, data: gameStatus } = useApi(`/status/${gameId}`)
    if (status === "fetched" && !gameStatus.game_status) { navigate('/error') }

    // Pre-load all data
    const result = useQueries([
        { queryKey: `/info/origins/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/destinations/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/warehouses/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/regions/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/storetypes/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/products/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/suppliers/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/materials/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/plants/${gameId}`, queryFn: fetchFromApi },
        { queryKey: `/info/stores/${gameId}`, queryFn: fetchFromApi },

        { queryKey: `/dynfulfillment/move/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/dynfulfillment/move/${gameId}/summary/originID`, queryFn: fetchFromApi },
        { queryKey: `/dynfulfillment/move/${gameId}/summary/destID`, queryFn: fetchFromApi },
        { queryKey: `/dynfulfillment/warehouses/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/dynfulfillment/warehouses/${gameId}/summary/whID`, queryFn: fetchFromApi },
        { queryKey: `/dynfulfillment/warehouses/${gameId}/summary/whRegion`, queryFn: fetchFromApi },
        { queryKey: `/execoverview/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/execoverview/${gameId}/summary/region`, queryFn: fetchFromApi },
        { queryKey: `/execoverview/${gameId}/summary/storeType`, queryFn: fetchFromApi },
        { queryKey: `/execoverview/${gameId}/summary/finishedGoodName`, queryFn: fetchFromApi },
        { queryKey: `/intelligentsupply/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/intelligentsupply/${gameId}/summary/supplierID`, queryFn: fetchFromApi },
        { queryKey: `/intelligentsupply/${gameId}/summary/rawMaterialName`, queryFn: fetchFromApi },
        { queryKey: `/intelligentsupply/${gameId}/summary/finishedGoodName`, queryFn: fetchFromApi },
        { queryKey: `/smartfactory/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/smartfactory/${gameId}/summary/region`, queryFn: fetchFromApi },
        { queryKey: `/smartfactory/${gameId}/summary/finishedGoodName`, queryFn: fetchFromApi },
        { queryKey: `/sustainability/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/syncplanning/${gameId}/summary`, queryFn: fetchFromApi },
        { queryKey: `/syncplanning/${gameId}/summary/region`, queryFn: fetchFromApi },
        { queryKey: `/syncplanning/${gameId}/summary/finishedGoodName`, queryFn: fetchFromApi },
    ])

    let isLoading = false
    result.forEach(result => {
        isLoading |= result.isLoading
    })

    return <Box id="PageLayoutBox" sx={{display: 'flex', flexDirection: 'column', height: '100%'}}>
        <Outlet context={{isLoading: isLoading}} />
    </Box>
}

export default PageLayout;