import { useEffect, useState } from "react";
import { Box, styled, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import KPIs from "../../components/KPIs";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import IconColumn from "../../components/StyledElements/IconColumn";

import { ReactComponent as PlanningIcon } from "../../assets/icons/IconMake1.svg"
import { ReactComponent as PerformanceIcon } from "../../assets/icons/IconMake2.svg"
import { ReactComponent as QualityIcon } from "../../assets/icons/IconMake3.svg"
import { ReactComponent as ManufacturingIcon } from "../../assets/icons/IconMake4.svg"
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { relativeDiff } from "../../util/mathOperations";
import { fromInterval } from "../../util/dataOperations";

const ContentBox = styled(Box)({
    height: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-evenly'
})

function SmartFactorySummary() {
    const { latestYear, currYear, currQuarter, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 4;

    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const summaryLast8Quarters = last8Quarters(aggregatedData.summary) || []

    const prodPlanning = <GridColumnContent fullHeight>
        {/* Availability */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Availability
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).oeeAvailability*100, format: (value) => `${value}%`, decimals: 1, fontSize: 25}}
                        lastQuarter={{
                            value: (summaryLast8Quarters.at(-1).oeeAvailability - summaryLast8Quarters.at(-2).oeeAvailability)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (summaryLast8Quarters.at(-1).oeeAvailability - summaryLast8Quarters.at(-5).oeeAvailability)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Planning stability */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Planning stability
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).planningAdherence, format: (value) => `${value}`, fontSize: 25, subText: 'changes'}}
                        lastQuarter={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).planningAdherence, summaryLast8Quarters.at(-2).planningAdherence)*100, 
                            format: (value) => `${value}%`
                        }} 
                        lastYear={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).planningAdherence, summaryLast8Quarters.at(-5).planningAdherence)*100, 
                            format: (value) => `${value}%`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const performance = <GridColumnContent fullHeight>
        {/* Overall Equipment Effectiveness */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Overall equipment effectiveness
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).oeeKPI*100, format: (value) => `${value}%`, decimals: 1, fontSize: 25}}
                        lastQuarter={{
                            value: (summaryLast8Quarters.at(-1).oeeKPI - summaryLast8Quarters.at(-2).oeeKPI)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (summaryLast8Quarters.at(-1).oeeKPI - summaryLast8Quarters.at(-5).oeeKPI)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Cycle time */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Cycle time
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).cycleTime, format: (value) => `${value}`, subText: 'units/hour', fontSize: 25}}
                        lastQuarter={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).cycleTime, summaryLast8Quarters.at(-2).cycleTime)*100, 
                            format: (value) => `${value}%`
                        }} 
                        lastYear={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).cycleTime, summaryLast8Quarters.at(-5).cycleTime)*100, 
                            format: (value) => `${value}%`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const quality = <GridColumnContent fullHeight>
        {/* Quality */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Quality
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).oeeQuality*100, format: (value) => `${value}%`, decimals: 1, fontSize: 25}}
                        lastQuarter={{
                            value: (summaryLast8Quarters.at(-1).oeeQuality - summaryLast8Quarters.at(-2).oeeQuality)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (summaryLast8Quarters.at(-1).oeeQuality - summaryLast8Quarters.at(-5).oeeQuality)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Empty space */}
    </GridColumnContent>

    const manufacturing = <GridColumnContent fullHeight>
        {/* Production costs */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Production costs
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} inversed
                        average={{value: summaryLast8Quarters.at(-1).costsManufacturingTotal/1000000000, format: (value) => `\u20AC${value}B`, decimals: 2, fontSize: 25}}
                        lastQuarter={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsManufacturingTotal, summaryLast8Quarters.at(-2).costsManufacturingTotal)*100, 
                            format: (value) => `${value}%`
                        }} 
                        lastYear={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsManufacturingTotal, summaryLast8Quarters.at(-5).costsManufacturingTotal)*100, 
                            format: (value) => `${value}%`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Unit production costs */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                    Unit production costs
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} inversed
                        average={{value: summaryLast8Quarters.at(-1).costsUnitProduction, format: (value) => `\u20AC${value}`, decimals: 2, fontSize: 25}}
                        lastQuarter={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsUnitProduction, summaryLast8Quarters.at(-2).costsUnitProduction)*100, 
                            format: (value) => `${value}%`
                        }} 
                        lastYear={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsUnitProduction, summaryLast8Quarters.at(-5).costsUnitProduction)*100, 
                            format: (value) => `${value}%`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Production planning */}
        <GridItem>
            <IconColumn
                icon={<PlanningIcon height='100%' width='100%' />}
                title={"Production planning"}
                kpis={prodPlanning}
            />
        </GridItem>
        {/* Performance */}
        <GridItem>
            <IconColumn
                icon={<PerformanceIcon height='100%' width='100%' />}
                title={"Performance"}
                kpis={performance}
            />
        </GridItem>
        {/* Quality control */}
        <GridItem>
            <IconColumn
                icon={<QualityIcon height='100%' width='100%' />}
                title={"Quality control"}
                kpis={quality}
            />
        </GridItem>
        {/* Manufacturing costs */}
        <GridItem>
            <IconColumn
                icon={<ManufacturingIcon height='100%' width='100%' />}
                title={"Manufacturing costs"}
                kpis={manufacturing}
            />
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

export default SmartFactorySummary;