import { Box, lighten } from "@mui/material";

const CustomPaper = (props) =>
<Box 
    {...props} 
    sx={{ 
        height: `100%`, 
        width: `100%`, 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "space-between", 
        padding: props.padding, 
        position: "relative",
        borderRadius: props.padding,
        backgroundColor: "background.paper",
        ...props.sx
    }} 
/>

export default CustomPaper