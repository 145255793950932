import { Box } from "@mui/material";

const GridContainer = (props) =>
<Box
    sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${props.columns}, 1fr)`,
        columnGap: props.spacing,
        rowGap: props.spacing,
        height: "100%"
    }}
    {...props} 
/>

export default GridContainer