import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tooltip, Legend, ComposedChart, YAxis } from 'recharts';
import SmallGraphContainer from './SmallGraphContainer';

function SmallGraph(props) {
    
    const [ data, setData ] = useState([]);

    useEffect(() => {
        setData(props.data);
    }, [props.data]);

    if (!data || !data.length || Object.keys(data[0]).length <= 1) {
        return <Box>
            <CircularProgress />
        </Box>
    }
    let yInterval = props.yInterval 
        || (props.noBottomScale ? 
            [0, dataMax => (dataMax * 1.2)] 
        : 
            ([dataMin, dataMax]) => { return [dataMin - (dataMax+dataMin)*0.1, dataMax + (dataMax+dataMin)*0.1] });

    return (
        <SmallGraphContainer width={props.width} height={props.minBarSize ? props.data.length >= 20 ? props.data.length * props.minBarSize : props.height : props.height} overflow={props.overflow}>
            <ComposedChart
                data={data}
                {...props}
            >
                {!props.noYAxis ? <YAxis hide allowDataOverflow domain={yInterval} type="number" /> : null}

                {!props.hideLegend ? <Legend iconSize={10} /> : null}

                {props.children}
            </ComposedChart>
        </SmallGraphContainer>
    )
}

export default SmallGraph;