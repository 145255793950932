import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import { useApi } from "../../util/useApiHook";
import { sum, mean } from "../../util/arrayOperations";
import { Box, CircularProgress } from "@mui/material";
import NoDataMessage from "../../components/NoDataMessage";
import { aggregateCategorizedData, aggregateData, filterCategorizedData, filterData, filterInfo } from "../../util/dataOperations";
import { useQuery } from "react-query";
import { fetchFromApi } from "../../util/fetchFromApi";
import LoadingPage from "../LoadingPage";

const filterSkeleton = JSON.stringify([
    {
        type: "finishedGoodName",
        label: "finishedGoodName",
        name: "Product Group",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "rawMaterialName",
        label: "rawMaterialName",
        name: "Material",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "supplierRegion",
        label: "supplierRegion",
        name: "Region",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "supplierCountry",
        label: "supplierCountry",
        name: "Country",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "supplierID",
        label: "supplierName",
        name: "Supplier",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
])

function IntelligentSupplyLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Get all needed data for this department
    const {gameId} = useParams()

    // Get info on types
    const {data: supplierInfo} = useQuery(`/info/suppliers/${gameId}`, fetchFromApi)
    const {data: materialInfo} = useQuery(`/info/materials/${gameId}`, fetchFromApi)
    const {data: productInfo} = useQuery(`/info/products/${gameId}`, fetchFromApi)
    // Get transactional data
    const {data: dataSummary} = useQuery(`/intelligentsupply/${gameId}/summary`, fetchFromApi)
    const {data: dataBySupplier} = useQuery(`/intelligentsupply/${gameId}/summary/supplierID`, fetchFromApi)
    const {data: dataByMaterial} = useQuery(`/intelligentsupply/${gameId}/summary/rawMaterialName`, fetchFromApi)
    const {data: dataByProduct} = useQuery(`/intelligentsupply/${gameId}/summary/finishedGoodName`, fetchFromApi)

    let initialYear = null
    let latestYear = null
    let latestQuarter = null

    // Handle incoming data
    if (!isLoading) {
        initialYear = dataSummary[0].gameYear
        latestYear = dataSummary.at(-1).gameYear
        latestQuarter = (dataSummary.at(-1).gameRound - 1) % 4 + 1
    }

    // Set correct paths to subpages
    const tabs = [
        {link: `/${gameId}/intelligentsupply/summary`, name: "Summary"},
        {link: `/${gameId}/intelligentsupply/spend`, name: "Spend"},
        {link: `/${gameId}/intelligentsupply/supplier`, name: "Supplier performance"},
        {link: `/${gameId}/intelligentsupply/materials`, name: "Raw materials"},
    ]

    const [currYear, setCurrYear] = useState(latestYear);
    
    // Filters for synchronized planning
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }

    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }

    const initialFilters = {
        finishedGoodName: 'ALL',
        rawMaterialName: 'ALL', 
        supplierRegion: 'ALL',
        supplierCountry: 'ALL',
        supplierID: 'ALL', 
    }
    
    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredSupplierInfo, setFilteredSupplierInfo] = useState({})
    const [filteredDataSummary, setFilteredDataSummary] = useState([])
    const [filteredDataBySupplier, setFilteredDataBySupplier] = useState({})
    const [filteredDataByMaterial, setFilteredDataByMaterial] = useState({})
    const [filteredDataByProduct, setFilteredDataByProduct] = useState({})
    
    useEffect(() => {
        if (isLoading) return

        let newFilters = JSON.parse(filterSkeleton)
        let temp = []
        Object.values(productInfo).forEach((product) => {
            newFilters[0].options.push({value: product[newFilters[0].type], label: product[newFilters[0].label]})
        })

        Object.values(materialInfo).forEach((material) => {
            newFilters[1].options.push({value: material[newFilters[1].type], label: material[newFilters[1].label]})
        })

        temp = []
        Object.values(supplierInfo).forEach( supplier => {
            if (!temp.includes(supplier[newFilters[2].type])) {
                newFilters[2].options.push({value: supplier[newFilters[2].type], label: supplier[newFilters[2].label]})
                temp.push(supplier[newFilters[2].type])
            }
        })

        temp = []
        if (currFilters[newFilters[2].type] !== 'ALL') {
            Object.values(filteredSupplierInfo).forEach( supplier => {
                if (!temp.includes(supplier[newFilters[3].type])) {
                    newFilters[3].options.push({value: supplier[newFilters[3].type], label: supplier[newFilters[3].label]})
                    temp.push(supplier[newFilters[3].type])
                }
            })
        }

        temp = []
        if (currFilters[newFilters[3].type] !== 'ALL') {
            Object.values(filteredSupplierInfo).forEach( supplier => {
                if (!temp.includes(supplier[newFilters[4].type])) {
                    newFilters[4].options.push({value: supplier[newFilters[4].type], label: supplier[newFilters[4].label]})
                    temp.push(supplier[newFilters[4].type])
                }
            })
        }

        setFilters(newFilters)
    }, [currFilters, productInfo, materialInfo, supplierInfo, filteredSupplierInfo])

    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return

        setFilteredSupplierInfo(filterInfo(supplierInfo, currFilters))
    }, [supplierInfo, currFilters])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return
        
        setCurrYear(latestYear)
        setFilteredDataSummary(filterData(dataSummary, currFilters))
        setFilteredDataBySupplier(filterCategorizedData(dataBySupplier, currFilters))
        setFilteredDataByMaterial(filterCategorizedData(dataByMaterial, currFilters))
        setFilteredDataByProduct(filterCategorizedData(dataByProduct, currFilters))
    }, [latestYear, dataSummary, dataBySupplier, dataByMaterial, dataByProduct, currFilters])

    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
            if (type === filters[2].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[3].type]: 'ALL', [filters[4].type]: 'ALL'})
            } else if (type === filters[3].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[4].type]: 'ALL'})
            }
        }
    };
    
    const filterReset = () => {
        setCurrYear(latestYear)
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"intelligentsupply"} departmentName={"Intelligent Supply"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters timeFilter={timeFilter} timeValue={currYear} filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            filteredData: {summary: filteredDataSummary, bySupplier: filteredDataBySupplier, byMaterial: filteredDataByMaterial, byProduct: filteredDataByProduct}, 
                            aggregatedData: {
                                summary: aggregateData(filteredDataSummary, aggFunctions), 
                                bySupplier: aggregateCategorizedData(filteredDataBySupplier, aggFunctions), 
                                byMaterial: aggregateCategorizedData(filteredDataByMaterial, aggFunctions),
                                byProduct: aggregateCategorizedData(filteredDataByProduct, aggFunctions)
                            }
                        }} />
                    :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const aggFunctions = {
    'supplierQuality': 'mean',
    'supplierReliabilityInFull': 'mean',
    'supplierReliabilityOnTime': 'mean',
    'supplierReliabilityOTIF': 'mean',
    'isContractSpend': 'sum',
    'isMaverickSpend': 'sum',
    'costsRmTotalSpend': 'sum',
    'extraPercMaverickCosts': 'mean',
    'rmDOH': 'mean',
}

export default IntelligentSupplyLayout;