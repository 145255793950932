import { useEffect, useState } from "react";
import { Box, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, Cell, LabelList, ReferenceLine, XAxis, YAxis } from "recharts";
import { relativeDiff } from "../../util/mathOperations";

import MainContentBox from "../../components/StyledElements/MainContentBox";
import { yearToDate } from "../../util/dataOperations";

function ExecutiveViewWaterfall() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();
    
    const theme = useTheme();

    const data = getWaterfallData(aggregatedData.summary, currQuarter)
    
    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // Get colors from theme
    const colors = theme.palette.graphColors

    const CustomTick = (props) => {
        let textY = props.y+15
        let strings = props.payload.value.split(';')

        return <text 
            textAnchor="middle" 
            y={textY} 
            fill={['Gross sales', 'Net sales', 'Net profit'].includes(props.payload.value) ? data[props.index].value > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail : theme.palette.text.secondary} 
            fontWeight={['Gross sales', 'Net sales', 'Net profit'].includes(props.payload.value) ? 700 : 500}
        >
            {strings.map( (string, index) => <tspan key={`tick-${index}`} x={props.x} y={textY+15*index}>{string}</tspan>)}
        </text>
    }

    const AxisLabel = (props) => {
        let yNote = props.yNote
        let yTarget = props.yTarget
        let yLastYear = props.yLastYear

        return <text 
            {...props}
            fill={theme.palette.text.secondary} 
        >
            <tspan x={props.viewBox.x + props.viewBox.width} y={yNote} textAnchor="end">YTD in &euro;B</tspan>
            <tspan x={props.viewBox.x + props.viewBox.width} y={yTarget} textAnchor="end">vs. target</tspan>
            <tspan x={props.viewBox.x + props.viewBox.width} y={yLastYear} textAnchor="end">vs. last year</tspan>
        </text>
    }

    const ChangeLabel = (props) => {
        let y = props.yOffset

        return <text 
            fontSize={props.fontSize}
            y={y} 
            fill={props.value > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail} 
        >
            <tspan x={props.x+props.width/2} y={y} textAnchor="middle">{props.value === Infinity ? '' : props.formatter(`${props.value > 0 ? '+' : ''}${(props.value*100).toFixed(1)}`)}</tspan>
        </text>
    }

    const WaterfallBar = (props) => {
        const {name, value, start, partOfSales, width, height, x, y, fill} = props
        const valueToSize = (val) => height/Math.abs(value) * val
        const yAxisHeight = value > 0 ? y + height : y

        return <>
            <rect 
                x={x}
                y={yAxisHeight - valueToSize(start+value)}
                width={width}
                height={valueToSize(value)}
                fill={fill}
            />
            <text
                x={x + width/2}
                y={partOfSales !== undefined ? yAxisHeight - valueToSize(start+value/2) - 5 : yAxisHeight - valueToSize(start+value/2) + 5}
                dy={height < 40 ? -(valueToSize(value/2) + 20) : 0 }
                fill={height < 40 ? theme.palette.text.secondary : colors[3].contrastText}
                textAnchor="middle"
                fontSize={15}
            >
                {`${(value/1000000000).toFixed(1)}`}
                {partOfSales !== undefined ?
                    <tspan
                        x={x + width/2}
                        dy={17.5}                
                    >
                        {`${(partOfSales*100).toFixed(1)}%`}{['Returns', 'Discounts'].includes(name) ? '**' : '*'}
                    </tspan>
                : null}
            </text>
        </>
    }

    const yNote = 20
    const yTarget = 50 // height of quarter difference
    const yLastYear = 80 // height of year difference

    const waterfall = <Box sx={{height: '100%'}}>
        <SmallGraph 
            key={`${key}-Waterfall`} 
            width={'100%'} 
            height={'100%'}  
            data={data} 
            margin={{
                top: 50,
                right: 0,
                left: 80,
                bottom: 30,
            }}
            stackOffset="sign"
        >
            <XAxis allowDataOverflow dataKey="name" xAxisId={0} type="category" interval={0} tick={<CustomTick />} />
            <XAxis hide allowDataOverflow dataKey="nothing" xAxisId={1} type="category" interval={0}/>
            <YAxis yAxisId={1} label={<AxisLabel yNote={yNote} yTarget={yTarget} yLastYear={yLastYear} />} axisLine={false} tickLine={false} tick={false} />
            <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
            {/* Visible bar */}
            <Bar legendType="none" dataKey={`value`} shape={<WaterfallBar />} xAxisId={0} animationDuration={1000}>
                {data.map( entry => 
                    <Cell key={`cell-${entry.name}`} fill={entry.start !== 0 ? theme.palette.grey[500] : entry.value > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail} />
                )}
            </Bar>
            <Bar legendType="none" dataKey={'nothing'} xAxisId={1} animationDuration={0}>
                {/* Previous quarter */}
                <LabelList dataKey={`relativeTarget`} position="middle" content={<ChangeLabel yOffset={yTarget} />} formatter={(value) => `${value}%`} fontSize={15} />
                {/* Previous year */}
                <LabelList dataKey={`prevYearChange`} position="middle" content={<ChangeLabel yOffset={yLastYear} />} formatter={(value) => `${value}%`} fontSize={15} />
            </Bar>
            <g transform="translate(0, -5)">
                <text transform="translate(0, -40)" fontSize={12} fill={theme.palette.text.secondary}>
                    <tspan x='20' y='100%' textAnchor="end">* </tspan>
                    <tspan x='20' y='100%' textAnchor="start">relative to Net sales</tspan>
                </text>
                <text transform="translate(0, -20)" fontSize={12} fill={theme.palette.text.secondary}>
                    <tspan x='20' y='100%' textAnchor="end">** </tspan>
                    <tspan x='20' y='100%' textAnchor="start">relative to Gross sales</tspan>
                </text>
                <text fontSize={12} fill={theme.palette.text.secondary}>
                    <tspan x='20' y='100%' textAnchor="end">*** </tspan>
                    <tspan x='20' y='100%' textAnchor="start">Selling general and administrative costs</tspan>
                </text>
            </g>
        </SmallGraph>
    </Box>

    return <MainContentBox>
        {waterfall}
    </MainContentBox>
}

const getWaterfallData = (data, currQuarter) => {
    if (!data || data.length <= 0) return []

    const dataToReturn = [
        { 
            name: 'Gross sales', 
            value: yearToDate(data, 'grossSales', currQuarter), 
            prevYear: yearToDate(data, 'grossSales', currQuarter, -4), 
            target: null,
        },
        { 
            name: 'Returns', 
            value: yearToDate(data, 'returnCosts', currQuarter),
            prevYear: yearToDate(data, 'returnCosts', currQuarter, -4),
            target: null,
        },
        { 
            name: 'Discounts', 
            value: yearToDate(data, 'discountCosts', currQuarter),
            prevYear: yearToDate(data, 'discountCosts', currQuarter, -4),
            target: null,
        },
        { 
            name: 'Net sales', 
            value: yearToDate(data, 'revenueSales', currQuarter),
            prevYear: yearToDate(data, 'revenueSales', currQuarter, -4),
            target: yearToDate(data, 'targetRevenueSales', currQuarter),
        },
        { 
            name: 'Raw;material;costs', 
            value: yearToDate(data, 'costsRmTotalSpend', currQuarter),
            prevYear: yearToDate(data, 'costsRmTotalSpend', currQuarter, -4),
            target: null,
        },
        { 
            name: 'Manufacturing;costs', 
            value: yearToDate(data, 'costsManufacturingTotal', currQuarter),
            prevYear: yearToDate(data, 'costsManufacturingTotal', currQuarter, -4),
            target: null,
        },
        { 
            name: 'Transport;costs', 
            value: yearToDate(data, 'costsTransport', currQuarter),
            prevYear: yearToDate(data, 'costsTransport', currQuarter, -4),
            target: null,
        },
        { 
            name: 'Warehouse;costs',
            value: yearToDate(data, 'whCosts', currQuarter),
            prevYear: yearToDate(data, 'whCosts', currQuarter, -4),
            target: null,
        }, 
        { 
            name: 'SGA;costs***',
            value: yearToDate(data, 'costsMarketingSalesOtherOverhead', currQuarter),
            prevYear: yearToDate(data, 'costsMarketingSalesOtherOverhead', currQuarter, -4),
            target: null,
        },
        { 
            name: 'EBITA',
            value: yearToDate(data, 'netProfit', currQuarter),
            prevYear: yearToDate(data, 'netProfit', currQuarter, -4),
            target: null,
        }
    ]

    const grossSales = yearToDate(data, 'grossSales', currQuarter)
    const netSales = yearToDate(data, 'revenueSales', currQuarter)

    for (let i = dataToReturn.length-1; i >= 0; i--) {
        let entry = dataToReturn.at(i)
        let sales = ['Gross sales', 'Returns', 'Discounts'].includes(entry.name) ? grossSales : netSales
        entry.start = ['Gross sales', 'Net sales', 'EBITA'].includes(entry.name) ? 0 : dataToReturn.at(i+1).start + dataToReturn.at(i+1).value
        entry.prevYearChange = relativeDiff(entry.value, entry.prevYear)
        entry.partOfSales = entry.value/sales
        entry.relativeTarget = relativeDiff(entry.value, entry.target)
    }

    return dataToReturn
}

export default ExecutiveViewWaterfall;