export function sum(array) {
    if (array.length === 0) return 0
    return array.reduce((a,b)=>a+b)
}

export function mean(array, weights=null) {
    if (array.length === 0) return 0

    if (weights) {
        let weightedSum = 0
        array.forEach((element, index) => {
            weightedSum += element * weights[index]
        });
        return weightedSum/sum(weights)
    } else {
        let sum = 0
        array.forEach((element) => {
            sum += element
        });
        return sum/array.length
    }
}

export function prepend(array, value) {
    let newArray = array.slice()
    newArray.unshift(value)
    return newArray
}