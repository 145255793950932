import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, ReferenceLine, XAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategorySingleQuarter, getLastYear } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function IntelligentSupplySupplier() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }

    const materials = Object.keys(aggregatedData.byMaterial)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const oeeData = getLastYear(fromLastYearData, 'supplierReliabilityOTIF', currQuarter, currYear, 0, 100)
    const reliability = <GridColumnContent>
        {/* OEE evolution */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-OEE`} width={'100%'} height={'80%'} data={oeeData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={oeeData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={oeeData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* OEE by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By raw material <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-OEEByProduct`} 
                    width={'100%'} 
                    height={'80%'} 
                    margin={{left: 10, right: 10}}
                    data={getByCategorySingleQuarter(last8Quarters(aggregatedData.byMaterial), 'supplierReliabilityOTIF', materials, 0, 100)}
                >
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" angle={340} interval={0} fontSize={10} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {/* Bar of current year */}
                    <Bar name={`Last quarter`} dataKey={`prevQuarter`} xAxisId={'axis0'} maxBarSize={60} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`prevQuarter`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                    </Bar>
                    {/* Diamond to indicate previous year */}
                    <Bar 
                        name={`Last year`} 
                        dataKey={`prevYear`} 
                        xAxisId={'axis1'}
                        maxBarSize={60}
                        fill={theme.palette.grey[700]} 
                        fillOpacity={0.8}
                        animationDuration={1000} 
                        legendType='none'
                        shape={(props) => renderDiamond(props, theme)} 
                        />
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const onTimeData = getLastYear(fromLastYearData, 'supplierReliabilityOnTime', currQuarter, currYear, 0, 100)
    const onTime = <GridColumnContent>
        {/* Supplier on-time */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-OnTime`} width={'100%'} height={'80%'} data={onTimeData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={onTimeData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={onTimeData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Supplier on-time by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By raw material <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-OnTimeByProduct`} 
                    width={'100%'} 
                    height={'80%'} 
                    margin={{left: 10, right: 10}}
                    data={getByCategorySingleQuarter(last8Quarters(aggregatedData.byMaterial), 'supplierReliabilityOnTime', materials, 0, 100)} 
                >
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" angle={340} interval={0} fontSize={10} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {/* Bar of current year */}
                    <Bar name={`Last quarter`} dataKey={`prevQuarter`} xAxisId={'axis0'} maxBarSize={60} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`prevQuarter`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                    </Bar>
                    {/* Diamond to indicate previous year */}
                    <Bar 
                        name={`Last year`} 
                        dataKey={`prevYear`} 
                        xAxisId={'axis1'}
                        maxBarSize={60}
                        fill={theme.palette.grey[700]} 
                        fillOpacity={0.8}
                        animationDuration={1000} 
                        legendType='none'
                        shape={(props) => renderDiamond(props, theme)} 
                        />
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const inFullData = getLastYear(fromLastYearData, 'supplierReliabilityInFull', currQuarter, currYear, 0, 100)
    const inFull = <GridColumnContent>
        {/* Supplier in-full */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-InFull`} width={'100%'} height={'80%'} data={inFullData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={inFullData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={inFullData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Supplier in-full by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By raw material <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-InFullByProduct`} 
                    width={'100%'} 
                    height={'80%'} 
                    margin={{left: 10, right: 10}}
                    data={getByCategorySingleQuarter(last8Quarters(aggregatedData.byMaterial), 'supplierReliabilityInFull', materials, 0, 100)}
                >
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" angle={340} interval={0} fontSize={10} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {/* Bar of current year */}
                    <Bar name={`Last quarter`} dataKey={`prevQuarter`} xAxisId={'axis0'} maxBarSize={60} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`prevQuarter`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                    </Bar>
                    {/* Diamond to indicate previous year */}
                    <Bar 
                        name={`Last year`} 
                        dataKey={`prevYear`} 
                        xAxisId={'axis1'}
                        maxBarSize={60}
                        fill={theme.palette.grey[700]} 
                        fillOpacity={0.8}
                        animationDuration={1000} 
                        legendType='none'
                        shape={(props) => renderDiamond(props, theme)} 
                        />
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Supplier reliability (OTIF) */}
        <GridItem>
            <CardTitle title="Supplier reliability (OTIF)" />
            {reliability}
        </GridItem>
        {/* Supplied on-time */}
        <GridItem>
            <CardTitle title="Supplied on-time" />
            {onTime}
        </GridItem>
        {/* Supplied in-fulle */}
        <GridItem>
            <CardTitle title="Supplied in-full" />
            {inFull}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

export default IntelligentSupplySupplier;