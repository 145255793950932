import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import NoDataMessage from "../../components/NoDataMessage";
import { aggregateData, filterData } from "../../util/dataOperations";
import LoadingPage from "../LoadingPage";
import { fetchFromApi } from "../../util/fetchFromApi";
import { useQuery } from "react-query";

const filterSkeleton = JSON.stringify([
    {
        type: "finishedGoodName",
        label: "finishedGoodName",
        name: "Product Group",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    }
])

function SustainabilityLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Fetch data for synchronized planning
    const { gameId } = useParams()

    // Get info on types
    const {data: productInfo} = useQuery(`/info/products/${gameId}`, fetchFromApi)
    // Get transactional data
    const {status: summaryStatus, data: dataSummary} = useQuery(`/sustainability/${gameId}/summary`, fetchFromApi)

    let initialYear = null
    let latestYear = null
    let latestQuarter = null

    // Handle incoming data
    if (!isLoading) {
        initialYear = dataSummary[0].gameYear
        latestYear = dataSummary.at(-1).gameYear
        latestQuarter = (dataSummary.at(-1).gameRound - 1) % 4 + 1
    }

    const [currYear, setCurrYear] = useState(latestYear);

    // Set correct paths to subpages
    // TODO add paths when available
    const tabs = [
        {link: `/${gameId}/sustainability/summary`, name: "Summary"},
    ]
    
    // Filters for sustainability
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }

    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }

    const initialFilters = {
        finishedGoodName: 'ALL'
    }
    
    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredDataSummary, setFilteredDataSummary] = useState([])
    
    useEffect(() => {
        if (isLoading) return
        
        let newFilters = JSON.parse(filterSkeleton)

        Object.values(productInfo).forEach((product) => {
            newFilters[0].options.push({value: product[newFilters[0].type], label: product[newFilters[0].label]})
        })

        setFilters(newFilters)
    }, [currFilters, productInfo])

    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return
        
        setCurrYear(latestYear)
        setFilteredDataSummary(filterData(dataSummary, currFilters))
    }, [latestYear, dataSummary, currFilters])

    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
        }
    };
    
    const filterReset = () => {
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"sustainability"} departmentName={"Supply chain sustainability"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            filteredData: {summary: filteredDataSummary}, 
                            aggregatedData: {
                                summary: aggregateData(filteredDataSummary, aggFunctions)
                            }
                        }} />
                    :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const aggFunctions = {
    'co2unit_total': 'mean',
    'co2unit_scope1': 'mean',
    'co2unit_scope2': 'mean',
    'co2unit_scope3': 'mean',
    'co2unit_target2030': 'mean',
    'co2unit_target2050': 'mean',
    'renewableEnergy_total': 'mean',
    'renewableEnergy_scope1': 'mean',
    'renewableEnergy_scope2': 'mean',
    'renewableEnergy_scope3': 'mean',
    'renewableEnergy_target2030': 'mean',
    'renewableEnergy_target2050': 'mean',
    'socialSustainability': 'mean',
    'socialSustainability_target2030': 'mean',
    'socialSustainability_target2050': 'mean',
    'transportModalitySplit_barge': 'mean',
    'transportModalitySplit_rail': 'mean',
    'transportModalitySplit_road': 'mean',
    'transportModalitySplit_air': 'mean',
    'waterUsage_total': 'mean',
    'waterUsage_scope1': 'mean',
    'waterUsage_scope2': 'mean',
    'waterUsage_scope3': 'mean',
    'waterUsage_target2030': 'mean',
    'waterUsage_target2050': 'mean',
    'recycledWaste': 'mean',
    'recycledWaste_target2030': 'mean',
    'recycledWaste_target2050': 'mean',
    'CO2footprintTarget2030': 'sum',
    'CO2footprintTarget2050': 'sum',
    'recycledWasteTarget2030': 'sum',
    'recycledWasteTarget2050': 'sum',
    'socialSustainabilityTarget2030': 'sum',
    'socialSustainabilityTarget2050': 'sum',
    'cleanWaterTarget2030': 'sum',
    'cleanWaterTarget2050': 'sum',
}

export default SustainabilityLayout;