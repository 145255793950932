import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import KPIs from "../../components/KPIs";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, ReferenceLine, XAxis } from "recharts";
import ServiceLevelDonutGraph from "../../components/graphs/ServiceLevelDonutGraph";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { relativeDiff } from "../../util/mathOperations";
import { mean } from "../../util/arrayOperations";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";
import { getLastYear } from "../../util/dataOperations";

function SynchronizedPlanningSummary() {
    const { currFilters, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const kpiData = getKpiData(aggregatedData.summary)

    const forecastAccuracyData = getLastYear(fromLastYearData, 'forecastAccuracy', currQuarter, currYear)
    let demand = <GridColumnContent>
        {/* Forecast accuracy */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'30%'}>
                    <Typography variant="body2" align="left">
                        Forecast accuracy <i>(%)</i>
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: kpiData.forecastAccuracy.average*100, decimals: 1, format: (value) => `${value}%`}} 
                        lastYear={{value: kpiData.forecastAccuracy.change*100, format: (value) => `${value}%pt`}} 
                    />
                </Box>
                <SmallGraph key={`${key}-ForecastAccuracy`} width={'100%'} height={'70%'} data={forecastAccuracyData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} dot={true} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={forecastAccuracyData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} dot={true} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={forecastAccuracyData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Forecast bias */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'30%'}>
                    <Typography variant="body2" align="left">
                        Forecast bias <i>(%)</i>
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} 
                        average={{value: kpiData.forecastBias.average*100, decimals: 1, format: (value) => `${value}%`}} 
                        lastYear={{value: kpiData.forecastBias.change*100, format: (value) => `${value}%pt`}} 
                    />
                </Box>
                <SmallGraph key={`${key}-ForecastBias`} width={'100%'} height={'70%'} data={getLastYear(fromLastYearData, 'forecastBias', currQuarter, currYear, -100)}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    <Bar name={`${currYear}`} dataKey={`${currYear}`} fill={theme.palette.primary.main} animationDuration={1000}>
                        <LabelList dataKey={`${currYear}`} position="middle" fill={theme.palette.primary.contrastText} formatter={(value) => `${value.toFixed(1)}`} />
                    </Bar>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        {/* <LabelList dataKey={`${currYear-1}`} position="top" fill={theme.palette.grey[500]} formatter={(value) => round(value,0)} /> */}
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const invCoverageData = getLastYear(fromLastYearData, 'invCoverage', currQuarter, currYear)
    let inventory = <GridColumnContent>
        {/* Inventory coverage */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'30%'}>
                    <Typography variant="body2" align="left">
                        Inventory coverage <i>(days)</i>
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} inversed
                        average={{value: kpiData.invCoverage.average, decimals: 0, format: (value) => `${value} days`}} 
                        lastYear={{value: kpiData.invCoverage.change, format: (value) => `${value} days`}} 
                        target={{value: kpiData.invCoverage.target, decimals: 0, format: (value) => `${value} days`}}
                    />
                </Box>
                <SmallGraph key={`${key}-InventoryCoverage`} width={'100%'} height={'70%'} 
                    data={invCoverageData}
                    yInterval={([dataMin, dataMax]) => {dataMin = Math.min(dataMin, fromLastYearData.at(-1).invCoverageTarget); dataMax = Math.max(dataMax, fromLastYearData.at(-1).invCoverageTarget); return [dataMin - (dataMax+dataMin)*0.1, dataMax + (dataMax+dataMin)*0.1]}}
                >
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} dot={true} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={invCoverageData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => value.toFixed(1)} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} dot={true} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={invCoverageData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => value.toFixed(1)} 
                        />
                    </Line>
                    <ReferenceLine y={fromLastYearData.at(-1).invCoverageTarget} stroke={theme.palette.primary.light} strokeWidth={1} strokeOpacity={1} strokeDasharray="3 3" />
                    <Line name='Target' dataKey={'nothing'} stroke={theme.palette.primary.light} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Stock */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'30%'}>
                    <Typography variant="body2" align="left">
                        Stock <i>(thousand units)</i>
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} inversed
                        average={{value: kpiData.stock.average/1000, decimals: 0, format: (value) => `${value}K units`}} 
                        lastYear={{value: kpiData.stock.change, format: (value) => `${value} units`}} 
                    />
                </Box>
                <SmallGraph key={`${key}-Stock`} width={'100%'} height={'70%'} data={getLastYearStock(fromLastYearData, currQuarter)} offset={10} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" xAxisId={0} type="category" strokeWidth={0} />
                    <XAxis hide allowDataOverflow dataKey="name" xAxisId={1} type="category" strokeWidth={0} />
                    <ReferenceLine y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    <Bar name={'Cycle stock'} dataKey={'cycleStock'} xAxisId={0} stackId='a' fill={theme.palette.primary.light} animationDuration={1000}>
                        <LabelList dataKey={'cycleStockPercentage'} position="middle" fill={theme.palette.primary.contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} />
                        <LabelList dataKey={'total'} position="top" fill={theme.palette.text.secondary} formatter={(value) => (value/1000).toFixed(0)} />
                    </Bar>
                    <Bar name={'Safety stock'} dataKey={'safetyStock'} xAxisId={1} fill={theme.palette.primary.main} animationDuration={1000}>
                        <LabelList dataKey={'safetyStockPercentage'} position="middle" fill={theme.palette.primary.contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} content={(props) => <text {...props} x={props.x + props.width/2} textAnchor='middle'>{props.value !== null ? props.formatter(props.value) : ''}</text>} />
                    </Bar>
                    <Line name={'Previous year'} type="monotone" dataKey={'lastYear'} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}/>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const serviceLevelData = getLastYear(fromLastYearData, 'serviceLevelActual', currQuarter, currYear)
    let supply = <GridColumnContent>
        {/* Service level radial */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Service level <i>(%)</i>
                    </Typography>
                </Box>
                <ServiceLevelDonutGraph width={'100%'} arcWidth={0.3} 
                    data={getServiceLevels(fromLastYearData, currQuarter)} 
                    currQuarter={currQuarter}
                    currYear={currYear}
                />
            </CustomPaper>
        </Box>
        {/* Service level lines */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'30%'}>
                    <Typography variant="body2" align="left">
                        Service level <i>(%)</i>
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear} 
                        average={{value: kpiData.serviceLevel.average*100, decimals: 1, format: (value) => `${value}%`}} 
                        target={{value: kpiData.serviceLevel.target*100, format: (value) => `${value}%`}} 
                        lastYear={{value: kpiData.serviceLevel.change*100, format: (value) => `${value}%pt`}} 
                    />
                </Box>
                <SmallGraph key={`${key}-ServiceLevelLines`} width={'100%'} height={'70%'} 
                    data={serviceLevelData} 
                    yInterval={([dataMin, dataMax]) => [dataMin - (Math.max(dataMax, fromLastYearData.at(-1).serviceLevelTarget)-dataMin)*0.2, Math.max(dataMax, fromLastYearData.at(-1).serviceLevelTarget) + (Math.max(dataMax, fromLastYearData.at(-1).serviceLevelTarget)-dataMin)*0.2]}
                >
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={serviceLevelData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={serviceLevelData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                    <ReferenceLine y={fromLastYearData.at(-1).serviceLevelTarget} stroke={theme.palette.primary.light} strokeWidth={1} strokeOpacity={1} strokeDasharray="3 3" />
                    <Line name='Target' dataKey={'nothing'} stroke={theme.palette.primary.light} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Demand Planning */}
        <GridItem>
            <CardTitle title="Demand planning" />
            {demand}
        </GridItem>
        {/* Inventory Planning */}
        <GridItem>
            <CardTitle title="Inventory planning" />
            {inventory}
        </GridItem>
        {/* Supply Planning */}
        <GridItem>
            <CardTitle title="Supply planning" />
            {supply}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

const getKpiData = (data) => {
    const yearlyData = {
        currYear: {},
        prevYear: {}
    }

    Object.keys(data.at(0)).forEach( key => {
        yearlyData.currYear[key] = mean([data.at(-4)[key], data.at(-3)[key], data.at(-2)[key], data.at(-1)[key]])
        yearlyData.prevYear[key] = mean([data.at(-8)[key], data.at(-7)[key], data.at(-6)[key], data.at(-5)[key]])
    })

    const dataToReturn = {
        forecastAccuracy: { 
            average: yearlyData.currYear.forecastAccuracy, 
            change: relativeDiff(yearlyData.currYear.forecastAccuracy, yearlyData.prevYear.forecastAccuracy)
        },
        forecastBias: { 
            average: yearlyData.currYear.forecastBias, 
            change: relativeDiff(yearlyData.currYear.forecastBias, yearlyData.prevYear.forecastBias)
        },
        invCoverage: { 
            average: yearlyData.currYear.invCoverage, 
            change: relativeDiff(yearlyData.currYear.invCoverage, yearlyData.prevYear.invCoverage),
            target: yearlyData.currYear.invCoverageTarget
        },
        stock: { 
            average: yearlyData.currYear.cycleStockLevel + yearlyData.currYear.safetyStockLevel, 
            change: relativeDiff(yearlyData.currYear.cycleStockLevel + yearlyData.currYear.safetyStockLevel, yearlyData.prevYear.cycleStockLevel + yearlyData.prevYear.safetyStockLevel)
        },
        serviceLevel: { 
            average: yearlyData.currYear.serviceLevelActual, 
            change: relativeDiff(yearlyData.currYear.serviceLevelActual, yearlyData.prevYear.serviceLevelActual),
            target: yearlyData.currYear.serviceLevelTarget
        },
    }

    return dataToReturn
}

const getLastYearStock = (data, currQuarter) => {
    const dataToReturn = [
        {
            name: 'Q1',
        },
        {
            name: 'Q2',
        },
        {
            name: 'Q3',
        },
        {
            name: 'Q4',
        },
    ]
    
    if (data.length > 0) {
        dataToReturn[0].cycleStock = data[4].cycleStockLevel;
        dataToReturn[0].safetyStock = data[4].safetyStockLevel;
        dataToReturn[0].total = data[4].cycleStockLevel + data[4].safetyStockLevel;
        dataToReturn[0].lastYear = data[0].cycleStockLevel + data[0].safetyStockLevel;
        dataToReturn[0].cycleStockPercentage = dataToReturn[0].cycleStock / (dataToReturn[0].cycleStock + dataToReturn[0].safetyStock)
        dataToReturn[0].safetyStockPercentage = dataToReturn[0].safetyStock / (dataToReturn[0].cycleStock + dataToReturn[0].safetyStock)

        dataToReturn[1].cycleStock = currQuarter > 1 ? data[5].cycleStockLevel : null;
        dataToReturn[1].safetyStock = currQuarter > 1 ? data[5].safetyStockLevel : null;
        dataToReturn[1].total = currQuarter > 1 ? data[5].cycleStockLevel + data[5].safetyStockLevel : null;
        dataToReturn[1].lastYear = data[1].cycleStockLevel + data[1].safetyStockLevel;
        dataToReturn[1].cycleStockPercentage = currQuarter > 1 ? dataToReturn[1].cycleStock / (dataToReturn[1].cycleStock + dataToReturn[1].safetyStock) : null;
        dataToReturn[1].safetyStockPercentage = currQuarter > 1 ? dataToReturn[1].safetyStock / (dataToReturn[1].cycleStock + dataToReturn[1].safetyStock) : null;

        dataToReturn[2].cycleStock = currQuarter > 2 ? data[6].cycleStockLevel : null;
        dataToReturn[2].safetyStock = currQuarter > 2 ? data[6].safetyStockLevel : null;
        dataToReturn[2].total = currQuarter > 2 ? data[6].cycleStockLevel + data[6].safetyStockLevel : null;
        dataToReturn[2].lastYear = data[2].cycleStockLevel + data[2].safetyStockLevel;
        dataToReturn[2].cycleStockPercentage = currQuarter > 2 ? dataToReturn[2].cycleStock / (dataToReturn[2].cycleStock + dataToReturn[2].safetyStock) : null;
        dataToReturn[2].safetyStockPercentage = currQuarter > 2 ? dataToReturn[2].safetyStock / (dataToReturn[2].cycleStock + dataToReturn[2].safetyStock) : null;

        dataToReturn[3].cycleStock = currQuarter > 3 ? data[7].cycleStockLevel : null;
        dataToReturn[3].safetyStock = currQuarter > 3 ? data[7].safetyStockLevel : null;
        dataToReturn[3].total = currQuarter > 3 ? data[7].cycleStockLevel + data[7].safetyStockLevel : null;
        dataToReturn[3].lastYear = data[3].cycleStockLevel + data[3].safetyStockLevel;
        dataToReturn[3].cycleStockPercentage = currQuarter > 3 ? dataToReturn[3].cycleStock / (dataToReturn[3].cycleStock + dataToReturn[3].safetyStock) : null;
        dataToReturn[3].safetyStockPercentage = currQuarter > 3 ? dataToReturn[3].safetyStock / (dataToReturn[3].cycleStock + dataToReturn[3].safetyStock) : null;
    }

    return dataToReturn
}

const getServiceLevels = (data, currQuarter) => {
    const dataToReturn = {
        current: null,
        prevQuarter: null,
        prevYear: null,
        target: null
    }
    
    if (data.length > 0) {
        dataToReturn.current = data[currQuarter+3].serviceLevelActual;
        dataToReturn.prevQuarter = data[currQuarter+2].serviceLevelActual;
        dataToReturn.prevYear = data[currQuarter-1].serviceLevelActual;
        dataToReturn.target = data[currQuarter+3].serviceLevelTarget;
    }

    return dataToReturn
}


export default SynchronizedPlanningSummary;