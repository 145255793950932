import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Tooltip, Legend, ComposedChart, YAxis, ScatterChart } from 'recharts';
import SmallGraphContainer from './SmallGraphContainer';

function ScatterPlot(props) {
    return (
        <SmallGraphContainer width={props.width} height={props.height}>
            <ScatterChart
                margin={{ left: 20, right: 20, bottom: 20}}
                {...props}
            >
                {props.children}
            </ScatterChart>
        </SmallGraphContainer>
    )
}

export default ScatterPlot;