import { Box, Typography, useTheme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Area, Bar, Label, LabelList, Line, Scatter, XAxis, YAxis, ZAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import ScatterPlot from "../../components/graphs/ScatterPlot";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getQuarters } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";

function SynchronizedPlanningInventory() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const regions = Object.keys(aggregatedData.byRegion)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const inventory = <GridColumnContent>
        {/* Inventory coverage */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Inventory coverage by region <i>(days)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-InventoryCoverage`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byRegion), 'invCoverage', regions, quarters, 0)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`inventorycoverage-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar 
                            name={`${quarter}`} 
                            dataKey={`${quarter}`} 
                            xAxisId={'axis0'} 
                            fill={colors[index].color} 
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.treeColors.orange} 
                            fillOpacity={0.6}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Sales */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Inventory coverage vs. units sold (size = stock level) 
                    </Typography>
                </Box>
                {/* Scatter plot */}
                <ScatterPlot key={`${key}-Scatter`} width={'100%'} height={'80%'}>
                    <XAxis dataKey={'invCoverage'} type="number" domain={[0, dataMax => round(dataMax * 1.1, 2-Math.ceil(Math.log10(dataMax + 1)))]} >
                        <Label value="Inventory coverage (days)" fill={theme.palette.grey[700]} position="bottom" />
                    </XAxis>
                    <YAxis dataKey={'unitsSold'} type="number" domain={[0, dataMax => round(dataMax * 1.1, 2-Math.ceil(Math.log10(dataMax + 1)))]} >
                        <Label value="Units sold (M. units)" fill={theme.palette.grey[700]} position="insideBottomLeft" angle={270} />
                    </YAxis>
                    <ZAxis dataKey={'totalStockLevel'} range={[100, 500]} type="number" />
                    <Scatter name="test" data={getScatterData(last8Quarters(aggregatedData.byProduct), products, 1/1000000)} fill={colors[3].color} shape="circle" animationDuration={1000}>
                        <LabelList dataKey={`name`} position="left" fill={theme.palette.text.secondary} fontSize={12} />
                    </Scatter>
                </ScatterPlot>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const stock = <GridColumnContent>
        {/* Cycle and safety stock levels */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Cycle and safety stock levels <i>(k. units)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-StockLevels`} width={'100%'} height={'80%'} data={getStockLevels(last8Quarters(aggregatedData.summary), quarters)} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    {/* Areas of current year */}
                    <Area 
                        key={`safetyStock`} 
                        name={`Safety stock`} 
                        type="monotone" 
                        dataKey={`safetyStock`} 
                        stackId="1" 
                        stroke={colors[3].color} 
                        fill={colors[3].color} 
                        animationDuration={1000} 
                    />
                    <Area 
                        key={`cycleStock`} 
                        name={`Cycle stock`} 
                        type="monotone" 
                        dataKey={`cycleStock`} 
                        stackId="1" 
                        stroke={colors[2].color} 
                        fill={colors[2].color} 
                        animationDuration={1000} 
                    />
                    {/* Line to indicate total */}
                    <Line key={`total`} name={`Total`} type="monotone" dataKey={`total`} stroke={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`total`} position="top" fill={colors[3].color} formatter={(value) => value.toFixed(0)} fontSize={12} />
                    </Line>
                    {/* Line to indicate previous year */}
                    <Line key={`prevyear`} name={`Previous year`} type="monotone" dataKey={`prevYearTotal`} stroke={colors[0].color} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Stock levels by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Stock levels by product <i>(k. units)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-StockLevelsByProduct`} width={'100%'} height={'80%'} data={getStockLevelsByProduct(last8Quarters(aggregatedData.byProduct), products, quarters, 0)} noBottomScale>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`productStock-${quarter}`}>
                        {/* Stacked bar of current year */}
                        <Bar 
                            name={`${quarter}`} 
                            dataKey={`${quarter}.safetyStock`} 
                            xAxisId={'axis0'} 
                            stackId={`${quarter}`} 
                            fill={colors[index].color}
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}.safetyStock`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => value.toFixed(0)} fontSize={10} />
                        </Bar>
                        <Bar 
                            name={`${quarter}`} 
                            legendType='none' 
                            dataKey={`${quarter}.cycleStock`} 
                            xAxisId={'axis0'} 
                            stackId={`${quarter}`} 
                            fill={`${colors[index].color}88`} 
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}.cycleStock`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => value.toFixed(0)} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}.total`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    {/* <Line name={`Safety stock`} dataKey={`nothing`} xAxisId={'axis0'} stroke={colors[3].color} legendType='rect' /> */}
                    {/* <Line name={`Cycle stock`} dataKey={`nothing`} xAxisId={'axis0'} stroke={`${colors[3].color}88`} legendType='rect' /> */}
                    {/* <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' /> */}
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const discount = <GridColumnContent>
        {/* Discount by region */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Discount by region <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-DiscountByRegion`} width={'100%'} height={'80%'} data={getDiscountByRegion(last8Quarters(aggregatedData.byRegion), regions, quarters).splice(4,4)}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    {regions.map( (region, index) => 
                        <Line key={`discount-${region}`} name={`${region}`} dataKey={`${region}`} stroke={colors[3-index].color} animationDuration={1000}>
                            <LabelList key={`discount-${region}`} dataKey={`${region}`} position="top" fill={colors[3-index].color} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                        </Line>
                    )}
                    <Line name={`Average`} dataKey={`average`} stroke={theme.palette.grey[700]} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Discount by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Discount by product <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-DiscountByProduct`} width={'100%'} height={'80%'} data={getDiscountByProduct(last8Quarters(aggregatedData.byProduct), products, quarters).splice(4,4)}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    {products.map( (product, index) => 
                        <Line key={`discount-${product}`} name={`${product}`} dataKey={`${product}`} stroke={colors[3-index].color} animationDuration={1000}>
                            <LabelList key={`discount-${product}`} dataKey={`${product}`} position="top" fill={colors[3-index].color} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                        </Line>
                    )}
                    <Line name={`Average`} dataKey={`average`} stroke={theme.palette.grey[700]} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Inventory Coverage */}
        <GridItem>
            <CardTitle title="Inventory coverage" />
            {inventory}
        </GridItem>
        {/* Stock Levels */}
        <GridItem>
            <CardTitle title="Stock levels" />
            {stock}
        </GridItem>
        {/* Discount */}
        <GridItem>
            <CardTitle title="Discount" />
            {discount}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

const round = (number, digits) => {
    return Math.round(number * Math.pow(10, digits) + 0.5)/Math.pow(10, digits)
}

const getStockLevels = (data, quarters, offset=0, scalar=1) => {
    const dataToReturn = []
    const lastQuarters = [...quarters].splice(4,4)
    lastQuarters.forEach( (quarter, index) => {
        dataToReturn[index] = { name: quarter }
    })

    if (data.length > 0) {
        lastQuarters.forEach( (quarter, index) => {
            dataToReturn[index].cycleStock = (data[index+4].cycleStockLevel * scalar + offset)/1000;
            dataToReturn[index].safetyStock = (data[index+4].safetyStockLevel * scalar + offset)/1000;
            dataToReturn[index].total = (data[index+4].cycleStockLevel * scalar + offset + data[index+4].safetyStockLevel * scalar + offset)/1000;
            dataToReturn[index].prevYearTotal = (data[index].cycleStockLevel * scalar + offset + data[index].safetyStockLevel * scalar + offset)/1000;
        })
    }

    return dataToReturn
}

const getStockLevelsByProduct = (data, products, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    products.forEach( (product, index ) => {
        dataToReturn[index] = { name: product.split(" ").at(-2) }
    })

    if (data && Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            products.forEach( (product, index2) => {
                dataToReturn[index2][quarter] = {}
                dataToReturn[index2][quarter].cycleStock = (scalar * data[product][index1].cycleStockLevel + offset)/1000;
                dataToReturn[index2][quarter].safetyStock = (scalar * data[product][index1].safetyStockLevel + offset)/1000;
                dataToReturn[index2][quarter].total = (scalar * data[product][index1].cycleStockLevel + offset + scalar * data[product][index1].safetyStockLevel + offset)/1000;
            })
        })
    }

    return dataToReturn
}

const getDiscountByRegion = (data, regions, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    quarters.forEach( (quarter, index ) => {
        dataToReturn[index] = { name: quarter }
    })

    if (data && Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            let total = 0;
            regions.forEach( (region, index2) => {
                dataToReturn[index1][region] = scalar * data[region][index1].discountRate + offset;
                total += scalar * data[region][index1].discountRate + offset;
            })
            dataToReturn[index1].average = total/regions.length;
        })
    }

    return dataToReturn
}

const getDiscountByProduct = (data, products, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    quarters.forEach( (quarter, index ) => {
        dataToReturn[index] = { name: quarter }
    })

    if (data && Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            let total = 0;
            products.forEach( (product, index2) => {
                dataToReturn[index1][product] = scalar * data[product][index1].discountRate + offset;
                total += scalar * data[product][index1].discountRate + offset;
            })
            dataToReturn[index1].average = total/products.length;
        })
    }

    return dataToReturn
}

const getScatterData = (data, products, unitsSoldScalar = 1) => {
    const dataToReturn = []
    if (data && Object.keys(data).length > 0) {
        products.forEach( (product) => {
            let productData = {
                name: product,
                invCoverage: (data[product].at(-4).invCoverage + data[product].at(-3).invCoverage + data[product].at(-2).invCoverage + data[product].at(-1).invCoverage) / 4,
                unitsSold: (data[product].at(-4).unitsSold + data[product].at(-3).unitsSold + data[product].at(-2).unitsSold + data[product].at(-1).unitsSold) * unitsSoldScalar,
                cycleStockLevel: (data[product].at(-4).cycleStockLevel + data[product].at(-3).cycleStockLevel + data[product].at(-2).cycleStockLevel + data[product].at(-1).cycleStockLevel) / 4,
                safetyStockLevel: (data[product].at(-4).safetyStockLevel + data[product].at(-3).safetyStockLevel + data[product].at(-2).safetyStockLevel + data[product].at(-1).safetyStockLevel) / 4,
            }
            
            productData.totalStockLevel = productData.cycleStockLevel + productData.safetyStockLevel
            
            dataToReturn.push(productData)
        })
    }
    
    return dataToReturn
}

export default SynchronizedPlanningInventory;