import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, ReferenceLine, XAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getLastYear, getQuarters } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function SmartFactoryPerformance() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    // const regions = Object.keys(aggregatedData.byRegion)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const oeeData = getLastYear(fromLastYearData, 'oeeKPI', currQuarter, currYear, 0, 100)
    const oee = <GridColumnContent>
        {/* OEE evolution */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-OEE`} width={'100%'} height={'80%'} data={oeeData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={oeeData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={oeeData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* OEE by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-OEEByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'oeeKPI', products, quarters, 0, 100)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`oeeproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const cycleTimeData = getLastYear(fromLastYearData, 'cycleTime', currQuarter, currYear)
    const cycle = <GridColumnContent>
        {/* Cycle time */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(units/hour)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-CycleTime`} width={'100%'} height={'80%'} data={cycleTimeData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={cycleTimeData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => value.toFixed(0)} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={cycleTimeData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => value.toFixed(0)} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Cycle time by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(units/hour)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-CycleTimeByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'cycleTime', products, quarters)}>
                <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`cycletimeproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => value.toFixed(0)} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const planningAdherenceData = getLastYear(fromLastYearData, 'planningAdherence', currQuarter, currYear)
    const planning = <GridColumnContent>
        {/* Planning adherence */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(changes)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-PlanningAdherence`} width={'100%'} height={'80%'} data={planningAdherenceData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={planningAdherenceData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => value.toFixed(0)} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={planningAdherenceData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => value.toFixed(0)} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Planning adherence by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(changes)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-PlanningAdherenceByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'planningAdherence', products, quarters)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`planningproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => value.toFixed(0)} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Overall Equipment Effectiveness */}
        <GridItem>
            <CardTitle title="Overall equipment effectiveness" />
            {oee}
        </GridItem>
        {/* Cycle Time */}
        <GridItem>
            <CardTitle title="Cycle time" />
            {cycle}
        </GridItem>
        {/* Planning Adherence */}
        <GridItem>
            <CardTitle title="Planning adherence" />
            {planning}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

export default SmartFactoryPerformance;