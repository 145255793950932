import { Box, Typography, useTheme } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

function NoDataMessage() {
    const theme = useTheme()

    return <Box sx={{display: 'flex', flexDirection: 'column', height: '100%', alignContent: 'center', justifyContent: 'center' }}>
        <ErrorOutlineIcon sx={{ margin: 'auto auto 15% auto', transform: 'scale(10)', fill: theme.palette.text.secondary }} />
        <Typography variant="h4" color='text.secondary' sx={{ margin: '0 auto auto auto' }} textAlign='center' >No data available with the current filters</Typography>
    </Box>
}

export default NoDataMessage;