import { Box, CircularProgress, useTheme } from '@mui/material';
import { height } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Tooltip, Legend, ComposedChart, YAxis, PieChart, Pie, Cell } from 'recharts';
import SmallGraphContainer from './SmallGraphContainer';

function ServiceLevelDonutGraph(props) {
    
    const { height, width, arcWidth, data, currQuarter, currYear } = props

    const innerRadius = (1-arcWidth)*100

    const theme = useTheme();

    // data.current = 0.85
    // data.prevQuarter = 0.75
    // data.prevYear = 0.84
    // data.target = 0.95

    if (!data.current) {
        return <CircularProgress />
    }

    const getAngle = (percentage) => 0.5*Math.PI - 2*Math.PI*percentage
    const coordsFromAngle = (angle, scalar) => [Math.cos(angle) * scalar, -Math.sin(angle) * scalar]
    const coordsToString = ([x, y]) => `${x} ${y}`
    const posFromData = (percentage, scalar) => coordsToString(coordsFromAngle(getAngle(percentage), scalar))
    const diamond = (angle, inner, outer) => {
        const innerPoint = coordsFromAngle(angle, inner)
        const midPoint = coordsFromAngle(angle, (outer+inner)/2)
        const leftPoint = [midPoint[0] + (innerPoint[1]-midPoint[1]), midPoint[1] - (innerPoint[0]-midPoint[0])]
        const rightPoint = [midPoint[0] - (innerPoint[1]-midPoint[1]), midPoint[1] + (innerPoint[0]-midPoint[0])]

        return `M ${coordsToString(innerPoint)}
                L ${coordsToString(leftPoint)}
                L ${coordsToString(coordsFromAngle(angle, outer))}
                L ${coordsToString(rightPoint)}
                L ${coordsToString(innerPoint)}`
    }
    const arrow = (angle, outer) => {
        const outerPoint = coordsFromAngle(angle, outer)
        const edgePoint = coordsFromAngle(angle, 100)
        const leftPoint = [outerPoint[0] + (edgePoint[1]-outerPoint[1]), outerPoint[1] - (edgePoint[0]-outerPoint[0])]
        const rightPoint = [outerPoint[0] - (edgePoint[1]-outerPoint[1]), outerPoint[1] + (edgePoint[0]-outerPoint[0])]

        return `M ${coordsToString(edgePoint)}
                L ${coordsToString(leftPoint)}
                L ${coordsToString(rightPoint)}
                L ${coordsToString(edgePoint)}`
    }


    return (<Box style={{ userSelect: "none" }} >
        <svg style={{
                position: 'relative', 
                top: '0', left: '0', 
                fontFamily: theme.typography.fontFamily, 
                fontSize: theme.typography.body2.fontSize
            }} 
            width={width} 
            height={height} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={`-240 -130 400 240`} 
        >
            {/* Current service level */}
            <path d={`M 0 -100
                    A 100 100 0 1 1 ${posFromData(data.current, 100)}
                    L ${posFromData(data.current, innerRadius)}
                    A ${innerRadius} ${innerRadius} 0 1 0 0 -${innerRadius}
                    L 0 -100`} 
                    fill={data.current > data.target ? theme.palette.treeColors.green : theme.palette.treeColors.orange} />

            {/* Fill rest of donut */}
            <path d={`M 0 -100
                    A 100 100 0 0 0 ${posFromData(data.current, 100)}
                    L ${posFromData(data.current, innerRadius)}
                    A ${innerRadius} ${innerRadius} 1 0 1 0 -${innerRadius}
                    L 0 -100`} 
                    fill={theme.palette.grey[600]} />

            {/* Diamond at previous quartile */}
            <path d={diamond(getAngle(data.prevQuarter), 100 - arcWidth*90, 100 - arcWidth*10)} 
                    fill={theme.palette.primary.dark} />
            <text 
                x={Math.abs(data.prevYear - data.current) > 0.05 ?
                    coordsFromAngle(getAngle(data.prevYear), 115)[0]
                    :
                    coordsFromAngle(getAngle(data.current - 0.05), 115)[0]} 
                y={Math.abs(data.prevYear - data.current) > 0.05 ?
                    coordsFromAngle(getAngle(data.prevYear), 115)[1]
                    :
                    coordsFromAngle(getAngle(data.current - 0.05), 115)[1]}
                textAnchor="end"
                fill={theme.palette.primary.dark}
            >
                {`Q${currQuarter-1} ${currYear}: ${round(data.prevQuarter*100, 0)}%`}
            </text>

            {/* Diamond at previous year */}
            <path d={diamond(getAngle(data.prevYear), 100 - arcWidth*90, 100 - arcWidth*10)} 
                    fill={theme.palette.grey[400]} />
            <text 
                x={Math.abs(data.prevYear - data.current) > 0.05 ?
                    coordsFromAngle(getAngle(data.prevYear), 115)[0]
                    :
                    Math.abs(data.prevYear - data.prevQuarter) > 0.05 ?
                        coordsFromAngle(getAngle(data.current - 0.05), 115)[0]
                        :
                        coordsFromAngle(getAngle(data.current - 0.10), 115)[0]
                } 
                y={Math.abs(data.prevYear - data.current) > 0.05 ?
                    coordsFromAngle(getAngle(data.prevYear), 115)[1]
                    :
                    Math.abs(data.prevYear - data.prevQuarter) > 0.05 ?
                        coordsFromAngle(getAngle(data.current - 0.05), 115)[1]
                        :
                        coordsFromAngle(getAngle(data.current - 0.10), 115)[1]
                } 
                textAnchor="end"
                fill={theme.palette.grey[400]}
            >
                {`Q${currQuarter} ${currYear-1}: ${round(data.prevYear*100, 0)}%`}
            </text>

            {/* Target arrow */}
            <path d={arrow(getAngle(data.target), 115)} 
                    fill={theme.palette.treeColors.green} />
            <path d={`M ${coordsFromAngle(getAngle(data.target), innerRadius)}
                    L ${coordsFromAngle(getAngle(data.target), 100)}`} 
                    stroke={theme.palette.treeColors.green} 
                    strokeWidth={2} />
            <text 
                x={coordsFromAngle(getAngle(data.target), 120)[0]} 
                y={coordsFromAngle(getAngle(data.target), 120)[1]}
                textAnchor="end"
                fill={theme.palette.treeColors.green}
            >
                {`Target: ${round(data.target*100, 0)}%`}
            </text>

            {/* Current value */}
            <text 
                x={coordsFromAngle(getAngle(data.current), 115)[0]} 
                y={coordsFromAngle(getAngle(data.current), 115)[1]} 
                textAnchor="end"
                fill={theme.palette.primary.light}
            >
                {`Q${currQuarter} ${currYear}: ${round(data.current*100, 0)}%`}
            </text>
        </svg>
    </Box>)
}

const round = (number, digits) => {
    return Math.round(number * Math.pow(10, digits))/Math.pow(10, digits)
}

export default ServiceLevelDonutGraph;