import { Box, Typography } from "@mui/material";
import React from "react";
import NavigationStar from "../components/NavigationStar";

function Home() {
    return <Box>
        <Typography variant='h2'>DSN LAB</Typography>
        <Box width={'60vh'} margin={'auto'}>
            <NavigationStar enlarged={true} setEnlargedStar={(value) => {}} />
        </Box>
        {/* <Link to="/test/executiveview">Executive View</Link><br />
        <Link to="/dynamicfulfillment">Dynamic Fulfillment</Link><br />
        <Link to="/synchronizedplanning">Synchronized Planning</Link><br />
        <Link to="/smartfactory">Smart Factory</Link><br />
        <Link to="/intelligentsupply">Intelligent Supply</Link> */}
    </Box>
}

export default Home;