import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import { useApi } from "../../util/useApiHook";
import { sum, mean } from "../../util/arrayOperations";
import { Box, CircularProgress } from "@mui/material";
import NoDataMessage from "../../components/NoDataMessage";
import { aggregateCategorizedData, aggregateData, filterCategorizedData, filterData, filterInfo } from "../../util/dataOperations";
import { fetchFromApi } from "../../util/fetchFromApi";
import { useQueries, useQuery } from "react-query";
import LoadingPage from "../LoadingPage";

const filterSkeleton = JSON.stringify([
    {
        type: "finishedGoodName",
        label: "finishedGoodName",
        name: "Product Group",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "region",
        label: "region",
        name: "Region",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "storeCountry",
        label: "storeCountry",
        name: "Country",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "storeID",
        label: "storeName",
        name: "Store",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
])

function SynchronizedPlanningLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Fetch data for synchronized planning
    const { gameId } = useParams()

    // Get info on types
    const { data: storeInfo} = useQuery( `/info/stores/${gameId}`, fetchFromApi )
    const { data: productInfo} = useQuery( `/info/products/${gameId}`, fetchFromApi )

    // Get transactional data
    const { data: dataSummary} = useQuery( `/syncplanning/${gameId}/summary`, fetchFromApi )
    const { data: dataByRegion} = useQuery( `/syncplanning/${gameId}/summary/region`, fetchFromApi )
    const { data: dataByProduct} = useQuery( `/syncplanning/${gameId}/summary/finishedGoodName`, fetchFromApi )

    let initialYear = null
    let latestYear = null
    let latestQuarter = null
    
    // Handle incoming data
    if (!isLoading) {
        initialYear = dataSummary[0].gameYear
        latestYear = dataSummary.at(-1).gameYear
        latestQuarter = (dataSummary.at(-1).gameRound - 1) % 4 + 1
    }

    // Set correct paths to subpages
    const tabs = [
        {link: `/${gameId}/synchronizedplanning/summary`, name: "Summary"},
        {link: `/${gameId}/synchronizedplanning/demand`, name: "Demand"},
        {link: `/${gameId}/synchronizedplanning/inventory`, name: "Inventory"},
        {link: `/${gameId}/synchronizedplanning/supply`, name: "Supply"},
    ]
    
    const [currYear, setCurrYear] = useState(latestYear);
    
    // Filters for synchronized planning    
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }
    
    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }
    
    const initialFilters = {
        finishedGoodName: 'ALL',
        region: 'ALL', 
        storeCountry: 'ALL',
        storeID: 'ALL'
    }
    
    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredStoreInfo, setFilteredStoreInfo] = useState({})
    const [filteredDataSummary, setFilteredDataSummary] = useState([])
    const [filteredDataByRegion, setFilteredDataByRegion] = useState({})
    const [filteredDataByProduct, setFilteredDataByProduct] = useState({})
    
    useEffect(() => {
        if (isLoading) return

        let newFilters = JSON.parse(filterSkeleton)
        let temp = []
        
        Object.values(productInfo).forEach((product) => {
            newFilters[0].options.push({value: product[newFilters[0].type], label: product[newFilters[0].label]})
        })
        
        temp = []
        Object.values(storeInfo).forEach((store) => {
            if (!temp.includes(store[newFilters[1].type])) {
                newFilters[1].options.push({value: store[newFilters[1].type], label: store[newFilters[1].label]})
                temp.push(store[newFilters[1].type])
            }
        })
        
        temp = []
        if (currFilters[newFilters[1].type] !== 'ALL') {
            Object.values(filteredStoreInfo).forEach((store) => {
                if (!temp.includes(store[newFilters[2].type])) {
                    newFilters[2].options.push({value: store[newFilters[2].type], label: store[newFilters[2].label]})
                    temp.push(store[newFilters[2].type])
                }
            })
        }
        
        temp = []
        if (currFilters[newFilters[2].type] !== 'ALL') {
            Object.values(filteredStoreInfo).forEach((store) => {
                if (!temp.includes(store[newFilters[3].type])) {
                    newFilters[3].options.push({value: store[newFilters[3].type], label: store[newFilters[3].label]})
                    temp.push(store[newFilters[3].type])
                }
            })
        }
        
        setFilters(newFilters)
    }, [isLoading, currFilters, productInfo, storeInfo, filteredStoreInfo])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return

        setFilteredStoreInfo(filterInfo(storeInfo, currFilters))
    }, [isLoading, storeInfo, currFilters])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return

        setCurrYear(latestYear)
        setFilteredDataSummary(filterData(dataSummary, currFilters))
        setFilteredDataByRegion(filterCategorizedData(dataByRegion, currFilters))
        setFilteredDataByProduct(filterCategorizedData(dataByProduct, currFilters))
    }, [isLoading, latestYear, dataSummary, dataByRegion, dataByProduct, currFilters])
    
    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
            if (type === filters[1].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[2].type]: 'ALL', [filters[3].type]: 'ALL'})
            } else if (type === filters[2].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[3].type]: 'ALL'})
            }
        }
    };
    
    const filterReset = () => {
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"synchronizedplanning"} departmentName={"Synchronized Planning"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            filteredData: {summary: filteredDataSummary, byRegion: filteredDataByRegion, byProduct: filteredDataByProduct}, 
                            aggregatedData: {
                                summary: aggregateData(filteredDataSummary, aggFunctions), 
                                byRegion: aggregateCategorizedData(filteredDataByRegion, aggFunctions), 
                                byProduct: aggregateCategorizedData(filteredDataByProduct, aggFunctions)
                            }
                        }} />
                        :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const aggFunctions = {
    'targetRevenueSales': 'sum',
    'revenueSales': 'sum',
    'unitsSold': 'sum',
    'serviceLevelTarget': 'first',
    'serviceLevelActual': 'mean',
    'returnRate': 'mean',
    'discountRate': 'mean',
    'safetyStockLevel': 'mean',
    'cycleStockLevel': 'mean',
    'invCoverage': 'mean',
    'invCoverageTarget': 'first',
    'planningAdherence': 'mean',
    'forecastAccuracy': 'mean',
    'forecastBias': 'mean',
    'workingCapital': 'sum',
    'costsMarketingSalesOtherOverhead': 'sum',
}

export default SynchronizedPlanningLayout;