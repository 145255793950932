import React from 'react';
import {ReactComponent as DSNStarSmall} from '../assets/DSN_Star_small.svg';    
import {ReactComponent as DSNStarInteractive} from '../assets/DSN_Star_Interactive.svg';
import { useNavigate, useParams } from 'react-router';

function NavigationStar(props) {
    const { gameId } = useParams()

    const navigate = useNavigate();
    return (
        props.enlarged ? 
            <DSNStarInteractive 
                style={{ marginTop: '5%', height: '90%', width: 'auto' }} 
                alt='DSN navigation star' 
                onClick={(event) => { 
                    // If a circle has been clicked, navigate to the corresponding page
                    if(event.target.id) { 
                        props.setEnlargedStar(false)
                        navigate(`/${gameId}/${event.target.id}/summary`)
                    }
                }} 
            />
        :
            <DSNStarSmall style={{ height: '100%', width: 'auto' }} alt='DSN navigation star' />
    );
}

export default NavigationStar;