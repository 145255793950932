import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, XAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";
import { getLastYear } from "../../util/dataOperations";

function SmartFactoryCost() {
    const { currFilters, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter + 4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))

    // Get colors from theme
    const colors = theme.palette.graphColors

    const total = <GridColumnContent halfHeight>
        {/* Breakdown of total production costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Breakdown of total production costs <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-Breakdown`} 
                    width={'100%'} 
                    height={'80%'} 
                    data={getCostsBreakdown(
                        fromLastYearData, 
                        ['costsOverhead', 'costsLabor', 'costsUtilities', 'costsMaintenanceTotal'], 
                        'costsManufacturingTotal', 
                        currQuarter
                    )} 
                    noBottomScale 
                >
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Bar  
                        name={`Overhead`} 
                        dataKey={`costsOverhead.value`} 
                        stackId={0}
                        fill={colors[0].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsOverhead.percentage`} position="middle" fill={colors[0].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Bar  
                        name={`Labor`} 
                        dataKey={`costsLabor.value`} 
                        stackId={0}
                        fill={colors[1].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsLabor.percentage`} position="middle" fill={colors[1].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Bar  
                        name={`Utilities`} 
                        dataKey={`costsUtilities.value`} 
                        stackId={0}
                        fill={colors[2].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsUtilities.percentage`} position="middle" fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Bar  
                        name={`Maintenance`} 
                        dataKey={`costsMaintenanceTotal.value`} 
                        stackId={0}
                        fill={colors[3].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsMaintenanceTotal.percentage`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Line name={`Total`} legendType="none" type="monotone" dataKey={`total`} stroke={colors[3].color} strokeWidth={0} animationDuration={1000}>
                        <LabelList dataKey={`prevTotal`} position="top" fill={theme.palette.text.secondary} formatter={(value) => `${(value/1000000).toFixed(0)}`} />
                    </Line>
                    <Line name={`Previous year`} type="monotone" dataKey={`prevTotal`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const laborData = getLastYear(fromLastYearData, 'costsLabor', currQuarter, currYear)
    const labor = <GridColumnContent halfHeight>
        {/* Labor costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-LaborCosts`} width={'100%'} height={'80%'} data={laborData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={laborData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                    <Line name={`${currYear - 1}`} type="monotone" dataKey={`${currYear - 1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear - 1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={laborData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const unitData = getLastYear(fromLastYearData, 'costsUnitProduction', currQuarter, currYear)
    const unit = <GridColumnContent halfHeight>
        {/* Unit production costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(&euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-UnitCosts`} width={'100%'} height={'80%'} data={unitData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                    <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={unitData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(2)}`} 
                        />
                    </Line>
                    <Line name={`${currYear - 1}`} type="monotone" dataKey={`${currYear - 1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear - 1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={unitData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(2)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const overheadData = getLastYear(fromLastYearData, 'costsOverhead', currQuarter, currYear)
    const overhead = <GridColumnContent halfHeight>
        {/* Overhead costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-OverheadCosts`} width={'100%'} height={'80%'} data={overheadData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                    <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={overheadData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                    <Line name={`${currYear - 1}`} type="monotone" dataKey={`${currYear - 1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear - 1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={overheadData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const maintenance = <GridColumnContent halfHeight>
        {/* Maintenance costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Breakdown of total maintenance costs <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-MaintenanceCosts`} 
                    width={'100%'} 
                    height={'80%'} 
                    data={getCostsBreakdown(
                        fromLastYearData, 
                        ['costsMaintenancePlanned', 'costsMaintenanceUnplanned'], 
                        'costsMaintenanceTotal', 
                        currQuarter
                    )} 
                    noBottomScale 
                >
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Bar  
                        name={`Utilities`} 
                        dataKey={`costsMaintenancePlanned.value`} 
                        stackId={0}
                        fill={colors[2].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsMaintenancePlanned.percentage`} position="middle" fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Bar  
                        name={`Maintenance`} 
                        dataKey={`costsMaintenanceUnplanned.value`} 
                        stackId={0}
                        fill={colors[3].color} 
                        animationDuration={1000}
                    >
                        <LabelList dataKey={`costsMaintenanceUnplanned.percentage`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={10} />
                    </Bar>
                    <Line name={`Total`} legendType="none" type="monotone" dataKey={`total`} stroke={colors[3].color} strokeWidth={0} animationDuration={1000}>
                        <LabelList dataKey={`prevTotal`} position="top" fill={theme.palette.text.secondary} formatter={(value) => `${(value/1000000).toFixed(1)}`} />
                    </Line>
                    <Line name={`Last year`} type="monotone" dataKey={`prevTotal`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const utilitiesData = getLastYear(fromLastYearData, 'costsUtilities', currQuarter, currYear)
    const utilities = <GridColumnContent halfHeight>
        {/* Utilities costs */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-UtilitiesCosts`} width={'100%'} height={'80%'} data={utilitiesData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{ left: 20, right: 20 }} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                    <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={utilitiesData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                    <Line name={`${currYear - 1}`} type="monotone" dataKey={`${currYear - 1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear - 1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={utilitiesData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value/1000000).toFixed(0)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Total production costs and Labor costs */}
        <GridItem>
            <Box height='50%'>
                <CardTitle title="Total production costs" halfHeight />
                {total}
            </Box>
            <Box height='50%'>
                <CardTitle title="Labor costs" halfHeight />
                {labor}
            </Box>
        </GridItem>
        {/* Unit production costs and Overhead costs */}
        <GridItem>
            <Box height='50%'>
                <CardTitle title="Unit production" halfHeight />
                {unit}
            </Box>
            <Box height='50%'>
                <CardTitle title="Overhead costs" halfHeight />
                {overhead}
            </Box>
        </GridItem>
        {/* Maintenance costs and Utilities costs */}
        <GridItem>
            <Box height='50%'>
                <CardTitle title="Maintenance costs" halfHeight />
                {maintenance}
            </Box>
            <Box height='50%'>
                <CardTitle title="Utilities costs" halfHeight />
                {utilities}
            </Box>
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

const getCostsBreakdown = (data, keys, keyTotal, currQuarter, offset = 0, scalar = 1) => {
    const dataToReturn = [
        {
            name: 'Q1',
        },
        {
            name: 'Q2',
        },
        {
            name: 'Q3',
        },
        {
            name: 'Q4',
        },
    ]

    if (data.length > 0) {
        keys.forEach((key) => {
            dataToReturn[0][key] = {}
            dataToReturn[0][key].value = scalar * data[4][key] + offset;
            dataToReturn[0][key].percentage = scalar * data[4][key]/data[4][keyTotal] + offset;

            dataToReturn[1][key] = {}
            dataToReturn[1][key].value = currQuarter > 1 ? scalar * data[5][key] + offset : null;
            dataToReturn[1][key].percentage = currQuarter > 1 ? scalar * data[5][key]/data[5][keyTotal] + offset : null;

            dataToReturn[2][key] = {}
            dataToReturn[2][key].value = currQuarter > 2 ? scalar * data[6][key] + offset : null;
            dataToReturn[2][key].percentage = currQuarter > 2 ? scalar * data[6][key]/data[6][keyTotal] + offset : null;

            dataToReturn[3][key] = {}
            dataToReturn[3][key].value = currQuarter > 3 ? scalar * data[7][key] + offset : null;
            dataToReturn[3][key].percentage = currQuarter > 3 ? scalar * data[7][key]/data[7][keyTotal] + offset : null;
        })

        dataToReturn[0]['total'] = scalar * data[4][keyTotal] + offset;
        dataToReturn[1]['total'] = currQuarter > 1 ? scalar * data[5][keyTotal] + offset : null;
        dataToReturn[2]['total'] = currQuarter > 2 ? scalar * data[6][keyTotal] + offset : null;
        dataToReturn[3]['total'] = currQuarter > 3 ? scalar * data[7][keyTotal] + offset : null;

        dataToReturn[0]['prevTotal'] = scalar * data[0][keyTotal] + offset;
        dataToReturn[1]['prevTotal'] = scalar * data[1][keyTotal] + offset;
        dataToReturn[2]['prevTotal'] = scalar * data[2][keyTotal] + offset;
        dataToReturn[3]['prevTotal'] = scalar * data[3][keyTotal] + offset;
    }

    return dataToReturn
}

export default SmartFactoryCost;