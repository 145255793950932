import { Box, CircularProgress, useTheme } from '@mui/material';
import React from 'react'
import { ReactComponent as FactoryIcon } from "../../assets/icons/IconFactory.svg"
import { ReactComponent as WarehouseIcon } from "../../assets/icons/IconWarehouse.svg"
import { ReactComponent as TruckIcon } from "../../assets/icons/IconTruck.svg"
import { ReactComponent as StoreIcon } from "../../assets/icons/IconStore.svg"
import { ReactComponent as MoneyIcon } from "../../assets/icons/IconMoney.svg"
import { ReactComponent as TransportIcon } from "../../assets/icons/IconTransport.svg"

function DynFulfillmentVisual(props) {
    
    const { height, width, currYear, currQuarter, data } = props

    const theme = useTheme();    

    if (!data || Object.keys(data).length === 0) {
        return <Box margin={'auto'}>
            <CircularProgress />
        </Box>
    }

    const svgWidth = 240
    const svgHeight = 135
    const centerHor = 120
    const marginHor = 20
    const topLine = 15
    const midLine = 60

    const kpiCosts = (value, change, x, y, horizontal=false) =>
        <>
            <MoneyIcon height='15' width='15' x={horizontal ? x-35 : x-7.5} y={horizontal ? y-2.5 : y-7.5} />
            <text 
                x={x} 
                y={horizontal ? y : y+12.5} 
                fill={theme.palette.text.primary}
                fontSize={4}
                textAnchor='middle'
            >
                Costs       
            </text>
            {change ? 
                <>
                    <text 
                        x={x-10} 
                        y={horizontal ? y+5 : y+17.5} 
                        fill={theme.palette.text.primary}
                        fontSize={3}
                        textAnchor='middle'
                    >
                        {`\u20AC${(value/1000000).toFixed(1)}M`}
                        <tspan 
                            x={x-10} 
                            y={horizontal ? y+9 : y+21.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >{`${currYear} Q${currQuarter}`}</tspan>         
                    </text>
                    <text 
                        x={x+10} 
                        y={horizontal ? y+5 : y+17.5} 
                        fill={change > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail}
                        fontSize={3}
                        textAnchor='middle'
                    >
                        {`${change > 0 ? '+' : ''}${(change*100).toFixed(1)}%`}
                        <tspan 
                            x={x+10} 
                            y={horizontal ? y+9 : y+21.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >vs. previous</tspan>
                        <tspan 
                            x={x+10} 
                            y={horizontal ? y+12 : y+24.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >quarter</tspan>
                    </text>
                </>
            :         
                <text 
                    x={x} 
                    y={horizontal ? y+5 : y+17.5} 
                    fill={theme.palette.text.secondary}
                    fontSize={3}
                    textAnchor='middle'
                >
                    No data with       
                    <tspan 
                        x={x} 
                        y={horizontal ? y+8 : y+20.5} 
                        fill={theme.palette.text.secondary}
                        fontSize={3}
                        textAnchor='middle'
                    >current filters</tspan>
                </text>
            }
        </>

    const kpiOTIF = (value, change, x, y) =>
        <>
            <TransportIcon height='15' width='15' x={x-7.5} y={y-7.5} />
            <text 
                x={x} 
                y={y+12.5} 
                fill={theme.palette.text.primary}
                fontSize={4}
                textAnchor='middle'
            >
                OTIF       
            </text>
            {change ? 
                <>
                    <text 
                        x={x-10} 
                        y={y+17.5} 
                        fill={theme.palette.text.primary}
                        fontSize={3}
                        textAnchor='middle'
                    >
                        {`${(value*100).toFixed(1)}%`}
                        <tspan 
                            x={x-10} 
                            y={y+21.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >{`${currYear} Q${currQuarter}`}</tspan>         
                    </text>
                    <text 
                        x={x+10} 
                        y={y+17.5} 
                        fill={change > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail}
                        fontSize={3}
                        textAnchor='middle'
                    >
                        {`${change > 0 ? '+' : ''}${(change*100).toFixed(1)}%pt`}
                        <tspan 
                            x={x+10} 
                            y={y+21.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >vs. previous</tspan>
                        <tspan 
                            x={x+10} 
                            y={y+24.5} 
                            fill={theme.palette.text.secondary}
                            fontSize={2.5}
                            textAnchor='middle'
                        >quarter</tspan>
                    </text>
                </>
            : 
                <text 
                    x={x} 
                    y={y+17.5} 
                    fill={theme.palette.text.secondary}
                    fontSize={3}
                    textAnchor='middle'
                >
                    No data with       
                    <tspan 
                        x={x} 
                        y={y+20.5} 
                        fill={theme.palette.text.secondary}
                        fontSize={3}
                        textAnchor='middle'
                    >current filters</tspan>
                </text>
            }
        </>

    return (<Box style={{ userSelect: "none" }} >
        <svg style={{
                position: 'relative', 
                top: '0', left: '0', 
                fontFamily: theme.typography.fontFamily, 
                fontSize: `calc(${theme.typography.body2.fontSize}/3)`
            }} 
            height={height} 
            width={width} 
            xmlns="http://www.w3.org/2000/svg" 
            preserveAspectRatio="xMidYMax meet"
            viewBox={`0 0 ${svgWidth} ${svgHeight}`} 
        >
            <defs>
                <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="40%">
                    <stop offset="0%" stopColor="#86F200" stopOpacity="20%" />
                    <stop offset="100%" stopColor="#86F200" stopOpacity="0%" />
                </linearGradient>
            </defs>

            {/* Paths */}
            <g>
                {/* Top path */}
                <path d={`M ${marginHor+7.5} 87.5 
                        L ${marginHor+7.5} ${topLine+10}
                        A 10 10 0 0 1 ${marginHor+17.5} ${topLine}
                        L ${svgWidth - (marginHor+17.5)} ${topLine}
                        A 10 10 0 0 1 ${svgWidth - (marginHor+7.5)} ${topLine+10}
                        L ${svgWidth - (marginHor+7.5)} 87.5`} 
                        fill={'url(#gradient)'}
                        stroke={theme.palette.grey[700]}
                        strokeWidth={0.5}
                        strokeDasharray='3 2' />
                {/* Left middle path */}
                <path d={`M ${marginHor+12.5} 87.5 
                        L ${marginHor+12.5} ${midLine+5}
                        A 5 5 0 0 1 ${marginHor+17.5} ${midLine}
                        L ${centerHor-7.5} ${midLine}
                        A 5 5 0 0 1 ${centerHor-2.5} ${midLine+5}
                        L ${centerHor-2.5} 87.5`} 
                        fill={'url(#gradient)'}
                        stroke={theme.palette.grey[700]}
                        strokeWidth={0.5}
                        strokeDasharray='3 2' />
                {/* Right middle path */}
                <path d={`M ${svgWidth - (marginHor+12.5)} 87.5 
                        L ${svgWidth - (marginHor+12.5)} ${midLine+5}
                        A 5 5 0 0 0 ${svgWidth - (marginHor+17.5)} ${midLine}
                        L ${centerHor+7.5} ${midLine}
                        A 5 5 0 0 0 ${centerHor+2.5} ${midLine+5}
                        L ${centerHor+2.5} 87.5`}
                        fill={'url(#gradient)'}
                        stroke={theme.palette.grey[700]}
                        strokeWidth={0.5}
                        strokeDasharray='3 2' />
                {/* Left bottom path */}
                <path d={`M ${marginHor+22.5} 100 
                        L ${centerHor-15} 100`} 
                        stroke={theme.palette.grey[700]}
                        strokeWidth={0.5}
                        strokeDasharray='3 2' />
                {/* Right bottom path */}
                <path d={`M ${centerHor+15} 100 
                        L ${svgWidth - (marginHor+22.5)} 100`} 
                        stroke={theme.palette.grey[700]}
                        strokeWidth={0.5}
                        strokeDasharray='3 2' />
            </g>

            {/* Icons */}
            <g>
                {/* Factory */}
                <FactoryIcon height='20' width='20' x={marginHor} y={87.5} />
                <text 
                    x={marginHor+10} 
                    y={112} 
                    fill={theme.palette.text.primary}
                    fontSize={4}
                    textAnchor='middle'
                >
                    Factory
                </text>

                {/* Warehouse */}
                <WarehouseIcon height='20' width='20' x={centerHor-13.5} y={87.5} />
                <TruckIcon height='8' width='8' x={centerHor+5.5} y={100} />
                <text 
                    x={centerHor} 
                    y={112} 
                    fill={theme.palette.text.primary}
                    fontSize={4}
                    textAnchor='middle'
                >
                    Warehouse
                </text>

                {/* Store */}
                <StoreIcon height='17' width='20' x={svgWidth-(marginHor+20)} y={90.5} />
                <text 
                    x={svgWidth-(marginHor+10)} 
                    y={112} 
                    fill={theme.palette.text.primary}
                    fontSize={4}
                    textAnchor='middle'
                >
                    Store
                </text>
            </g>

            {/* KPIs */}
            <g>
                {/* Top row data */}
                {kpiCosts(data.totalCosts.value, data.totalCosts.change, (centerHor)/2 + 12.5, topLine)}
                {kpiOTIF(data.totalOTIF.value, data.totalOTIF.change, svgWidth-((centerHor)/2 + 12.5), topLine)}

                {/* Middle left data */}
                {kpiCosts(data.FGWHCosts.value, data.FGWHCosts.change, marginHor+10 + (centerHor-(marginHor+10))/4, midLine)}
                {kpiOTIF(data.FGWHOTIF.value, data.FGWHOTIF.change, marginHor+10 + 3*(centerHor-(marginHor+10))/4, midLine)}

                {/* Middle right data */}
                {kpiCosts(data.storeCosts.value, data.storeCosts.change, svgWidth - (marginHor+10 + 3*(centerHor-(marginHor+10))/4), midLine)}
                {kpiOTIF(data.storeOTIF.value, data.storeOTIF.change, svgWidth - (marginHor+10 + (centerHor-(marginHor+10))/4), midLine)}

                {/* Bottom data */}
                {kpiCosts(data.whCosts.value, data.whCosts.change, centerHor, 120, true)}
            </g>
        </svg>
    </Box>)
}

export default DynFulfillmentVisual;