import { useOutletContext } from "react-router";
import { sum, mean } from "../../util/arrayOperations";

import MainContentBox from "../../components/StyledElements/MainContentBox";
import DynFulfillmentVisual from "../../components/graphs/DynFullfillmentVisual";
import { relativeDiff } from "../../util/mathOperations";

function DynamicFulfillmentSummary() {
    const { currYear, currQuarter, filteredData } = useOutletContext();

    return <MainContentBox>
        <DynFulfillmentVisual height='100%' width='100%' currYear={currYear} currQuarter={currQuarter} data={getVisualData(filteredData.moveSummary, filteredData.warehouseSummary)} />
    </MainContentBox>

}

const getVisualData = (moveData, warehouseData) => {
    if (!(Array.isArray(moveData) && moveData.length > 0) || !(Array.isArray(warehouseData) && warehouseData.length > 0)) return {}

    let aggregatedMoveData = []
    let aggregatedWarehouseData = []
    let aggregatedElement = {}
    let currRound = 1
    let moveTemp = {
        totalCosts: [],
        totalOTIF: [],
        FGWHCosts: [],
        FGWHOTIF: [],
        storeCosts: [],
        storeOTIF: [],
        totalSize: [],
        FGWHSize: [],
        storeSize: [],
    }

    let warehouseTemp = {
        whCosts: [],
        size: []
    }

    // Aggregate move data
    moveData.forEach(element => {
        if (element.gameRound > currRound) {
            // Handle previous round aggregation
            aggregatedElement = {
                name: `Q${(currRound-1) % 4 + 1}`,
                totalCosts: sum(moveTemp.totalCosts),
                totalOTIF: mean(moveTemp.totalOTIF, moveTemp.totalSize),
                FGWHCosts: sum(moveTemp.FGWHCosts),
                FGWHOTIF: mean(moveTemp.FGWHOTIF, moveTemp.FGWHSize),
                storeCosts: sum(moveTemp.storeCosts),
                storeOTIF: mean(moveTemp.storeOTIF, moveTemp.storeSize),
                totalSize: sum(moveTemp.totalSize)
            }

            aggregatedMoveData.push(aggregatedElement)

            // Clear data of previous round
            moveTemp = {
                totalCosts: [],
                totalOTIF: [],
                FGWHCosts: [],
                FGWHOTIF: [],
                storeCosts: [],
                storeOTIF: [],
                totalSize: [],
                FGWHSize: [],
                storeSize: [],
            }

            currRound = element.gameRound
        }

        // Add values of element to temp if it belongs to the current round
        if (element.destType === 'FGWH') {
            moveTemp.FGWHCosts.push(element.costsTransport)
            moveTemp.totalCosts.push(element.costsTransport)
            moveTemp.FGWHOTIF.push(element.OTIF)
            moveTemp.totalOTIF.push(element.OTIF)
            moveTemp.FGWHSize.push(element.size)
            moveTemp.totalSize.push(element.size)
        }
        if (element.destType === 'CUSTOMER') {
            moveTemp.storeCosts.push(element.costsTransport)
            moveTemp.totalCosts.push(element.costsTransport)
            moveTemp.storeOTIF.push(element.OTIF)
            moveTemp.totalOTIF.push(element.OTIF)
            moveTemp.storeSize.push(element.size)
            moveTemp.totalSize.push(element.size)
        }
    });

    // Handle last round aggregation
    aggregatedElement = {
        name: `Q${(currRound-1) % 4 + 1}`,
        totalCosts: sum(moveTemp.totalCosts),
        totalOTIF: mean(moveTemp.totalOTIF, moveTemp.totalSize),
        FGWHCosts: sum(moveTemp.FGWHCosts),
        FGWHOTIF: mean(moveTemp.FGWHOTIF, moveTemp.FGWHSize),
        storeCosts: sum(moveTemp.storeCosts),
        storeOTIF: mean(moveTemp.storeOTIF, moveTemp.storeSize),
        totalSize: sum(moveTemp.totalSize)
    }

    aggregatedMoveData.push(aggregatedElement)
    
    // Aggregate warehouse data
    currRound = 1
    warehouseData.forEach(element => {
        if (element.gameRound > currRound) {
            // Handle previous round aggregation
            aggregatedElement = {
                name: `Q${(currRound-1) % 4 + 1}`,
                whCosts: sum(warehouseTemp.whCosts),
                size: sum(warehouseTemp.size)
            }

            aggregatedWarehouseData.push(aggregatedElement)

            // Clear data of previous round
            warehouseTemp = {
                whCosts: [],
                size: []
            }

            currRound = element.gameRound
        }

        // Add values of element to temp if it belongs to the current round
        for (const [key, value] of Object.entries(element)) {
            if (Object.keys(warehouseTemp).includes(key)) {
                warehouseTemp[key].push(value)
            }
        }
    });

    // Handle last round aggregation
    aggregatedElement = {
        name: `Q${(currRound-1) % 4 + 1}`,
        whCosts: sum(warehouseTemp.whCosts),
        size: sum(warehouseTemp.size)
    }

    aggregatedWarehouseData.push(aggregatedElement)
    
    let dataToReturn = {
        totalCosts: { value: aggregatedMoveData.at(-1).totalCosts, change: relativeDiff(aggregatedMoveData.at(-1).totalCosts, aggregatedMoveData.at(-2).totalCosts)},
        totalOTIF: { value: aggregatedMoveData.at(-1).totalOTIF, change: relativeDiff(aggregatedMoveData.at(-1).totalOTIF, aggregatedMoveData.at(-2).totalOTIF)},
        FGWHCosts: { value: aggregatedMoveData.at(-1).FGWHCosts, change: relativeDiff(aggregatedMoveData.at(-1).FGWHCosts, aggregatedMoveData.at(-2).FGWHCosts)},
        FGWHOTIF: { value: aggregatedMoveData.at(-1).FGWHOTIF, change: relativeDiff(aggregatedMoveData.at(-1).FGWHOTIF, aggregatedMoveData.at(-2).FGWHOTIF)},
        storeCosts: { value: aggregatedMoveData.at(-1).storeCosts, change: relativeDiff(aggregatedMoveData.at(-1).storeCosts, aggregatedMoveData.at(-2).storeCosts)},
        storeOTIF: { value: aggregatedMoveData.at(-1).storeOTIF, change: relativeDiff(aggregatedMoveData.at(-1).storeOTIF, aggregatedMoveData.at(-2).storeOTIF)},
        whCosts: { value: aggregatedWarehouseData.at(-1).whCosts, change: relativeDiff(aggregatedWarehouseData.at(-1).whCosts, aggregatedWarehouseData.at(-2).whCosts)},
    }

    return dataToReturn
}

export default DynamicFulfillmentSummary;