import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, XAxis, YAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getQuarters } from "../../util/dataOperations";

function DynamicFulfillmentServiceLoss() {
    const { currFilters, latestYear, currYear, currQuarter, typeData, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 2;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const CustomTick = (props) => {
        if (!props.payload.value) return null

        let origin = props.payload.value.split(" - ")[0]
        let destination = props.payload.value.split(" - ")[1]
        
        return <text 
            textAnchor="end"
            y={props.y-2} 
            fill={theme.palette.text.secondary}
            fontSize={10}
        >
            <tspan x={props.x - 130} textAnchor="start">From: </tspan><tspan x={props.x}>{origin}</tspan>
            <tspan x={props.x - 130} dy={10} textAnchor="start">To:   </tspan><tspan x={props.x} y={props.y+7}>{destination}</tspan>
        </text>
    }

    const overview = <GridColumnContent>
        {/* OTIF overview */}
        <Box height={`100%`}>
            <CustomPaper padding={1} sx={{ overflow: 'auto', height: '100%' }}>
                <SmallGraph 
                    key={`${key}-OTIF`} 
                    width={'100%'} 
                    height={'100%'} 
                    margin={{ left: 80 }}
                    minBarSize={30} 
                    layout='vertical' 
                    data={getOTIFByRoute(filteredData.moveSummary, typeData.origins, typeData.destinations)} 
                    noYAxis 
                    overflow
                >
                    <YAxis allowDataOverflow dataKey="name" yAxisId={'axis0'} type="category" interval={0} tick={<CustomTick />} />
                    <YAxis allowDataOverflow dataKey="name" yAxisId={'axis1'} type="category" interval={0} hide />
                    <XAxis hide allowDataOverflow domain={[0, dataMax => (dataMax * 1.2)]} type="number" />
                    <Bar name={`Last quarter`} dataKey={`OTIF`} yAxisId={'axis0'} maxBarSize={80} fill={colors[3].color} animationDuration={1000} legendType='none'>
                        <LabelList dataKey={`OTIF`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={12} />
                    </Bar>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const details = <GridColumnContent>
        {/* Quarter to date */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Quarter to date <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-QuarterToDate`} width={'100%'} height={'80%'} data={getTimeData(last8Quarters(aggregatedData.moveSummary), quarters)} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" type="category" interval={0} />
                    <Bar name={`On Time`} dataKey={`onTime`} fill={colors[2].color} animationDuration={1000}>
                        <LabelList dataKey={`onTime`} position="middle" fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                    </Bar>
                    <Bar name={`In Full`} dataKey={`inFull`} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`inFull`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                    </Bar>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Regional view */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Regional view <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-RegionalView`} width={'100%'} height={'80%'} data={getTimeDataByDestination(last8Quarters(aggregatedData.moveByDestination), typeData.destinations)} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" type="category" interval={0} />
                    <Bar name={`On Time`} dataKey={`onTime`} fill={colors[2].color} animationDuration={1000}>
                        <LabelList dataKey={`onTime`} position="middle" angle={270} fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                    </Bar>
                    <Bar name={`In Full`} dataKey={`inFull`} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`inFull`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                    </Bar>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* OTIF */}
        <GridItem>
            <CardTitle title="OTIF" />
            {overview}
        </GridItem>
        {/* OTIF details */}
        <GridItem>
            <CardTitle title="OTIF details" />
            {details}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

const getOTIFByRoute = (data, origins, destinations, offset=0, scalar=1) => {
    if (data.length === 0 || Object.keys(origins).length === 0 || Object.keys(destinations).length === 0) return []
    const dataToReturn = []
    Object.values(origins).forEach( (origin, index1) => {
        Object.values(destinations).forEach( (destination, index2) => {
            let dataEntry = getFromRoute(data, data.at(-1).gameRound, origin, destination)
            if (dataEntry) {
                dataToReturn[index1*Object.keys(destinations).length + index2] = { 
                    name: `${origin.originName} - ${destination.destName}`,
                    OTIF: scalar * dataEntry.OTIF + offset
                }
            }
        })
    })

    // Return the dataToReturn array without empty values and sorted on OTIF value
    return dataToReturn.filter( n => n ).sort( (a, b) => b.OTIF - a.OTIF)
}

const getFromRoute = (data, gameRound, origin, destination) => {
    let entries = data.filter((item) => {
            return item.gameRound === gameRound 
                && item.originID === origin.originID 
                && item.destID === destination.destID
        })

    return entries[0]
}

const getTimeData = (data, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    const lastQuarters = [...quarters].splice(4,4)
    lastQuarters.forEach( (quarter, index ) => {
        dataToReturn[index] = { name: quarter }
    })

    if (data.length > 0) {
        lastQuarters.forEach( (quarter, index) => {
            dataToReturn[index].onTime = scalar * data[index+4].onTime + offset;
            dataToReturn[index].inFull = scalar * data[index+4].inFull + offset;
        })
    }

    return dataToReturn
}

const getTimeDataByDestination = (data, destinations, offset=0, scalar=1) => {   
    if (Object.keys(destinations).length === 0) return {}

    const dataToReturn = []

    if (Object.keys(data).length > 0) {
        Object.values(destinations).forEach( (destination, index) => {
            if (data[destination.destID]) {
                dataToReturn[index] = { name: destination.destName }
                dataToReturn[index].onTime = scalar * data[destination.destID].at(-1).onTime + offset;
                dataToReturn[index].inFull = scalar * data[destination.destID].at(-1).inFull + offset;
            }
        })
    }

    return dataToReturn.filter(n => n)
}

export default DynamicFulfillmentServiceLoss;