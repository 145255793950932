import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function ErrorPage() {
    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(timeLeft-1);
        }, 1000);
    });

    const navigate = useNavigate()
    const [timeLeft, setTimeLeft] = useState(5)

    if (timeLeft <= 0) { navigate('/') }

    return <Box>
        <Typography variant="h2">Something went wrong...</Typography>
        <Typography variant="h3">Redirecting in {timeLeft} seconds</Typography>
    </Box>
}

export default ErrorPage