const ResponsiveLineLabel = (props) => {
    const { data, thisKey, otherKey, index, value, formatter, x, y, offset, fill } = props

    return <text 
        x={x}
        y={data[index][thisKey] > data[index][otherKey] ? y-5 : y+20}
        fill={fill}
        textAnchor='middle'
    >
        {value !== null && formatter(value)}
    </text>
}

export default ResponsiveLineLabel