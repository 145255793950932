import { useTheme } from '@emotion/react';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { round } from '../util/mathOperations';
    
const KPI = (props) =>
    <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ height: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography variant='body1' color={props.color || "text.primary"} sx={{ fontSize: props.fontSize, fontWeight: props.bold ? 'bold' : null }}>{props.value}</Typography>
        </Box>
        <Box sx={{ height: '50%', display: 'flex', justifyContent: 'center' }}>
            <Typography variant='caption' color="text.secondary">{props.subText}</Typography>
        </Box>
    </Box>

const success = (value, inversed) => {
    // Return whether value >= 0 or opposite when inversed
    return !inversed ? value >= 0 : value < 0
}

function KPIs(props) {
    const theme = useTheme();

    const { average, target, lastQuarter, lastYear, currQuarter, currYear, inversed } = props

    return (
        <Box padding={1} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", '> .MuiBox-root': { width: "30%" }, ...props.sx }}>
            {average ? 
                <KPI 
                    subText={`${average.subText ? `${average.subText}\n` : ''}${currYear} Q${currQuarter}`} 
                    value={average.format(average.value.toFixed(average.decimals !== undefined ? average.decimals : 0))} 
                    fontSize={average.fontSize || null} 
                /> 
            : null}
            {target ? 
                <KPI 
                    subText={`Target`} 
                    value={target.format(target.value.toFixed(target.decimals !== undefined ? target.decimals : 1))} 
                />
            : null}
            {lastQuarter ? 
                <KPI 
                    subText={`vs. previous quarter`} 
                    value={lastQuarter.format(`${lastQuarter.value < 0 ? "" : "+"}${lastQuarter.value.toFixed(lastQuarter.decimals !== undefined ? lastQuarter.decimals : 1)}`)} 
                    color={ success(lastQuarter.value, inversed) ? theme.palette.kpi.success : theme.palette.kpi.fail } 
                    bold
                /> 
            : null}
            {lastYear ? 
                <KPI 
                    subText={`vs. last year`} 
                    value={lastYear.format(`${lastYear.value < 0 ? "" : "+"}${lastYear.value.toFixed(lastYear.decimals !== undefined ? lastYear.decimals : 1)}`)} 
                    color={ success(lastYear.value, inversed) ? theme.palette.kpi.success : theme.palette.kpi.fail } 
                    bold
                /> 
            : null}
        </Box>
    );
}

export default KPIs;