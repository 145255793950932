import { useEffect, useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, Label, LabelList, Line, ReferenceLine, Scatter, XAxis, YAxis, ZAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import ScatterPlot from "../../components/graphs/ScatterPlot";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategorySingleQuarter, getLastYear, getQuarters } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function IntelligentSupplyMaterials() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const materials = Object.keys(aggregatedData.byMaterial)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const materialsDOH = <GridColumnContent>
        {/* Wide bar graph */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Raw materials on hand <i>(days)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-WideBar`} width={'100%'} height={'100%'} data={getByCategorySingleQuarter(last8Quarters(aggregatedData.byMaterial), 'rmDOH', materials)} noBottomScale>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {/* Bar of current year */}
                    <Bar name={`Last quarter`} dataKey={`prevQuarter`} xAxisId={'axis0'} maxBarSize={60} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`prevQuarter`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                    </Bar>
                    {/* Diamond to indicate previous year */}
                    <Bar 
                        name={`Last year`} 
                        dataKey={`prevYear`} 
                        xAxisId={'axis1'}
                        maxBarSize={60}
                        animationDuration={1000} 
                        legendType='none'
                        shape={(props) => renderDiamond(props, theme)} 
                    />
                    <Line name={`Last year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.grey[700]} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Separate graphs */}
        <Box height={`calc(50% - ${theme.spacing(1)})`} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            {/* Scatter plot */}
            <Box width={`calc(50% - ${theme.spacing(1)})`} mr={1}>
                <CustomPaper padding={1}>
                    <Box height={'15%'}>
                        <Typography variant="body2" align="left">
                            Raw materials on hand vs. Total spend
                        </Typography>
                    </Box>
                    <ScatterPlot key={`${key}-Scatter`} width={'100%'} height={'80%'}>
                        <XAxis dataKey={'rmDOH'} type="number" domain={[0, dataMax => round(dataMax * 1.1, 2-Math.ceil(Math.log10(dataMax + 1)))]} >
                            <Label value={'Raw materials on hand (days)'} fill={theme.palette.grey[700]} position="bottom" />
                        </XAxis>
                        <YAxis dataKey={'costsRmTotalSpend'} type="number" domain={[0, dataMax => round(dataMax * 1.1, 2-Math.ceil(Math.log10(dataMax + 1)))]} >
                            <Label value={"Total spend (million \u20AC)"} fill={theme.palette.grey[700]} position="insideBottomLeft" angle={270} />
                        </YAxis>
                        <ZAxis dataKey={'nothing'} range={[200, 500]} type="number" />
                        <Scatter name="test" data={getScatterData(last8Quarters(aggregatedData.byMaterial), materials, quarters)} fill={colors[3].color} shape="circle" animationDuration={0}>
                            <LabelList dataKey={`name`} position="left" fill={theme.palette.text.secondary} fontSize={12} />
                        </Scatter>
                    </ScatterPlot>
                </CustomPaper>
            </Box>
            {/* Bar graph */}
            <Box width={`calc(50% - ${theme.spacing(1)})`}>
                <CustomPaper padding={1}>
                    <Box height={'15%'}>
                        <Typography variant="body2" align="left">
                            Raw materials on hand by finished good <i>(days)</i>
                        </Typography>
                    </Box>
                    <SmallGraph key={`${key}-Bar`} width={'100%'} height={'100%'} data={getByCategorySingleQuarter(last8Quarters(aggregatedData.byProduct), 'rmDOH', products)} >
                        <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                        <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                        <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                        {/* Bar of current year */}
                        <Bar name={`Last quarter`} dataKey={`prevQuarter`} xAxisId={'axis0'} maxBarSize={60} fill={colors[3].color} animationDuration={1000}>
                            <LabelList dataKey={`prevQuarter`} position="middle" angle={270} fill={colors[3].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`Last year`} 
                            dataKey={`prevYear`} 
                            xAxisId={'axis1'}
                            maxBarSize={60}
                            animationDuration={1000} 
                            legendType='none'
                            shape={(props) => renderDiamond(props, theme)} 
                            />
                        <Line name={`Last year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.grey[700]} legendType='diamond' />
                    </SmallGraph>
                </CustomPaper>
            </Box>
        </Box>
    </GridColumnContent>

    const suppliedData = getLastYear(fromLastYearData, 'supplierReliabilityOnTime', currQuarter, currYear, 0, 100)
    const supplied = <GridColumnContent>
        {/* Supplied in-full */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-SuppliedInFull`} width={'100%'} height={'80%'} data={suppliedData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                    <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={suppliedData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={suppliedData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Raw materials days-on-hand */}
        <GridItem sx={{ gridColumnStart: 1, gridColumnEnd: 3}}>
            <CardTitle title="Raw materials onhand" />
            {materialsDOH}
        </GridItem>
        {/* Supplied in-full */}
        <GridItem>
            <CardTitle title="Supplied in-full" />
            {supplied}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

const round = (number, digits) => {
    return Math.round(number * Math.pow(10, digits))/Math.pow(10, digits)
}

const getScatterData = (data, materials) => {   
    const dataToReturn = []
    materials.forEach( (material, index ) => {
        dataToReturn[index] = { name: material }
    })

    if (Object.keys(data).length > 0) {
        materials.forEach( (material, index) => {
            dataToReturn[index].rmDOH = (data[material].at(-1).rmDOH + data[material].at(-2).rmDOH + data[material].at(-3).rmDOH + data[material].at(-4).rmDOH) / 4;
            dataToReturn[index].costsRmTotalSpend = (data[material].at(-1).costsRmTotalSpend + data[material].at(-2).costsRmTotalSpend + data[material].at(-3).costsRmTotalSpend + data[material].at(-4).costsRmTotalSpend) / 1000000;
        })
    }

    return dataToReturn
}

export default IntelligentSupplyMaterials;