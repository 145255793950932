import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import { Box } from "@mui/material";
import NoDataMessage from "../../components/NoDataMessage";
import { aggregateCategorizedData, aggregateData, filterCategorizedData, filterData, filterInfo } from "../../util/dataOperations";
import LoadingPage from "../LoadingPage";
import { fetchFromApi } from "../../util/fetchFromApi";
import { useQuery } from "react-query";

const filterSkeleton = JSON.stringify([
    {
        type: "finishedGoodName",
        label: "finishedGoodName",
        name: "Product Group",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "region",
        label: "region",
        name: "Region",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "plantCountry",
        label: "plantCountry",
        name: "Country",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "plantID",
        label: "plantName",
        name: "Plant",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
])

function SmartFactoryLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Fetch data for synchronized planning
    const { gameId } = useParams()

    // Get info on types
    const {data: plantInfo} = useQuery(`/info/plants/${gameId}`, fetchFromApi)
    const {data: productInfo} = useQuery(`/info/products/${gameId}`, fetchFromApi)
    // Get transactional data
    const {data: dataSummary} = useQuery(`/smartfactory/${gameId}/summary`, fetchFromApi)
    const {data: dataByRegion} = useQuery(`/smartfactory/${gameId}/summary/region`, fetchFromApi)
    const {data: dataByProduct} = useQuery(`/smartfactory/${gameId}/summary/finishedGoodName`, fetchFromApi)

    let initialYear = null
    let latestYear = null
    let latestQuarter = null

    // Handle incoming data
    if (!isLoading) {
        initialYear = dataSummary[0].gameYear
        latestYear = dataSummary.at(-1).gameYear
        latestQuarter = (dataSummary.at(-1).gameRound - 1) % 4 + 1
    }


    // Set correct paths to subpages
    const tabs = [
        {link: `/${gameId}/smartfactory/summary`, name: "Summary"},
        {link: `/${gameId}/smartfactory/performance`, name: "Performance"},
        {link: `/${gameId}/smartfactory/oee`, name: "OEE"},
        {link: `/${gameId}/smartfactory/cost`, name: "Cost"},
    ]

    const [currYear, setCurrYear] = useState(latestYear);
    
    // Filters for synchronized planning
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }

    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }

    const initialFilters = {
        plantID: 'ALL',
        region: 'ALL', 
        plantCountry: 'ALL',
        finishedGoodName: 'ALL'
    }
    
    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredPlantInfo, setFilteredPlantInfo] = useState({})
    const [filteredDataSummary, setFilteredDataSummary] = useState([])
    const [filteredDataByRegion, setFilteredDataByRegion] = useState({})
    const [filteredDataByProduct, setFilteredDataByProduct] = useState({})

    useEffect(() => {
        if (isLoading) return
        
        let newFilters = JSON.parse(filterSkeleton)
        let temp = []

        Object.values(productInfo).forEach((product) => {
            newFilters[0].options.push({value: product[newFilters[0].type], label: product[newFilters[0].label]})
        })

        temp = []
        Object.values(plantInfo).forEach((plant) => {
            if (!temp.includes(plant[newFilters[1].type])) {
                newFilters[1].options.push({value: plant[newFilters[1].type], label: plant[newFilters[1].label]})
                temp.push(plant[newFilters[1].type])
            }
        })

        temp = []
        if (currFilters[newFilters[1].type] !== 'ALL') {
            Object.values(filteredPlantInfo).forEach((plant) => {
                if (!temp.includes(plant[newFilters[2].type])) {
                    newFilters[2].options.push({value: plant[newFilters[2].type], label: plant[newFilters[2].label]})
                    temp.push(plant[newFilters[2].type])
                }
            })
        }

        temp = []
        if (currFilters[newFilters[2].type] !== 'ALL') {
            Object.values(filteredPlantInfo).forEach((plant) => {
                if (!temp.includes(plant[newFilters[3].type])) {
                    newFilters[3].options.push({value: plant[newFilters[3].type], label: plant[newFilters[3].label]})
                    temp.push(plant[newFilters[3].type])
                }
            })
        }

        setFilters(newFilters)
    }, [currFilters, productInfo, plantInfo, filteredPlantInfo])

    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return
        
        setFilteredPlantInfo(filterInfo(plantInfo, currFilters))
    }, [plantInfo, currFilters])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return
        
        setCurrYear(latestYear)
        setFilteredDataSummary(filterData(dataSummary, currFilters))
        setFilteredDataByRegion(filterCategorizedData(dataByRegion, currFilters))
        setFilteredDataByProduct(filterCategorizedData(dataByProduct, currFilters))
    }, [latestYear, dataSummary, dataByRegion, dataByProduct, currFilters])

    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
            if (type === filters[1].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[2].type]: 'ALL', [filters[3].type]: 'ALL'})
            } else if (type === filters[2].type) {
                setCurrFilters({...currFilters, [type]: event.target.value, [filters[3].type]: 'ALL'})
            }
        }
    };
    
    const filterReset = () => {
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"smartfactory"} departmentName={"Smart Factory"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            filteredData: {summary: filteredDataSummary, byRegion: filteredDataByRegion, byProduct: filteredDataByProduct}, 
                            aggregatedData: {
                                summary: aggregateData(filteredDataSummary, aggFunctions), 
                                // byRegion: aggregateCategorizedData(filteredDataByRegion), 
                                byProduct: aggregateCategorizedData(filteredDataByProduct, aggFunctions)
                            }
                        }} />
                    :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const aggFunctions = {
    'costsLabor': 'sum',
    'costsMaintenancePlanned': 'sum',
    'costsMaintenanceUnplanned': 'sum',
    'costsMaintenanceTotal': 'sum',
    'costsUtilities': 'sum',
    'costsOperating': 'sum',
    'costsOverhead': 'sum',
    'costsManufacturingTotal': 'sum',
    'costsUnitProduction': 'mean',
    'oeePerformance': 'mean',
    'oeeQuality': 'mean',
    'oeeAvailability': 'mean',
    'oeeKPI': 'mean',
    'cycleTime': 'mean',
    'planningAdherence': 'mean',
}

export default SmartFactoryLayout;