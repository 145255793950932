import './styling/App.scss';

import { createTheme, CssBaseline, lighten, ThemeProvider } from '@mui/material';
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import { QueryClient, QueryClientProvider } from 'react-query'

import GameSelect from './pages/GameSelect';
import Home from './pages/Home';
import ErrorPage from './pages/Error';
import PageLayout from './pages/layouts/PageLayout';

import DynamicFulfillmentLayout from './pages/layouts/DynamicFulfillmentLayout';
import DynamicFulfillmentSummary from "./pages/DynamicFulfillment/DynamicFulfillmentSummary";
import DynamicFulfillmentFulfillment from "./pages/DynamicFulfillment/DynamicFulfillmentFulfillment";
import DynamicFulfillmentServiceLoss from "./pages/DynamicFulfillment/DynamicFulfillmentServiceLoss";
import DynamicFulfillmentWarehouses from "./pages/DynamicFulfillment/DynamicFulfillmentWarehouses";

import ExecutiveViewLayout from './pages/layouts/ExecutiveViewLayout';
import ExecutiveViewSummary from './pages/ExecutiveView/ExecutiveViewSummary';

import IntelligentSupplyLayout from './pages/layouts/IntelligentSupplyLayout';
import IntelligentSupplySummary from './pages/IntelligentSupply/IntelligentSupplySummary';
import IntelligentSupplySpend from './pages/IntelligentSupply/IntelligentSupplySpend';
import IntelligentSupplySupplier from './pages/IntelligentSupply/IntelligentSupplySupplier';
import IntelligentSupplyMaterials from './pages/IntelligentSupply/IntelligentSupplyMaterials';

import SmartFactoryLayout from './pages/layouts/SmartFactoryLayout';
import SmartFactorySummary from './pages/SmartFactory/SmartFactorySummary';

import SynchronizedPlanningLayout from './pages/layouts/SynchronizedPlanningLayout';
import SynchronizedPlanningSummary from "./pages/SyncronizedPlanning/SynchronizedPlanningSummary";
import SynchronizedPlanningDemand from "./pages/SyncronizedPlanning/SynchronizedPlanningDemand";
import SynchronizedPlanningInventory from "./pages/SyncronizedPlanning/SynchronizedPlanningInventory";
import SynchronizedPlanningSupply from "./pages/SyncronizedPlanning/SynchronizedPlanningSupply";

import SustainabilityLayout from './pages/layouts/SustainabilityLayout';
import SustainabilitySummary from './pages/Sustainability/SustainabilitySummary';
import SmartFactoryPerformance from './pages/SmartFactory/SmartFactoryPerformance';
import SmartFactoryOEE from './pages/SmartFactory/SmartFactoryOEE';
import SmartFactoryCost from './pages/SmartFactory/SmartFactoryCost';
import DynamicFulfillmentDynamicMap from './pages/DynamicFulfillment/DynamicFulfillmentDynamicMap';
import ExecutiveViewWaterfall from './pages/ExecutiveView/ExecutiveViewWaterfall';


const router = createBrowserRouter([
    {
        path: "/",
        element: <GameSelect />,
    },
    {
        path: "/error",
        element: <ErrorPage />,
    },
    {
        path: "/:gameId/",
        element: <PageLayout />,
        children: [
            {
                path: "",
                element: <Home />,
            },
            {
                path: "executiveview",
                element: <ExecutiveViewLayout />,
                children: [
                    {
                        path: "summary",
                        element: <ExecutiveViewSummary />
                    }, 
                    {
                        path: "waterfall",
                        element: <ExecutiveViewWaterfall />
                    },
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
            {
                path: "dynamicfulfillment",
                element: <DynamicFulfillmentLayout />,
                children: [
                    {
                        path: "summary",
                        element: <DynamicFulfillmentSummary />
                    }, 
                    {
                        path: "fulfillment",
                        element: <DynamicFulfillmentFulfillment />
                    },
                    {
                        path: "serviceloss",
                        element: <DynamicFulfillmentServiceLoss />
                    },
                    {
                        path: "warehouses",
                        element: <DynamicFulfillmentWarehouses />
                    },
                    {
                        path: "dynamicmap",
                        element: <DynamicFulfillmentDynamicMap />
                    },
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
            {
                path: "synchronizedplanning",
                element: <SynchronizedPlanningLayout />,
                children: [
                    {
                        path: "summary",
                        element: <SynchronizedPlanningSummary />
                    }, 
                    {
                        path: "demand",
                        element: <SynchronizedPlanningDemand />
                    },
                    {
                        path: "inventory",
                        element: <SynchronizedPlanningInventory />
                    },
                    {
                        path: "supply",
                        element: <SynchronizedPlanningSupply />
                    },
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
            {
                path: "smartfactory",
                element: <SmartFactoryLayout />,
                children: [
                    {
                        path: "summary",
                        element: <SmartFactorySummary />
                    }, 
                    {
                        path: "performance",
                        element: <SmartFactoryPerformance />
                    },
                    {
                        path: "oee",
                        element: <SmartFactoryOEE />
                    },
                    {
                        path: "cost",
                        element: <SmartFactoryCost />
                    },
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
            {
                path: "sustainability",
                element: <SustainabilityLayout />,
                children: [
                    {
                        path: "summary",
                        element: <SustainabilitySummary />
                    }, 
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
            {
                path: "intelligentsupply",
                element: <IntelligentSupplyLayout />,
                children: [
                    {
                        path: "summary",
                        element: <IntelligentSupplySummary />
                    }, 
                    {
                        path: "spend",
                        element: <IntelligentSupplySpend />
                    },
                    {
                        path: "supplier",
                        element: <IntelligentSupplySupplier />
                    },
                    {
                        path: "materials",
                        element: <IntelligentSupplyMaterials />
                    },
                    {
                        path: "*",
                        element: <Navigate to="summary" />,
                    }
                ]
            },
        ]
    },
    {
        path: "*",
        element: <Navigate to="/" />,
    }
]);

const deloitteTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: "#86F200",
            light: "#CFFF94",
            dark: "#64B500"
        },
        secondary: {
            main: "#34F0FF",
            light: "#AEF9FF",
            dark: "#00D5E6"
        },
        background: {
            paper: lighten('#121212', 0.1)
        },
        kpi: {
            success: '#86F200',
            fail: '#e41a0c'
        },
        treeColors: {
            red: '#e41a0c',
            orange: '#f0c000',
            green: '#86F200'
        },
        graphColors: [],
        diamond: {
            color: '#34F0FF',
            opacity: 0.6
        }
    },
    typography: {
        fontFamily: "Open Sans, sans-serif",
    },
    sizes: {
        appBarHeight: 9,
        filterHeight: 9,
        cardTitleHeight: 4,
    },
    dynamicMap: {
        scale: [1,4],
        lineOpacity: 0.7
    }
})

deloitteTheme.palette.graphColors = [
    { color: deloitteTheme.palette.grey[500], contrastText: deloitteTheme.palette.common.black },
    { color: deloitteTheme.palette.primary.light, contrastText: deloitteTheme.palette.primary.contrastText },
    { color: deloitteTheme.palette.primary.main, contrastText: deloitteTheme.palette.primary.contrastText },
    { color: deloitteTheme.palette.primary.dark, contrastText: deloitteTheme.palette.primary.contrastText },
]

const queryClient = new QueryClient()

function App() {
    return <div className="App">
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={deloitteTheme}>
                <CssBaseline />
                <RouterProvider router={router} />
            </ThemeProvider>
        </QueryClientProvider>
    </div>;
}

export default App;
