import { Box, Typography } from "@mui/material";

const CardTitle = (props) => 
<Box sx={{ 
    position: 'relative',
    width: "100%",
    height: (theme) => theme.spacing(theme.sizes.cardTitleHeight),
    justifyContent: "center",
    '> .MuiIconButton-root': { backgroundColor: "red"} 
}}>
    <Typography variant='h6' textAlign={'left'}>
        {props.title}
    </Typography>
</Box>

export default CardTitle
