import { Box, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";

function GameSelect() {
    return <Box>
        <Typography variant='h2'>DSN LAB Game Selection</Typography>
        <Link to="/test">Test game</Link><br />
    </Box>
}

export default GameSelect;