import { mean, sum } from "./arrayOperations"

export const filterInfo = (info, filters) => {
    const output = {}
    for (const [key, value] of Object.entries(info)) {
        if (filterFunc(value, filters)) {
            output[key] = value
        }
    }

    return output
}

export const filterData = (data, filters) => {
    if (!Array.isArray(data)) return []
    let filteredData = data.filter((item) => filterFunc(item, filters))
    return filteredData
}

export const filterCategorizedData = (data, filters) => {
    let output = {}
    for (const [key, value] of Object.entries(data)) {
        let filteredData = filterData(value, filters)
        if (filteredData.length > 0) {
            output[key] = filteredData
        }
    }

    return output
}

export const filterFunc = (item, filters) => {
    for (const [key, value] of Object.entries(filters)) {
        if (value !== 'ALL' && item[key] !== value && Object.keys(item).includes(key)) { return false }
    }
    return true
}

export const aggregateData = (data, aggregationFunctions) => {
    if (typeof(data) !== typeof([])) return []

    let aggregatedData = []

    let currRound = 1
    let round_data = {}

    for (const [key, value] of Object.entries(aggregationFunctions)) {
        round_data[key] = []
    }
    round_data.size = []

    data.forEach(element => {
        if (element.gameRound > currRound) {
            // Handle previous round aggregation
            aggregatedData.push(aggregate(round_data, currRound, aggregationFunctions))

            // Clear data of previous round
            for (const [key, value] of Object.entries(aggregationFunctions)) {
                round_data[key] = []
            }
            round_data.size = []

            currRound = element.gameRound
        }

        // Add values of element to round_data if it belongs to the current round
        for (const [key, value] of Object.entries(element)) {
            if (Object.keys(round_data).includes(key)) {
                round_data[key].push(value)
            }
        }
    });

    // Handle last round if it needs to be handled
    if (round_data.size.length > 0) {
        aggregatedData.push(aggregate(round_data, currRound, aggregationFunctions))
    }

    return aggregatedData
}    

const aggregate = (round_data, currRound, aggFunctions) => {
    let dataToReturn = {
        name: `Q${(currRound-1) % 4 + 1}`,
        size: sum(round_data.size)
    }
    for (const [key, value] of Object.entries(aggFunctions)) {
        switch (value) {
            case 'first': 
                dataToReturn[key] = round_data[key][0]; 
                break;
            case 'sum': 
                dataToReturn[key] = sum(round_data[key]); 
                break;
            case 'mean': 
                dataToReturn[key] = mean(round_data[key], round_data.size); 
                break;
        }
    }
    return dataToReturn
}

export const aggregateCategorizedData = (data, aggregationFunctions) => {
    let output = {}
    for (const [key, value] of Object.entries(data)) {
        output[key] = aggregateData(value, aggregationFunctions)
    }

    return output
}

export const fromInterval = (data, interval) => {
    if (Array.isArray(data)) {
        return [...data].slice(interval[0], interval[1])
    } else if (typeof data === 'object') {
        let output = {}
        for (const [key, value] of Object.entries(data)) {
            output[key] = [...value].slice(interval[0], interval[1])
        }
        return output
    } else {
        return null
    }
}

export const getLastYear = (data, key, currQuarter, currYear, offset=0, scalar=1) => {
    const dataToReturn = [
        {
            name: 'Q1',
        },
        {
            name: 'Q2',
        },
        {
            name: 'Q3',
        },
        {
            name: 'Q4',
        },
    ]
    
    if (data.length > 0) {
        dataToReturn[0][`${currYear}`] = scalar * data[4][key] + offset;
        dataToReturn[0][`${currYear-1}`] = scalar * data[0][key] + offset;

        dataToReturn[1][`${currYear}`] = currQuarter > 1 ? scalar * data[5][key] + offset : null;
        dataToReturn[1][`${currYear-1}`] = scalar * data[1][key] + offset;

        dataToReturn[2][`${currYear}`] = currQuarter > 2 ? scalar * data[6][key] + offset : null;
        dataToReturn[2][`${currYear-1}`] = scalar * data[2][key] + offset;

        dataToReturn[3][`${currYear}`] = currQuarter > 3 ? scalar * data[7][key] + offset : null;
        dataToReturn[3][`${currYear-1}`] = scalar * data[3][key] + offset;
    }

    return dataToReturn
}

export const getByCategory = (data, key, options, quarters, offset=0, scalar=1) => { 
    if (!data) return []

    const dataToReturn = []
    options.forEach( (option, index ) => {
        dataToReturn[index] = { name: option.split(" ").at(-1) }
    })

    if (Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            options.forEach( (option, index2) => {
                dataToReturn[index2][quarter] = scalar * data[option][index1][key] + offset;
            })
        })
    }

    return dataToReturn
}

export const getByCategorySingleQuarter = (data, key, options, offset=0, scalar=1) => {   
    if (!data) return []

    const dataToReturn = []
    options.forEach( (option, index ) => {
        dataToReturn[index] = { name: option.split(" ").at(-1) }
    })

    if (Object.keys(data).length > 0) {
        options.forEach( (option, index) => {
            dataToReturn[index].prevQuarter = scalar * data[option].at(-1)[key] + offset;
            dataToReturn[index].prevYear = data[option].length > 4 ? scalar * data[option].at(-5)[key] + offset : null;
        })
    }

    return dataToReturn
}

export const getQuarters = (currQuarter, currYear) => {
    return [
        currQuarter > 3 ? `${(currYear-1)%100}Q${(currQuarter) % 4 + 1}` : `${(currYear-2)%100}Q${(currQuarter) % 4 + 1}`,
        currQuarter > 2 ? `${(currYear-1)%100}Q${(currQuarter+1) % 4 + 1}` : `${(currYear-2)%100}Q${(currQuarter+1) % 4 + 1}`,
        currQuarter > 1 ? `${(currYear-1)%100}Q${(currQuarter+2) % 4 + 1}` : `${(currYear-2)%100}Q${(currQuarter+2) % 4 + 1}`,
        `${(currYear-1)%100}Q${currQuarter}`,

        currQuarter > 3 ? `${currYear%100}Q${(currQuarter) % 4 + 1}` : `${(currYear-1)%100}Q${(currQuarter) % 4 + 1}`,
        currQuarter > 2 ? `${currYear%100}Q${(currQuarter+1) % 4 + 1}` : `${(currYear-1)%100}Q${(currQuarter+1) % 4 + 1}`,
        currQuarter > 1 ? `${currYear%100}Q${(currQuarter+2) % 4 + 1}` : `${(currYear-1)%100}Q${(currQuarter+2) % 4 + 1}`,
        `${currYear%100}Q${currQuarter}`
    ]
}

export const yearToDate = (data, key, currQuarter, aggType = 'sum', offset = 0) => {
    let returnValue = 0;

    for (let i = 1; i <= currQuarter; i++) {
        returnValue += data.at(-i + offset)[key];
    }

    if (aggType === 'avg') {
        returnValue /= currQuarter;
    }

    return returnValue;
}
