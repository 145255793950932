import { Box } from "@mui/material";
import CardTitle from "./CardTitle";

const IconColumn = (props) =>
<>
    <Box sx={{ height: '40%', padding: 4, paddingTop: 0 }} >
        {props.icon}
    </Box>
    <CardTitle title={props.title} />
    <Box sx={{ height: (theme) => `calc(60% - ${theme.spacing(theme.sizes.cardTitleHeight)})` }} >
        {props.kpis}
    </Box>
</>

export default IconColumn