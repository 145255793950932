import { Box, IconButton } from "@mui/material"
import ResetIcon from "@mui/icons-material/RotateLeft"
import DropDown from "./Dropdown"

const Filters = (props) =>
<Box id="Filters" padding={1} sx={(theme) => ({ flexGrow: 1, textAlign: 'left', height: theme.spacing(theme.sizes.filterHeight) })}>
    <Box sx={{ 
        display: 'flex', 
        flexDirection: 'row', 
        flexGrow: 1, 

        '& .MuiTextField-root, & .MuiFormControl-root': { 
            m: 1, 
            width: '100%'
        },
    }}>
        {/* Time dropdown */}
        { props.timeValue ?
            <Box key={`filter-${props.timeFilter.name}`} sx={(theme) => ({width: theme.spacing(15), marginRight: theme.spacing(2)})}>
                <DropDown type={props.timeFilter.name} options={props.timeFilter.options} value={props.timeValue} handleChange={(type, event) => props.handleChange(props.timeFilter.type, event)} />
            </Box>
            : null
        }
        {/* Filter dropdowns */}
        {props.filters.map((filter) => {
            return filter.options.length > 1 ?
                <Box key={`filter-${filter.name}`} sx={(theme) => ({width: props.filters.filter(f => f.options.length > 1).length <= 4 ? theme.spacing(25) : '100%', marginRight: theme.spacing(2)})}>
                    <DropDown type={filter.name} options={filter.options} value={props.values[filter.type]} handleChange={(type, event) => props.handleChange(filter.type, event)} />
                </Box>
                : null 
            }
        )}

        {/* Filter reset button */}
        <Box>
            <IconButton sx={{width: '100%', height: '100%'}} onClick={props.filterReset}>
                <ResetIcon />
            </IconButton>
        </Box>
    </Box>
</Box>


export default Filters