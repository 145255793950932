import { Box, styled, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import KPIs from "../../components/KPIs";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";

import CustomPaper from "../../components/StyledElements/CustomPaper";
import IconColumn from "../../components/StyledElements/IconColumn";

import { ReactComponent as SpendIcon } from "../../assets/icons/IconSupply1.svg"
import { ReactComponent as PerformanceIcon } from "../../assets/icons/IconSupply2.svg"
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval } from "../../util/dataOperations";
import { relativeDiff } from "../../util/mathOperations";

const ContentBox = styled(Box)({
    height: '100%',
    display: 'flex', 
    flexDirection: 'column', 
    justifyContent: 'space-evenly'
})

function IntelligentSupplySummary() {
    const { latestYear, currYear, currQuarter, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const summaryLast8Quarters = last8Quarters(aggregatedData.summary) || []
    const spend = <GridColumnContent fullHeight>
        {/* Direct spend */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Total spend
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).costsRmTotalSpend/1000000000, format: (value) => `\u20AC${value}B`, decimals: 2, fontSize: 25}}
                        lastQuarter={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsRmTotalSpend, summaryLast8Quarters.at(-2).costsRmTotalSpend)*100, 
                            format: (value) => `${value}%`
                        }} 
                        lastYear={{
                            value: relativeDiff(summaryLast8Quarters.at(-1).costsRmTotalSpend, summaryLast8Quarters.at(-5).costsRmTotalSpend)*100, 
                            format: (value) => `${value}%`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Contractual spend */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Contractual spend
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: contractualSpend(summaryLast8Quarters.at(-1))*100, format: (value) => `${value}%`, fontSize: 25}}
                        lastQuarter={{
                            value: (contractualSpend(summaryLast8Quarters.at(-1)) - contractualSpend(summaryLast8Quarters.at(-2)))*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (contractualSpend(summaryLast8Quarters.at(-1)) - contractualSpend(summaryLast8Quarters.at(-5)))*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const performance = <GridColumnContent fullHeight>
        {/* Supplier reliability (OTIF) */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                        Supplier reliability (OTIF)
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).supplierReliabilityOTIF*100, format: (value) => `${value}%`, fontSize: 25}}
                        lastQuarter={{
                            value: (summaryLast8Quarters.at(-1).supplierReliabilityOTIF - summaryLast8Quarters.at(-2).supplierReliabilityOTIF)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (summaryLast8Quarters.at(-1).supplierReliabilityOTIF - summaryLast8Quarters.at(-5).supplierReliabilityOTIF)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
        {/* Supplier quality */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <ContentBox>
                    <Typography variant="body1" color={'text.primary'} align="center">
                    Supplier quality
                    </Typography>
                    <KPIs currQuarter={currQuarter} currYear={currYear}
                        average={{value: summaryLast8Quarters.at(-1).supplierQuality*100, format: (value) => `${value}%`, fontSize: 25}}
                        lastQuarter={{
                            value: (summaryLast8Quarters.at(-1).supplierQuality - summaryLast8Quarters.at(-2).supplierQuality)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                        lastYear={{
                            value: (summaryLast8Quarters.at(-1).supplierQuality - summaryLast8Quarters.at(-5).supplierQuality)*100, 
                            format: (value) => `${value}%pt`
                        }} 
                    />
                </ContentBox>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const risk = <GridColumnContent fullHeight>

    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Spend */}
        <GridItem>
            <IconColumn
                icon={<SpendIcon height='100%' width='100%' />}
                title={"Direct spend"}
                kpis={spend}
            />
        </GridItem>
        {/* Supplier performance */}
        <GridItem>
            <IconColumn
                icon={<PerformanceIcon height='100%' width='100%' />}
                title={"Supplier performance"}
                kpis={performance}
            />
        </GridItem>
        {/* Supplier risk */}
        <GridItem>
            <IconColumn
                icon={<PerformanceIcon height='100%' width='100%' />}
                title={"Supplier risk"}
                kpis={risk}
            />
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

const contractualSpend = (data) => {
    return data.isContractSpend / (data.isContractSpend + data.isMaverickSpend)
}

export default IntelligentSupplySummary;