import { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import { useApi } from "../../util/useApiHook";
import { sum, mean } from "../../util/arrayOperations";
import { Box, CircularProgress } from "@mui/material";
import NoDataMessage from "../../components/NoDataMessage";
import { filterInfo, filterFunc, aggregateData, aggregateCategorizedData } from "../../util/dataOperations";
import { fetchFromApi } from "../../util/fetchFromApi";
import { useQuery } from "react-query";
import LoadingPage from "../LoadingPage";

const filterSkeleton = JSON.stringify([
    {
        type: "originID",
        label: "originName",
        name: "Origin",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "destID",
        label: "destName",
        name: "Destination",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "whID",
        label: "whName",
        name: "Warehouse",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    }
])

function DynamicFulfillmentLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Get all needed data for this department
    const {gameId} = useParams()

    // Get info on types
    const {data: originInfoPreFilter} = useQuery(`/info/origins/${gameId}`, fetchFromApi)
    const {data: destinationInfo} = useQuery(`/info/destinations/${gameId}`, fetchFromApi)
    const {data: warehouseInfo} = useQuery(`/info/warehouses/${gameId}`, fetchFromApi)
    const [originInfo, setOriginInfo] = useState({})
    useEffect(() => {
        setOriginInfo(removeSuppliers(originInfoPreFilter))
    }, [originInfoPreFilter])

    // Get transactional data
    const {data: dataMoveSummary} = useQuery(`/dynfulfillment/move/${gameId}/summary`, fetchFromApi)
    const {data: dataMoveByOrigin} = useQuery(`/dynfulfillment/move/${gameId}/summary/originID`, fetchFromApi)
    const {data: dataMoveByDestination} = useQuery(`/dynfulfillment/move/${gameId}/summary/destID`, fetchFromApi)
    const {data: dataWarehouseSummary} = useQuery(`/dynfulfillment/warehouses/${gameId}/summary`, fetchFromApi)
    const {data: dataWarehouseByWarehouse} = useQuery(`/dynfulfillment/warehouses/${gameId}/summary/whID`, fetchFromApi)
    const {data: dataWarehouseByRegion} = useQuery(`/dynfulfillment/warehouses/${gameId}/summary/whRegion`, fetchFromApi)

    let initialYear = null
    let latestYear = null
    let latestQuarter = null

    // Handle incoming data
    if (!isLoading) {
        initialYear = dataMoveSummary[0].gameYear
        latestYear = dataMoveSummary.at(-1).gameYear
        latestQuarter = (dataMoveSummary.at(-1).gameRound - 1) % 4 + 1
    }

    // Set correct paths to subpages
    const tabs = [
        {link: `/${gameId}/dynamicfulfillment/summary`, name: "Summary"},
        {link: `/${gameId}/dynamicfulfillment/fulfillment`, name: "Fulfillment"},
        {link: `/${gameId}/dynamicfulfillment/serviceloss`, name: "Service loss"},
        {link: `/${gameId}/dynamicfulfillment/warehouses`, name: "Warehouses"},
        {link: `/${gameId}/dynamicfulfillment/dynamicmap`, name: "Dynamic map"},
    ]

    const [currYear, setCurrYear] = useState(latestYear);
    
    // Filters for synchronized planning
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }

    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }
    
    const initialFilters = {
        originID: 'ALL', 
        destID: 'ALL', 
        whID: 'ALL'
    }

    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredDataMoveSummary, setFilteredDataMoveSummary] = useState([])
    const [filteredDataMoveByOrigin, setFilteredDataMoveByOrigin] = useState({})
    const [filteredDataMoveByDestination, setFilteredDataMoveByDestination] = useState({})
    const [filteredDataWarehouseSummary, setFilteredDataWarehouseSummary] = useState([])
    const [filteredDataWarehouseByWarehouse, setFilteredDataWarehouseByWarehouse] = useState({})
    const [filteredDataWarehouseByRegion, setFilteredDataWarehouseByRegion] = useState({})
    
    useEffect(() => {
        if (isLoading) return

        let newFilters = JSON.parse(filterSkeleton)

        Object.values(originInfo).forEach( origin => {
            newFilters[0].options.push({value: origin[newFilters[0].type], label: origin[newFilters[0].label]})
        })

        Object.values(destinationInfo).forEach( destination => {
            newFilters[1].options.push({value: destination[newFilters[1].type], label: destination[newFilters[1].label]})
        })

        Object.values(warehouseInfo).forEach( warehouse => {
            newFilters[2].options.push({value: warehouse[newFilters[2].type], label: warehouse[newFilters[2].label]})
        })

        setFilters(newFilters)
    }, [isLoading, originInfo, destinationInfo, warehouseInfo])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return

        setCurrYear(latestYear)
        setFilteredDataMoveSummary(filterDataMove(dataMoveSummary, currFilters))
        setFilteredDataMoveByOrigin(filterCategorizedDataMove(dataMoveByOrigin, currFilters))
        setFilteredDataMoveByDestination(filterCategorizedDataMove(dataMoveByDestination, currFilters))
        setFilteredDataWarehouseSummary(filterDataWarehouses(dataWarehouseSummary, currFilters))
        setFilteredDataWarehouseByWarehouse(filterCategorizedDataWarehouses(dataWarehouseByWarehouse, currFilters))
        setFilteredDataWarehouseByRegion(filterCategorizedDataWarehouses(dataWarehouseByRegion, currFilters))
    }, [isLoading, latestYear, dataMoveSummary, dataMoveByOrigin, dataMoveByDestination, dataWarehouseSummary, dataWarehouseByWarehouse, dataWarehouseByRegion, currFilters])

    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
        }
    };
    
    const filterReset = (event) => {
        console.log('Filters have been reset')
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"dynamicfulfillment"} departmentName={"Dynamic Fulfillment"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataMoveSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            typeData: {
                                origins: filterInfo(originInfo, currFilters),
                                destinations: filterInfo(destinationInfo, currFilters),
                                warehouses: filterInfo(warehouseInfo, currFilters)
                            },
                            filteredData: {
                                moveSummary: filteredDataMoveSummary, 
                                moveByOrigin: filteredDataMoveByOrigin, 
                                moveByDestination: filteredDataMoveByDestination, 
                                warehouseSummary: filteredDataWarehouseSummary,
                                warehouseByWarehouse: filteredDataWarehouseByWarehouse,
                                warehouseByRegion: filteredDataWarehouseByRegion
                            }, 
                            aggregatedData: {
                                moveSummary: aggregateData(filteredDataMoveSummary, aggFunctionsMove), 
                                moveByOrigin: aggregateCategorizedData(filteredDataMoveByOrigin, aggFunctionsMove), 
                                moveByDestination: aggregateCategorizedData(filteredDataMoveByDestination, aggFunctionsMove), 
                                warehouseSummary: aggregateData(filteredDataWarehouseSummary, aggFunctionsWarehouses),
                                warehouseByWarehouse: aggregateCategorizedData(filteredDataWarehouseByWarehouse, aggFunctionsWarehouses),
                                warehouseByRegion: aggregateCategorizedData(filteredDataWarehouseByRegion, aggFunctionsWarehouses)
                            }
                        }} />
                    :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const removeSuppliers = (originInfo) => {
    let dataToReturn = {}
    for (const [key, value] of Object.entries(originInfo)) {
        if (value.originType === "SUPPLIER") continue;

        dataToReturn[key] = value
    }
    return dataToReturn
}

const filterDataMove = (data, filters) => {
    if (!Array.isArray(data)) return []

    const moveFilters = { originID: filters.originID, destID: filters.destID }

    let filteredData = data.filter((item) => filterFunc(item, moveFilters) && item.originType !== "SUPPLIER" )

    return filteredData
}

const filterDataWarehouses = (data, filters) => {
    if (!Array.isArray(data)) return []

    const warehouseFilters = { whID: filters.whID }

    let filteredData = data.filter((item) => filterFunc(item, warehouseFilters))

    return filteredData
}

const filterCategorizedDataMove = (data, filters) => {
    let output = {}
    for (const [key, value] of Object.entries(data)) {
        let filteredData = filterDataMove(value, filters)
        if (filteredData.length > 0) {
            output[key] = filteredData
        }
    }

    return output
}

const filterCategorizedDataWarehouses = (data, filters) => {
    let output = {}
    for (const [key, value] of Object.entries(data)) {
        let filteredData = filterDataWarehouses(value, filters)
        if (filteredData.length > 0) {
            output[key] = filteredData
        }
    }

    return output
}

const aggFunctionsMove = {
    'transLaneType': 'first',
    'finishedGoodName_toFGWH': 'first',
    'onTime': 'mean',
    'inFull': 'mean',
    'OTIF': 'mean',
    'leadTime': 'mean',
    'costsTransport': 'sum',
}

const aggFunctionsWarehouses = {
    'whType': 'first',
    'whCosts': 'sum',
    'whUtilization': 'mean',
}

export default DynamicFulfillmentLayout;