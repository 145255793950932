import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, Cell, LabelList, Line, ReferenceLine, XAxis, YAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getLastYear, getQuarters } from "../../util/dataOperations";
import { renderDiamond, renderVerticalDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function DynamicFulfillmentWarehouses() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.warehouseSummary.slice(Math.max(aggregatedData.warehouseSummary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const regions = Object.keys(aggregatedData.warehouseByRegion)
    const warehouses = Object.keys(aggregatedData.warehouseByWarehouse)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const costs = <GridColumnContent>
        {/* Warehouse costs by type */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Warehouse cost by type <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-WarehouseCostsByType`} width={'100%'} height={'80%'} data={getCostsByType(last8Quarters(aggregatedData.warehouseByWarehouse), currQuarter)} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" type="category" interval={0} />
                    <Bar name={`Raw material warehouses`} dataKey={`rawMaterialWHCosts`} stackId={0} fill={colors[2].color} animationDuration={1000}>
                        <LabelList dataKey={`rawMaterialWHPercentage`} position="middle" fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={12} />
                    </Bar>
                    <Bar name={`Finished good warehouses`} dataKey={`finishedGoodWHCosts`} stackId={0} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`finishedGoodWHPercentage`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={12} />
                        <LabelList dataKey={`total`} position="top" fill={theme.palette.text.secondary} formatter={(value) => `${(value/1000000).toFixed(0)}`} fontSize={12} />
                    </Bar>
                    <Line name={`Previous year`} dataKey={`prevYear`} stroke={theme.palette.grey[500]} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Warehouse costs by region */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Regional view <i>(million &euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-WarehouseCostsByRegion`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.warehouseByRegion), 'whCosts', regions, quarters)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`costregion-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${(value/1000000).toFixed(1)}`} fontSize={12} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const warehouseUtilizationData = getLastYear(fromLastYearData, 'whUtilization', currQuarter, currYear, 0, 100)
    const utilization = <GridColumnContent>
        {/* Warehouse utilization */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Warehouse utilization evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-WarehoudeUtilization`} width={'100%'} height={'80%'} data={warehouseUtilizationData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={warehouseUtilizationData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={warehouseUtilizationData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Warehouse utilization by region */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Warehouse utilization by region <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-WarehouseUtilizationByRegion`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.warehouseByRegion), 'whUtilization', regions, quarters, 0, 100)}>
                <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`utilizationregion-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const breakdownData = getBreakdownData(aggregatedData.warehouseByWarehouse, warehouses)
    const breakdown = <GridColumnContent>
        {/* Detailed breakdown */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'5%'} sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                    <Typography variant="body2" align="left" width='40%' textAlign='right'>
                        Costs
                    </Typography>
                    <Typography variant="body2" align="left" width='40%' textAlign='left'>
                        Utilization
                    </Typography>
                </Box>
                <Box height='100%' display={'flex'} flexDirection={'row'}>
                    <SmallGraph key={`${key}-Breakdown`} width={'59%'} height={'95%'} layout='vertical' data={breakdownData} noYAxis>
                        <YAxis allowDataOverflow dataKey="name" yAxisId={'axis0'} orientation='right' axisLine={false} tickLine={false} type="category" interval={0} />
                        <YAxis hide allowDataOverflow dataKey="name" yAxisId={'axis1'} type="category" interval={0} />
                        <XAxis hide allowDataOverflow domain={[0, dataMax => (dataMax * 1.2)]} type="number" reversed />
                        {/* Bar itself */}
                        <Bar name={`Last quarter`} dataKey={`whCosts`} yAxisId={'axis0'} maxBarSize={30} fill={colors[3].color} animationDuration={1000} legendType='none'>
                            { breakdownData.map((entry, index) => (
                                <Cell key={`costcell-${index}`} fill={entry.whType === 'Raw material warehouse' ? colors[2].color : colors[3].color} />
                            ))}
                            <LabelList dataKey={`whCosts`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value/1000000).toFixed(1)}M`} fontSize={12} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`Last year`} 
                            dataKey={`prevYearCosts`} 
                            yAxisId={'axis1'}
                            maxBarSize={30}
                            animationDuration={1000} 
                            legendType='none'
                            shape={(props) => renderVerticalDiamond(props, theme)}
                        />
                    </SmallGraph>
                    <SmallGraph key={`${key}-Breakdown2`} width={'41%'} height={'95%'} layout='vertical' data={breakdownData} noYAxis>
                        <YAxis hide allowDataOverflow dataKey="name" yAxisId={'axis0'} type="category" interval={0} />
                        <YAxis hide allowDataOverflow dataKey="name" yAxisId={'axis1'} type="category" interval={0} />
                        <XAxis hide allowDataOverflow domain={[0, dataMax => (dataMax * 1.2)]} type="number" />
                        {/* Bar itself */}
                        <Bar name={`Last quarter`} dataKey={`whUtilization`} yAxisId={'axis0'} maxBarSize={30} fill={colors[3].color} animationDuration={1000} legendType='none'>
                            { breakdownData.map((entry, index) => (
                                <Cell key={`costcell-${index}`} fill={entry.whType === 'Raw material warehouse' ? colors[2].color : colors[3].color} />
                            ))}
                            <LabelList dataKey={`whUtilization`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}%`} fontSize={12} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`Last year`} 
                            dataKey={`prevYearUtilization`} 
                            yAxisId={'axis1'}
                            maxBarSize={30}
                            animationDuration={1000} 
                            legendType='none'
                            shape={(props) => renderVerticalDiamond(props, theme)}
                        />
                    </SmallGraph>
                </Box>
                <SmallGraph key={`${key}-BreakdownLegend`} width={'100%'} height={'5%'} layout='vertical' data={breakdownData} noYAxis>
                    <Line name="Raw material warehouse" dataKey={'none'} stroke={colors[2].color} legendType={'rect'} />
                    <Line name="Finished good warehouse" dataKey={'none'} stroke={colors[3].color} legendType={'rect'} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Transport costs */}
        <GridItem>
            <CardTitle title="Transport costs" />
            {costs}
        </GridItem>
        {/* Outbound lead time */}
        <GridItem>
            <CardTitle title="Warehouse utilization" />
            {utilization}
        </GridItem>
        {/* OTIF */}
        <GridItem>
            <CardTitle title="Detailed breakdown" />
            {breakdown}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

const getCostsByType = (data, currQuarter, offset=0, scalar=1) => {   
    const dataToReturn = [
        {
            name: 'Q1',
        },
        {
            name: 'Q2',
        },
        {
            name: 'Q3',
        },
        {
            name: 'Q4',
        },
    ]

    if (Object.keys(data).length > 0) {
        let rawMaterialWarehouses = Object.entries(data).filter(([, value]) => value[0].whType === 'Raw material warehouse')
        let finishedGoodWarehouses = Object.entries(data).filter(([, value]) => value[0].whType === 'Finished good warehouse')
        let allWarehouses = Object.entries(data)

        for (let i = 0; i < 4; i++) {
            if (currQuarter > i) {
                dataToReturn[i].rawMaterialWHCosts = 0
                rawMaterialWarehouses.forEach(([, value]) => {
                    dataToReturn[i].rawMaterialWHCosts += scalar * value[4+i].whCosts + offset
                })
                dataToReturn[i].finishedGoodWHCosts = 0
                finishedGoodWarehouses.forEach(([, value]) => {
                    dataToReturn[i].finishedGoodWHCosts += scalar * value[4+i].whCosts + offset
                })
                dataToReturn[i].total = 0
                allWarehouses.forEach(([, value]) => {
                    dataToReturn[i].total += scalar * value[4+i].whCosts + offset
                })
                dataToReturn[i].rawMaterialWHPercentage = dataToReturn[i].rawMaterialWHCosts / dataToReturn[i].total
                dataToReturn[i].finishedGoodWHPercentage = dataToReturn[i].finishedGoodWHCosts / dataToReturn[i].total
                dataToReturn[i].prevYear = 0
            }
            allWarehouses.forEach(([key, value]) => {
                dataToReturn[i].prevYear += value[i].whCosts
            })
        }
    }
    
    return dataToReturn
}

const getBreakdownData = (data, warehouses) => {
    const dataToReturn = []
    warehouses.forEach( (warehouse, index) => {
        dataToReturn[index] = { 
            name: warehouse,
            whType: data[warehouse].at(-1).whType,
            whCosts: data[warehouse].at(-1).whCosts,
            whUtilization: data[warehouse].at(-1).whUtilization,
            prevYearCosts: data[warehouse].at(-5).whCosts,
            prevYearUtilization: data[warehouse].at(-5).whUtilization,
        }
    })
 
    // Return the dataToReturn array without empty values and sorted on OTIF value
    return dataToReturn.sort( (a, b) => b.whCosts - a.whCosts)
}

export default DynamicFulfillmentWarehouses;