import { Box, Typography, useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import SustainabilityTree from "../../components/graphs/SustainabilityTree";
import ProgressArc from "../../components/graphs/ProgressArc";
import MainContentBox from "../../components/StyledElements/MainContentBox";

function SustainabilitySummary() {
    
    const { aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const targetTracker = <GridColumnContent>
        {/* Sustainability target tracker */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'10%'}>
                    <Typography variant="body2" align="left">
                        2030 and 2050 goals
                    </Typography>
                </Box>
                <SustainabilityTree height={'100%'} width={'100%'} data={getTreeData(aggregatedData.summary)} />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const carbon = <GridColumnContent halfHeight>
        {/* Carbon footprint */}
        <Box height={'100%'}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        CO<sub>2</sub>(kg) per unit<br/>
                        Current quarter vs. last quarter
                    </Typography>
                </Box>
                <ProgressArc height={'100%'} width={'100%'} data={getArcData(aggregatedData.summary, 'co2')} />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const waste = <GridColumnContent halfHeight>
        {/* Recycled waste */}
        <Box height={'100%'}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Waste recycled <i>(%)</i><br/>
                        Current quarter vs. last quarter
                    </Typography>
                </Box>
                <ProgressArc height={'100%'} width={'100%'} data={getArcData(aggregatedData.summary, 'waste')} />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const social = <GridColumnContent halfHeight>
        {/* Social impact */}
        <Box height={'100%'}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Score on 1 to 10 (=good) scale<br/>
                        Current quarter vs. last quarter
                    </Typography>
                </Box>
                <ProgressArc height={'100%'} width={'100%'} data={getArcData(aggregatedData.summary, 'social')} />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const water = <GridColumnContent halfHeight>
        {/* Clean water usage */}
        <Box height={'100%'}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Clean water usage <i>(%)</i><br/>
                        Current quarter vs. last quarter
                    </Typography>
                </Box>
                <ProgressArc height={'100%'} width={'100%'} data={getArcData(aggregatedData.summary, 'water')} />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Sustainability target tracker */}
        <GridItem>
            <CardTitle title="Sustainability target tracker" />
            {targetTracker}
        </GridItem>
        {/* Carbon and Recycled waste */}
        <GridItem>
            <Box height='50%'>
                <CardTitle title="Carbon footprint reduction" halfHeight />
                {carbon}
            </Box>
            <Box height='50%'>
                <CardTitle title="Recycled waste" halfHeight />
                {waste}
            </Box>
        </GridItem>
        {/* Social and Clean water */}
        <GridItem>
            <Box height='50%'>
                <CardTitle title="Social impact" halfHeight />
                {social}
            </Box>
            <Box height='50%'>
                <CardTitle title="Clean water usage" halfHeight />
                {water}
            </Box>
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

const getTreeData = (data) => {
    if (data.length <= 0) return null
    
    const dataToReturn = {
        co2: [0, 0],
        waste: [0, 0],
        social: [0, 0],
        water: [0, 0]
    }

    dataToReturn.co2 = [data.at(-1).CO2footprintTarget2030, data.at(-1).CO2footprintTarget2050]
    dataToReturn.waste = [data.at(-1).recycledWasteTarget2030, data.at(-1).recycledWasteTarget2050]
    dataToReturn.social = [data.at(-1).socialSustainabilityTarget2030, data.at(-1).socialSustainabilityTarget2050]
    dataToReturn.water = [data.at(-1).cleanWaterTarget2030, data.at(-1).cleanWaterTarget2050]    

    return dataToReturn
}

const getArcData = (data, type) => {
    if (data.length <= 0) return null
    
    const dataToReturn = {
        value: 0,
        target30: 0,
        target50: 0
    }

    switch (type) {
        case 'co2': 
            dataToReturn.value = data.at(-1).co2unit_total
            dataToReturn.percentage = 1 - (data.at(-1).co2unit_total / (data.at(0).co2unit_total * 1.2))
            dataToReturn.target30 = [data.at(-1).co2unit_target2030, data.at(-1).CO2footprintTarget2030]
            dataToReturn.target50 = [data.at(-1).co2unit_target2050, data.at(-1).CO2footprintTarget2050]
            break;
        case 'waste': 
            dataToReturn.value = data.at(-1).recycledWaste
            dataToReturn.percentage = data.at(-1).recycledWaste
            dataToReturn.target30 = [data.at(-1).recycledWaste_target2030, data.at(-1).recycledWasteTarget2030]
            dataToReturn.target50 = [data.at(-1).recycledWaste_target2050, data.at(-1).recycledWasteTarget2050]
            break;
        case 'social': 
            dataToReturn.value = data.at(-1).socialSustainability
            dataToReturn.percentage = data.at(-1).socialSustainability
            dataToReturn.target30 = [data.at(-1).socialSustainability_target2030, data.at(-1).socialSustainabilityTarget2030]
            dataToReturn.target50 = [data.at(-1).socialSustainability_target2050, data.at(-1).socialSustainabilityTarget2050]
            break;
        case 'water': 
            dataToReturn.value = data.at(-1).waterUsage_total
            dataToReturn.percentage = data.at(-1).waterUsage_total
            dataToReturn.target30 = [data.at(-1).waterUsage_target2030, data.at(-1).cleanWaterTarget2030]
            dataToReturn.target50 = [data.at(-1).waterUsage_target2050, data.at(-1).cleanWaterTarget2050]
            break;
        default: break;
    }

    return dataToReturn
}


export default SustainabilitySummary;