import { Box, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Outlet, useOutletContext, useParams } from "react-router";
import Filters from "../../components/Filters";
import Header from "../../components/Header";
import NoDataMessage from "../../components/NoDataMessage";
import { mean, sum } from "../../util/arrayOperations";
import { aggregateCategorizedData, aggregateData, filterCategorizedData, filterData } from "../../util/dataOperations";
import { fetchFromApi } from "../../util/fetchFromApi";
import { useApi } from "../../util/useApiHook";
import LoadingPage from "../LoadingPage";

const filterSkeleton = JSON.stringify([
    {
        type: "finishedGoodName",
        label: "finishedGoodName",
        name: "Product Group",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "storeType",
        label: "storeType",
        name: "Store Type",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
    {
        type: "region",
        label: "region",
        name: "Region",
        options: [
            {value: "ALL", label: "All"}, 
        ]
    },
])

function ExecutiveViewLayout() {
    // Get context
    const { isLoading } = useOutletContext()

    // Get all needed data for this department
    const {gameId} = useParams()

    // Get info on types
    const {data: regionInfo} = useQuery(`/info/regions/${gameId}`, fetchFromApi)
    const {data: storeTypeInfo} = useQuery(`/info/storetypes/${gameId}`, fetchFromApi)
    const {data: productInfo} = useQuery(`/info/products/${gameId}`, fetchFromApi)
    // Get transactional data
    const {data: dataSummary} = useQuery(`/execoverview/${gameId}/summary`, fetchFromApi)
    const {data: dataByRegion} = useQuery(`/execoverview/${gameId}/summary/region`, fetchFromApi)
    const {data: dataByStoreType} = useQuery(`/execoverview/${gameId}/summary/storeType`, fetchFromApi)
    const {data: dataByProduct} = useQuery(`/execoverview/${gameId}/summary/finishedGoodName`, fetchFromApi)

    let initialYear = null
    let latestYear = null
    let latestQuarter = null

    // Handle incoming data
    if (!isLoading) {
        initialYear = dataSummary[0].gameYear
        latestYear = dataSummary.at(-1).gameYear
        latestQuarter = (dataSummary.at(-1).gameRound - 1) % 4 + 1
    }

    // Set correct paths to subpages
    const tabs = [
        {link: `/${gameId}/executiveview/summary`, name: "Summary"},
        {link: `/${gameId}/executiveview/waterfall`, name: "Waterfall"},
    ]

    const [currYear, setCurrYear] = useState(latestYear);

    // Filters for executive overview
    const timeFilter = {
        type: "time",
        name: "Year",
        options: []
    }

    for (let i = initialYear; i <= latestYear; i++) {
        timeFilter.options.push({value: i, label: i})
    }

    const initialFilters = {
        finishedGoodName: 'ALL',
        storeType: 'ALL', 
        region: 'ALL',
    }

    const [filters, setFilters] = useState(JSON.parse(filterSkeleton))
    const [currFilters, setCurrFilters] = useState(initialFilters)
    const [filteredDataSummary, setFilteredDataSummary] = useState([])
    const [filteredDataByRegion, setFilteredDataByRegion] = useState({})
    const [filteredDataByStoreType, setFilteredDataByStoreType] = useState({})
    const [filteredDataByProduct, setFilteredDataByProduct] = useState({})
    
    useEffect(() => {
        if (isLoading) return

        let newFilters = JSON.parse(filterSkeleton)

        Object.values(productInfo).forEach((product) => {
            newFilters[0].options.push({value: product[newFilters[0].type], label: product[newFilters[0].label]})
        })

        Object.values(storeTypeInfo).forEach((storeType) => {
            newFilters[1].options.push({value: storeType[newFilters[1].type], label: storeType[newFilters[1].label]})
        })

        Object.values(regionInfo).forEach( region => {
            newFilters[2].options.push({value: region[newFilters[2].type], label: region[newFilters[2].label]})
        })

        setFilters(newFilters)
    }, [currFilters, productInfo, storeTypeInfo, regionInfo])
    
    // Update filtered data on data change
    useEffect(() => {
        if (isLoading) return

        setCurrYear(latestYear)
        setFilteredDataSummary(filterData(dataSummary, currFilters))
        setFilteredDataByRegion(filterCategorizedData(dataByRegion, currFilters))
        setFilteredDataByStoreType(filterCategorizedData(dataByStoreType, currFilters))
        setFilteredDataByProduct(filterCategorizedData(dataByProduct, currFilters))
    }, [latestYear, dataSummary, dataByRegion, dataByStoreType, dataByProduct, currFilters])

    const handleFilterChange = (type, event) => {
        if (type === 'time') {
            setCurrYear(event.target.value)
        } else {
            setCurrFilters({...currFilters, [type]: event.target.value})
        }
    };
    
    const filterReset = () => {
        setCurrYear(latestYear)
        setCurrFilters(initialFilters)
    }

    return <>
        <Header department={"executiveview"} departmentName={"Executive Overview"} tabs={tabs} />
        { isLoading ? 
            <LoadingPage />
        :
            <>
                <Filters timeFilter={timeFilter} timeValue={currYear} filters={filters} values={currFilters} handleChange={handleFilterChange} filterReset={filterReset} />
                <Box id="ContentBox" sx={(theme) => ({height: `calc(100% - ${theme.spacing(theme.sizes.appBarHeight + theme.sizes.filterHeight)})`})}>
                    { filteredDataSummary.length > 0 ? 
                        <Outlet context={{ 
                            currFilters,
                            latestYear,
                            currYear, 
                            currQuarter: currYear === latestYear ? latestQuarter : 4, 
                            filteredData: {summary: filteredDataSummary, byRegion: filteredDataByRegion, byStoreType: filteredDataByStoreType, byProduct: filteredDataByProduct}, 
                            aggregatedData: {
                                summary: aggregateData(filteredDataSummary, aggFunctions), 
                                byRegion: aggregateCategorizedData(filteredDataByRegion, aggFunctions), 
                                byStoreType: aggregateCategorizedData(filteredDataByStoreType, aggFunctions),
                                byProduct: aggregateCategorizedData(filteredDataByProduct, aggFunctions)
                            }
                        }} />
                    :
                        <NoDataMessage />
                    }
                </Box>
            </>
        }
    </>
}

const aggFunctions = {
    'costsMarketingSalesOtherOverhead': 'sum',
    'discountRate': 'mean',
    'forecastAccuracy': 'mean',
    'forecastBias': 'mean',
    'returnRate': 'mean',
    'revenueSales': 'sum',
    'serviceLevelActual': 'mean',
    'serviceLevelTarget': 'first',
    'targetRevenueSales': 'sum',
    'unitsSold': 'sum',
    'workingCapital': 'sum',
    'fgUnitsSoldShare': 'mean',
    'salesInRegionShare': 'mean',
    'costsManufacturingTotal': 'sum',
    'oeeKPI': 'mean',
    'OTIF': 'mean',
    'inFull': 'mean',
    'onTime': 'mean',
    'costsTransport': 'sum',
    'costsRmTotalSpend': 'sum',
    'rmDOH': 'mean',
    'supplierReliabilityOTIF': 'mean',
    'whCosts': 'sum',
    'co2unit_target2030': 'first',
    'co2unit_total': 'sum',
    'socialSustainability': 'mean',
    'socialSustainability_target2030': 'first',
    'totalCosts': 'sum',
    'grossSales': 'sum',
    'discountCosts': 'sum',
    'returnCosts': 'sum',
    'netProfit': 'sum',
    'netMargin': 'mean',
    'costToServe': 'mean'
}

export default ExecutiveViewLayout;