import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, ReferenceLine, XAxis } from "recharts";
import ServiceLevelDonutGraphMultiLayer from "../../components/graphs/ServiceLevelDonutGraphMultiLayer";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getQuarters } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";

function SynchronizedPlanningSupply() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const regions = Object.keys(aggregatedData.byRegion)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const service = <GridColumnContent>
        {/* Service Level */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Service level by region, country <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-ServiceLevel`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byRegion), 'serviceLevelActual', regions, quarters)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`serviceLevel-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar 
                            name={`${quarter}`} 
                            dataKey={`${quarter}`} 
                            xAxisId={'axis0'} 
                            fill={colors[index].color} 
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Service level by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Service level by product <i>(%)</i>
                    </Typography>
                </Box>
                {/* Radial plot */}
                <ServiceLevelDonutGraphMultiLayer height={'85%'} arcWidth={0.15} arcPadding={0.015}
                    data={getServiceLevelsByProduct(last8Quarters(aggregatedData.byProduct), products)} 
                    currQuarter={currQuarter}
                    currYear={currYear}
                />
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const planning = <GridColumnContent>
        {/* Planning adherence by region */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Planning adherence by region <i>(%)</i> 
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-PlanningAdherenceByRegion`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byRegion), 'planningAdherence', regions, quarters)}>
                <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`planningAdherenceRegion-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar  
                            name={`${quarter}`} 
                            dataKey={`${quarter}`} 
                            xAxisId={'axis0'} 
                            fill={colors[index].color} 
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Planning adherence by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Planning adherence by product <i>(%)</i> 
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-PlanningAdherenceByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'planningAdherence', products, quarters)}>
                <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`planningAdherenceProduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar  
                            name={`${quarter}`} 
                            dataKey={`${quarter}`} 
                            xAxisId={'axis0'} 
                            fill={colors[index].color} 
                            animationDuration={1000}
                        >
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const returns = <GridColumnContent>
        {/* Returns by region */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Returns by region <i>(%)</i> 
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-ReturnsByRegion`} width={'100%'} height={'80%'} data={getReturnsByRegion(last8Quarters(aggregatedData.byRegion), regions, quarters).splice(4,4)}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    {regions.map( (region, index) => 
                        <Line key={`returns-${region}`} name={`${region}`} dataKey={`${region}`} stroke={colors[3-index].color} animationDuration={1000}>
                            <LabelList key={`returns-${region}`} dataKey={`${region}`} position="top" fill={colors[3-index].color} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                        </Line>
                    )}
                    <Line name={`Average`} dataKey={`average`} stroke={theme.palette.grey[700]} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Returns by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Returns by product <i>(%)</i> 
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-ReturnsByProduct`} width={'100%'} height={'80%'} data={getReturnsByProduct(last8Quarters(aggregatedData.byProduct), products, quarters).splice(4,4)}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    {products.map( (product, index) => 
                        <Line key={`returns-${product}`} name={`${product}`} dataKey={`${product}`} stroke={colors[3-index].color} animationDuration={1000}>
                            <LabelList key={`returns-${product}`} dataKey={`${product}`} position="top" fill={colors[3-index].color} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={12} />
                        </Line>
                    )}
                    <Line name={`Average`} dataKey={`average`} stroke={theme.palette.grey[700]} animationDuration={1000} />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Service Level */}
        <GridItem>
            <CardTitle title="Service level" />
            {service}
        </GridItem>
        {/* Planning Adherence */}
        <GridItem>
            <CardTitle title="Planning adherence" />
            {planning}
        </GridItem>
        {/* Returns */}
        <GridItem>
            <CardTitle title="Returns" />
            {returns}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

const getReturnsByRegion = (data, regions, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    quarters.forEach( (quarter, index ) => {
        dataToReturn[index] = { name: quarter }
    })

    if (Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            let total = 0;
            regions.forEach( (region, index2) => {
                dataToReturn[index1][region] = scalar * data[region][index1].returnRate + offset;
                total += scalar * data[region][index1].returnRate + offset;
            })
            dataToReturn[index1].average = total/regions.length;
        })
    }

    return dataToReturn
}

const getReturnsByProduct = (data, products, quarters, offset=0, scalar=1) => {   
    const dataToReturn = []
    quarters.forEach( (quarter, index ) => {
        dataToReturn[index] = { name: quarter }
    })

    if (Object.keys(data).length > 0) {
        quarters.forEach( (quarter, index1) => {
            let total = 0;
            products.forEach( (product, index2) => {
                dataToReturn[index1][product] = scalar * data[product][index1].returnRate + offset;
                total += scalar * data[product][index1].returnRate + offset;
            })
            dataToReturn[index1].average = total/products.length;
        })
    }

    return dataToReturn
}

const getServiceLevelsByProduct = (data, products, offset=0, scalar=1) => {
    const dataToReturn = {
        products: [],
        average: {}
    }
    products.forEach( (product, index ) => {
        dataToReturn.products[index] = { name: product }
    })
    dataToReturn.average = { name: 'Average' }

    if (Object.keys(data).length > 0) {
        let total = 0
        products.forEach( (product, index) => {
            dataToReturn.products[index].current = scalar * data[product][data[product].length-1].serviceLevelActual + offset;
            dataToReturn.products[index].prevQuarter = scalar * data[product][data[product].length-2].serviceLevelActual + offset;
            dataToReturn.products[index].prevYear = scalar * data[product][data[product].length-5].serviceLevelActual + offset;
            total += scalar * data[product][data[product].length-1].serviceLevelActual + offset;
        })
        dataToReturn.average = total/products.length;
    }

    return dataToReturn
}

export default SynchronizedPlanningSupply;