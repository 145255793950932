export const renderDiamond = (barProps, theme) => {
    const { x, y, width } = barProps
    const cx = x + width/2
    const cy = y
    return <path style={{zIndex: '1'}} d={`M ${cx} ${cy-width/2.5}
                    L ${cx-width/2.5} ${cy}
                    L ${cx} ${cy+width/2.5}
                    L ${cx+width/2.5} ${cy}
                    L ${cx} ${cy-width/2.5}`} fill={theme.palette.diamond.color} fillOpacity={theme.palette.diamond.opacity} />
}

export const renderVerticalDiamond = (barProps, theme) => {
    let { x, y, width, height } = barProps
    const cx = x + width
    const cy = y + height/2
    return <path style={{zIndex: '1'}} d={`M ${cx} ${cy-height/2.5}
                    L ${cx-height/2.5} ${cy}
                    L ${cx} ${cy+height/2.5}
                    L ${cx+height/2.5} ${cy}
                    L ${cx} ${cy-height/2.5}`} fill={theme.palette.diamond.color} fillOpacity={theme.palette.diamond.opacity} />
}