import { Box, CircularProgress, useTheme } from "@mui/material";
import { ReactComponent as CenterGraphic } from '../../assets/icons/IconExecCenterGraphic.svg';
import { ReactComponent as IconBills } from '../../assets/icons/IconExecBills.svg';
import { ReactComponent as IconBoxOpen } from '../../assets/icons/IconExecBoxOpen.svg';
import { ReactComponent as IconGears } from '../../assets/icons/IconExecGears.svg';
import { ReactComponent as IconGraph } from '../../assets/icons/IconExecGraph.svg';
import { ReactComponent as IconLeaf } from '../../assets/icons/IconExecLeaf.svg';
import { ReactComponent as IconMoneyBag } from '../../assets/icons/IconExecMoneyBag.svg';
import { ReactComponent as IconMoneyGear } from '../../assets/icons/IconExecMoneyGear.svg';
import { ReactComponent as IconPie } from '../../assets/icons/IconExecPie.svg';
import { ReactComponent as IconStar } from '../../assets/icons/IconExecStar.svg';
import { ReactComponent as IconTruck } from '../../assets/icons/IconExecTruck.svg';
import { relativeDiff } from "../../util/mathOperations";
import { useOutletContext } from "react-router";
import { yearToDate } from "../../util/dataOperations";

// TODO fix visual bug with disappearing icons
function ExecutiveViewSummary(props) {
    
    // TODO get curr year and quarter
    const { height, width, currQuarter, currYear, aggregatedData } = useOutletContext();

    const theme = useTheme();    

    const svgWidth = 240
    const svgHeight = 130

    if (!aggregatedData) {
        return <CircularProgress />
    }

    const data = getVisualData(aggregatedData.summary, currQuarter)

    const kpi = (Icon, name, value, change, x, y) =>
        <>
            {Icon ? <Icon height='13' width='13' x={x} y={y-2.5} /> : null}
            <text 
                y={y - 4 * (name.split(';').length)} 
                fill={theme.palette.text.primary}
                fontSize={4}
                textAnchor='middle'
                >
                {name.split(';').map((part, index) => 
                    <tspan key={`${name}-${index}`} x={x+27} dy={4}>{part}</tspan>
                )}       
            </text>
            {change ? 
                <>
                    <text 
                        x={x+27} 
                        y={y+6} 
                        fill={theme.palette.text.primary}
                        fontSize={5}
                        fontWeight={700}
                        textAnchor='middle'
                    >
                        {value.format(value.value)}   
                    </text>
                    <text 
                        x={x+27} 
                        y={y+10.5} 
                        fill={change.value > 0 ? theme.palette.kpi.success : theme.palette.kpi.fail}
                        fontSize={3}
                        textAnchor='middle'
                    >
                        {change.format(`${change.value > 0 ? '+' : ''}${(change.value*100).toFixed(1)}`)}
                    </text>
                </>
            :         
                <text 
                    x={x+32} 
                    y={y+5} 
                    fill={theme.palette.text.secondary}
                    fontSize={3}
                    textAnchor='middle'
                >
                    No data with       
                    <tspan 
                        x={x+32} 
                        y={y+8} 
                        fill={theme.palette.text.secondary}
                        fontSize={3}
                        textAnchor='middle'
                    >current filters</tspan>
                </text>
            }
        </>

    return (<Box style={{ userSelect: "none" }} p={2} >
        <svg style={{
                position: 'relative', 
                top: '0', left: '0', 
                fontFamily: theme.typography.fontFamily, 
                fontSize: `calc(${theme.typography.body2.fontSize}/3)`
            }} 
            height={height} 
            width={width} 
            xmlns="http://www.w3.org/2000/svg" 
            preserveAspectRatio="xMidYMax meet"
            viewBox={`-1 -1 ${svgWidth+2} ${svgHeight+7}`} 
        >
            {/* Frame left */}
            <g>
                <path d={`
                    M ${svgWidth/2} ${svgHeight/2} 
                    L ${svgWidth * 29/64} 0 
                    L 0 0 
                    L 0 ${svgHeight} 
                    L ${svgWidth * 29/64} ${svgHeight} 
                    L ${svgWidth/2} ${svgHeight/2}
                    `} 
                    stroke="#86F200" 
                    strokeWidth="0.75" 
                    strokeLinejoin="round" />
                <text fill="#86F200" fontStyle="italic" fontWeight="700" fontSize="5" x={svgHeight/20} y={svgHeight/20}>FINANCIALS</text>
            </g>

            {/* Frame right */}
            <g>
                <path d={`
                    M ${svgWidth/2} ${svgHeight/2} 
                    L ${svgWidth * 35/64} 0 
                    L ${svgWidth} 0 
                    L ${svgWidth} ${svgHeight} 
                    L ${svgWidth * 35/64} ${svgHeight} 
                    L ${svgWidth/2} ${svgHeight/2}
                    `} 
                    stroke="#86F200" 
                    strokeWidth="0.75" 
                    strokeLinejoin="round" />
                <text fill="#86F200" fontStyle="italic" fontWeight="700" fontSize="5" x={svgWidth - svgHeight/20} y={svgHeight/20} textAnchor='end'>SUPPLY CHAIN PERFORMANCE</text>
            </g>

            {/* Center graphic */}
            <CenterGraphic height={svgHeight/2} width={svgHeight/2} x={svgWidth/2 - svgHeight/4} y={svgHeight/2 - svgHeight/4} />
        
            {/* Left side */}
            <g>
                {kpi(IconMoneyBag, 'Sales', 
                    {value: data.revenueSales.value, format: (value) => `\u20AC${(value/1000000000).toFixed(1)}B`}, 
                    {value: data.revenueSales.change, format: (value) => `${value}%`}, 
                    svgHeight*1/20, 20+(svgHeight-10)*0/3 )}
                {kpi(IconPie, 'Margin', 
                    {value: data.netMargin.value, format: (value) => `${(value*100).toFixed(1)}%`}, 
                    {value: data.netMargin.change, format: (value) => `${value}%pt`}, 
                    svgHeight*8/20, 20+(svgHeight-10)*0/3 )}
                {kpi(IconBills, 'Cost-to-serve', 
                    {value: data.costToServe.value, format: (value) => `\u20AC${(value).toFixed(2)}`}, 
                    {value: data.costToServe.change, format: (value) => `${value}%`}, 
                    svgHeight*1/20, 20+(svgHeight-10)*1/3 )}
                {kpi(IconMoneyGear, 'Working;capital', 
                    {value: data.workingCapital.value, format: (value) => `\u20AC${(value/1000000000).toFixed(1)}B`}, 
                    {value: data.workingCapital.change, format: (value) => `${value}%`}, 
                    svgHeight*8/20, 20+(svgHeight-10)*1/3 )}
                {kpi(IconLeaf, 'CO2 footprint;(unit)', 
                    {value: data.co2unit_total.value, format: (value) => `${(value).toFixed(0)}`}, 
                    {value: data.co2unit_total.change, format: (value) => `${value}%`}, 
                    svgHeight*1/20, 20+(svgHeight-10)*2/3 )}
                {kpi(IconStar, 'Social;impact', 
                    {value: data.socialSustainability.value, format: (value) => `${(value).toFixed(0)}`}, 
                    {value: data.socialSustainability.change, format: (value) => `${value}%`}, 
                    svgHeight*8/20, 20+(svgHeight-10)*2/3 )}
            </g>

            {/* Right side */}
            <g>
                {kpi(IconGraph, 'Forecast;accuracy', 
                    {value: data.forecastAccuracy.value, format: (value) => `${(value*100).toFixed(0)}%`}, 
                    {value: data.forecastAccuracy.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20, 20+(svgHeight-10)*0/4 )}
                {kpi(null, 'Bias', 
                    {value: data.forecastBias.value, format: (value) => `${(value*100).toFixed(1)}%`}, 
                    {value: data.forecastBias.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20+35, 20+(svgHeight-10)*0/4 )}
                {kpi(IconBoxOpen, 'Supplier;performance', 
                    {value: data.supplierReliabilityOTIF.value, format: (value) => `${(value*100).toFixed(0)}%`}, 
                    {value: data.supplierReliabilityOTIF.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20, 20+(svgHeight-10)*1/4 )}
                {kpi(null, 'Supplier;risk', 
                    {value: 0, format: (value) => `${(value).toFixed(0)}`}, 
                    {value: 0, format: (value) => `${value}%`}, 
                    svgWidth/2+svgHeight*5/20+35, 20+(svgHeight-10)*1/4 )}
                {kpi(IconGears, 'OEE', 
                    {value: data.oeeKPI.value, format: (value) => `${(value*100).toFixed(0)}%`}, 
                    {value: data.oeeKPI.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20, 20+(svgHeight-10)*2/4 )}
                {kpi(null, 'Quality', 
                    {value: 0, format: (value) => `${(value*100).toFixed(0)}`}, 
                    {value: 0, format: (value) => `${value}%`}, 
                    svgWidth/2+svgHeight*5/20+35, 20+(svgHeight-10)*2/4 )}
                {kpi(IconTruck, 'On-time', 
                    {value: data.onTime.value, format: (value) => `${(value*100).toFixed(0)}%`}, 
                    {value: data.onTime.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20, 20+(svgHeight-10)*3/4 )}
                {kpi(null, 'In-full', 
                    {value: data.inFull.value, format: (value) => `${(value*100).toFixed(0)}%`}, 
                    {value: data.inFull.change, format: (value) => `${value}%pt`}, 
                    svgWidth/2+svgHeight*5/20+35, 20+(svgHeight-10)*3/4 )}
            </g>

            {/* Footnote */}
            <g>
                <text 
                    x={svgWidth/2 - 15} 
                    y={svgHeight+5} 
                    fill={theme.palette.text.secondary}
                    fontSize={3}
                    textAnchor="end"
                >X.X   {currYear} </text>
                <text 
                    x={svgWidth/2 + 15} 
                    y={svgHeight+5} 
                    fill={theme.palette.text.secondary}
                    fontSize={3}
                    textAnchor="start"
                >X.X%   Previous quarter </text>
            </g>
        </svg>
    </Box>)
}

// const visualDataKeys = [
//     { key: 'revenueSales', change: 'relative' },
//     { key: 'netMargin', change: 'absolute' },
//     { key: 'totalCosts', change: 'relative' },
//     { key: 'workingCapital', change: 'relative' },
//     { key: 'co2unit_total', change: 'relative' },
//     { key: 'socialSustainability', change: 'relative' },
//     { key: 'forecastAccuracy', change: 'absolute' },
//     { key: 'forecastBias', change: 'absolute' },
//     { key: 'supplierReliabilityOTIF', change: 'absolute' },
//     // TODO Supplier risk data missing
//     { key: 'oeeKPI', change: 'absolute' },
//     // TODO Quality data missing
//     { key: 'onTime', change: 'absolute' },
//     { key: 'inFull', change: 'absolute' },
// ]

const visualDataKeys = [
    { key: 'revenueSales', change: 'relative', aggType: 'sum' },
    { key: 'netMargin', change: 'absolute', aggType: 'avg' },
    { key: 'costToServe', change: 'absolute', aggType: 'avg' },
    { key: 'totalCosts', change: 'relative', aggType: 'sum' },
    { key: 'workingCapital', change: 'relative', aggType: 'sum' },
    { key: 'co2unit_total', change: 'relative', aggType: 'sum' },
    { key: 'socialSustainability', change: 'absolute', aggType: 'avg' },
    { key: 'forecastAccuracy', change: 'absolute', aggType: 'avg' },
    { key: 'forecastBias', change: 'absolute', aggType: 'avg' },
    { key: 'supplierReliabilityOTIF', change: 'absolute', aggType: 'avg' },
    // TODO Supplier risk data missing
    { key: 'oeeKPI', change: 'absolute', aggType: 'avg' },
    // TODO Quality data missing
    { key: 'onTime', change: 'absolute', aggType: 'avg' },
    { key: 'inFull', change: 'absolute', aggType: 'avg' },
]



const getVisualData = (data, currQuarter) => {
    const dataToReturn = {}
    visualDataKeys.forEach( ({key, change, aggType}) => {
        let currentYTD = yearToDate(data, key, currQuarter, aggType)
        let lastYearYTD = yearToDate(data, key, currQuarter, aggType,-4)
        switch (change) {
            case 'relative': 
                dataToReturn[key] = { value: currentYTD, change: relativeDiff(currentYTD, lastYearYTD) }
                break;
            case 'absolute':
                dataToReturn[key] = { value: currentYTD, change: currentYTD - lastYearYTD }
                break;
        }
    })
    return dataToReturn
}


export default ExecutiveViewSummary;