import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { AppBar, Box, MenuItem, TextField, Typography } from '@mui/material';
import Logo from '../assets/DeloitteLogo.svg';
import { useTheme } from '@emotion/react';
import NavigationStar from './NavigationStar';

const starTransitionDuration = 1

function Header(props) {
    const theme = useTheme();
    const appBarRef = useRef(null);
    const [appBarHeight, setAppBarHeight] = useState(0);
    const starButtonRef = useRef(null);
    const [starButtonRight, setStarButtonRight] = useState(0);

    // Hook that set the app bar height to ensure correct sizing
    useLayoutEffect(() => {
        setAppBarHeight(appBarRef.current.offsetHeight);
        setStarButtonRight(starButtonRef.current.offsetWidth);
    }, [])
    
    const enlargedBoxRef = useRef(null);
    const [enlargedStar, setEnlargedStar] = useState(false);
    
    /**
     * Hook that alerts clicks outside of the enlarged box
    */
   useEffect(() => {
        // Color the correct underscores
        document.querySelectorAll("[id$=Bar]").forEach( (bar) => bar.style.stroke = 'none' )
        let bar = document.getElementById(`${props.department}Bar`)
        bar && (bar.style.stroke = '#86F200')

        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event) {
            if (enlargedBoxRef.current && !enlargedBoxRef.current.contains(event.target)) {
                setEnlargedStar(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [enlargedBoxRef, enlargedStar]);

    let navItems = props.tabs.map((tab) => (
        <Box key={`tab-${tab.name}`} color='text.secondary'>
            <NavLink to={tab.link} className={({ isActive }) => isActive ? 'active' : null}>
                <Typography variant='body1' component='div' sx={{ flexGrow: 1 }}>{tab.name}</Typography>
            </NavLink>
        </Box>
    ));

    const starButton = <Box ref={enlargedStar ? enlargedBoxRef : starButtonRef}
        sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            padding: theme.spacing(1),
            paddingRight: theme.spacing(1.5),
            height: enlargedStar ? '70vh' : theme.spacing(9),
            width: enlargedStar ? '100vh' : theme.spacing(9),
            backgroundColor: theme.palette.grey[800],
            borderRadius: `0 9999px 9999px 0`,
            zIndex: 100,
            transition: `width ${starTransitionDuration}s, height ${starTransitionDuration}s, border ${starTransitionDuration}s`
        }}
        onClick={() => setTimeout(setEnlargedStar(true), starTransitionDuration * 1000)}
    >
        <NavigationStar enlarged={enlargedStar} setEnlargedStar={setEnlargedStar} />
    </Box>

    return <Box id="Header" sx={(theme) => ({ flexGrow: 1, height: theme.spacing(theme.sizes.appBarHeight) })}>
        {/* DSN Star */}
        {/* {enlargedStar ? starFullScreen : null} */}
        {starButton}
        
        <AppBar ref={appBarRef} position='static' sx={{ flexDirection: 'row', alignItems: 'center', padding: theme.spacing(2), background: 'transparent' }}>
            {/* Navigation items */}
            <Box 
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexGrow: 1,
                    height: 'fit-content',
                    paddingLeft: `${appBarHeight}px`,
                    '> .MuiBox-root': {
                        padding: 1,
                        width: 'fit-content',
                    }
                }}
            >
                <Box key={`department`} color='primary.main'>
                    <Typography variant='body1' component='div' sx={{ flexGrow: 1 }}>{props.departmentName}</Typography>
                </Box>
                {navItems}
            </Box>
            {/* Logo */}
            {/* <Box sx={{ margin: 'auto' }}> */}
                <img src={Logo} style={{ height: theme.spacing(3), width: 'auto' }} alt='Deloitte logo' />
            {/* </Box> */}
        </AppBar>
    </Box>
}

export default Header;