import { Box } from "@mui/material"

const MainContentBox = (props) =>
<Box 
    sx={{ 
        height: `100%`, 
        maxHeight: `100%`, 
        minHeight: 0,
        width: `100%`, 
        display: "flex", 
        flexDirection: "column", 
        justifyContent: "space-between", 
        padding: 2, 
        paddingTop: 0,
        justifyContent: "space-between", 
    }} 
    {...props} 
/>

export default MainContentBox