import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, XAxis, YAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getLastYear, getQuarters } from "../../util/dataOperations";
import { renderDiamond, renderVerticalDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function IntelligentSupplySpend() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();
    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = [...aggregatedData.summary].slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    const materials = Object.keys(aggregatedData.byMaterial)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const extraMaverickCostsData = getLastYear(fromLastYearData, 'extraPercMaverickCosts', currQuarter, currYear)
    const total = <GridColumnContent>
        {/* Spend by category */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Spend by category <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-SpendByCategory`} width={'100%'} height={'80%'} data={getSpendByCategory(last8Quarters(aggregatedData.summary), currQuarter)} noBottomScale>
                    <XAxis allowDataOverflow dataKey="name" type="category" interval={0} />
                    <Bar name={`Contractual Spend`} dataKey={`contractualSpend`} stackId={0} fill={colors[2].color} animationDuration={1000}>
                        <LabelList dataKey={`contractPercentage`} position="middle" fill={colors[2].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                    </Bar>
                    <Bar name={`Maverick Spend`} dataKey={`maverickSpend`} stackId={0} fill={colors[3].color} animationDuration={1000}>
                        <LabelList dataKey={`maverickPercentage`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value*100).toFixed(1)}`} fontSize={10} />
                    </Bar>
                    <Line name={`Previous year`} dataKey={`prevYear`} stroke={theme.palette.grey[500]} />
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Extra maverick costs */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Extra maverick costs <i>(&euro;)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-ExtraMaverickCosts`} width={'100%'} height={'80%'} data={extraMaverickCostsData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={extraMaverickCostsData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={extraMaverickCostsData} />} 
                            fill={theme.palette.grey[500]} 
                            formatter={(value) => `${(value*100).toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const breakdown = <GridColumnContent>
        {/* Radial graph */}
        {/* <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                TODO Radial graph
            </CustomPaper>
        </Box> */}
        {/* Spend by finished good product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Spend by finished good product <i>(&euro;)</i> 
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-SpendByFinishedGood`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'costsRmTotalSpend', products, quarters)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`forecastproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${(value/1000000).toFixed(0)}M`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const material = <GridColumnContent>
        {/* Raw materials spend */}
        <Box height={`100%`}>
            <CustomPaper padding={1}>
                <Box height={'10%'}>
                    <Typography variant="body2" align="left">
                        Raw materials spend last quarter vs. one year earlier
                    </Typography>
                </Box>
                <SmallGraph 
                    key={`${key}-RamMaterialsSpend`} 
                    width={'100%'} 
                    height={'90%'} 
                    margin={{left: 40}}
                    layout='vertical' 
                    data={getSpendByMaterial(aggregatedData.byMaterial, materials)} noYAxis
                >
                    <YAxis allowDataOverflow dataKey="name" yAxisId={'axis0'} type="category" interval={0} />
                    <YAxis allowDataOverflow dataKey="name" yAxisId={'axis1'} type="category" interval={0} hide />
                    <XAxis hide allowDataOverflow domain={[0, dataMax => (dataMax * 1.2)]} type="number" />
                    <Bar name={`Last quarter`} dataKey={`prevQuarter`} yAxisId={'axis0'} maxBarSize={80} fill={colors[3].color} animationDuration={1000} legendType='none'>
                        <LabelList dataKey={`prevQuarter`} position="middle" fill={colors[3].contrastText} formatter={(value) => `${(value/1000000).toFixed(1)}M`} fontSize={12} />
                    </Bar>
                    <Bar 
                        name={`Last year`} 
                        dataKey={`prevYear`} 
                        yAxisId={'axis1'}
                        maxBarSize={80}
                        animationDuration={1000} 
                        legendType='none'
                        shape={(props) => renderVerticalDiamond(props, theme)}
                        />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Total Spend */}
        <GridItem>
            <CardTitle title="Total spend" />
            {total}
        </GridItem>
        {/* Spend Breakdown */}
        <GridItem>
            <CardTitle title="Spend breakdown" />
            {breakdown}
        </GridItem>
        {/* Raw Material Spend */}
        <GridItem>
            <CardTitle title="Raw material spend" />
            {material}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>

}

const getSpendByCategory = (data, currQuarter) => {   
    const dataToReturn = [
        {
            name: 'Q1',
        },
        {
            name: 'Q2',
        },
        {
            name: 'Q3',
        },
        {
            name: 'Q4',
        },
    ]

    if (data.length > 0) {
        
        dataToReturn[0].contractPercentage = data[4].contractsSpend / (data[4].size)
        dataToReturn[0].maverickPercentage = data[4].mavericksSpend / (data[4].size)
        dataToReturn[0].contractualSpend = data[4].costsRmTotalSpend * dataToReturn[0].contractPercentage
        dataToReturn[0].maverickSpend = data[4].costsRmTotalSpend * dataToReturn[0].maverickPercentage
        dataToReturn[0].total = data[4].costsRmTotalSpend
        dataToReturn[0].prevYear = data[0].costsRmTotalSpend
        
        if (currQuarter > 1) {
            dataToReturn[1].contractPercentage = data[5].contractsSpend / (data[5].size)
            dataToReturn[1].maverickPercentage = data[5].mavericksSpend / (data[5].size)
            dataToReturn[1].contractualSpend = data[5].costsRmTotalSpend * dataToReturn[1].contractPercentage
            dataToReturn[1].maverickSpend = data[5].costsRmTotalSpend * dataToReturn[1].maverickPercentage
            dataToReturn[1].total = data[5].costsRmTotalSpend
        }
        dataToReturn[1].prevYear = data[1].costsRmTotalSpend    

        if (currQuarter > 2) {
            dataToReturn[2].maverickPercentage = data[6].mavericksSpend / (data[6].size)
            dataToReturn[2].contractPercentage = data[6].contractsSpend / (data[6].size)
            dataToReturn[2].contractualSpend = data[6].costsRmTotalSpend * dataToReturn[2].contractPercentage
            dataToReturn[2].maverickSpend = data[6].costsRmTotalSpend * dataToReturn[2].maverickPercentage
            dataToReturn[2].total = data[6].costsRmTotalSpend
        }
        dataToReturn[2].prevYear = data[2].costsRmTotalSpend    

        if (currQuarter > 3) {
            dataToReturn[3].contractPercentage = data[7].contractsSpend / (data[7].size)
            dataToReturn[3].maverickPercentage = data[7].mavericksSpend / (data[7].size)
            dataToReturn[3].contractualSpend = data[7].costsRmTotalSpend * dataToReturn[3].contractPercentage
            dataToReturn[3].maverickSpend = data[7].costsRmTotalSpend * dataToReturn[3].maverickPercentage
            dataToReturn[3].total = data[7].costsRmTotalSpend
        }
        dataToReturn[3].prevYear = data[3].costsRmTotalSpend    
    }

    return dataToReturn
}

const getSpendByMaterial = (data, materials, offset=0, scalar=1) => {
    const dataToReturn = []
    materials.forEach( (material, index ) => {
        dataToReturn[index] = { name: material }
    })

    if (Object.keys(data).length > 0) {
        materials.forEach( (material, index) => {
            dataToReturn[index].prevQuarter = scalar * data[material].at(-1).costsRmTotalSpend + offset;
            dataToReturn[index].prevYear = data[material].length > 4 ? scalar * data[material].at(-5).costsRmTotalSpend + offset : null;
        })
    }

    return dataToReturn
}

export default IntelligentSupplySpend;