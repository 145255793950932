import { Box, Typography, useTheme } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import GridColumnContent from "../../components/StyledElements/GridColumnContent";
import CardTitle from "../../components/StyledElements/CardTitle";
import GridContainer from "../../components/StyledElements/GridContainer";
import GridItem from "../../components/StyledElements/GridItem";
import SmallGraph from "../../components/graphs/SmallGraph";
import { Bar, LabelList, Line, ReferenceLine, XAxis } from "recharts";
import CustomPaper from "../../components/StyledElements/CustomPaper";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { fromInterval, getByCategory, getLastYear, getQuarters } from "../../util/dataOperations";
import { renderDiamond } from "../../components/graphs/Diamond";
import ResponsiveLineLabel from "../../components/graphs/ResponsiveLineLabel";

function SmartFactoryOEE() {
    const { currFilters, latestYear, currYear, currQuarter, filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();
    const columns = 3;

    const [key, setKey] = useState('redraw')
    useEffect(() => {
        setKey('redraw')
        setTimeout(() => setKey('graph'), 10)
    }, [currFilters])

    // In a game there should always be enough data
    const dataLength = currQuarter+4
    const fromLastYearData = aggregatedData.summary.slice(Math.max(aggregatedData.summary.length - dataLength, 0))
    const last8Quarters = (data) => {
        let dataLength = 0
        if (Array.isArray(data)) dataLength = data.length
        else if (typeof data === "object" && Object.values(data)[0]) dataLength = Object.values(data).at(0).length
        else return undefined

        let yearDiff = latestYear - currYear
        let end = Math.max(yearDiff === 0 ? dataLength : dataLength - currQuarter - (yearDiff - 1) * 4, 8)
        let start = Math.max(end - 8, 0)
    
        return fromInterval(data, [start, end])
    }


    // const regions = Object.keys(aggregatedData.byRegion)
    const products = Object.keys(aggregatedData.byProduct)

    const quarters = getQuarters(currQuarter, currYear)

    // Get colors from theme
    const colors = theme.palette.graphColors

    const performanceEvolutionData = getLastYear(fromLastYearData, 'oeePerformance', currQuarter, currYear, 0, 100)
    const performance = <GridColumnContent>
        {/* Performance evolution */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-Performance`} width={'100%'} height={'80%'} data={performanceEvolutionData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={performanceEvolutionData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={performanceEvolutionData} />} 
                            fill={theme.palette.grey[500]}
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Performance by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-PerformanceByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'oeePerformance', products, quarters, 0, 100)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`performanceproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const availabilityData = getLastYear(fromLastYearData, 'oeeAvailability', currQuarter, currYear, 0, 100)
    const availability = <GridColumnContent>
        {/* Availability evolution */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-Availability`} width={'100%'} height={'80%'} data={availabilityData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={availabilityData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={availabilityData} />} 
                            fill={theme.palette.grey[500]}
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Availability by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-AvailabilityByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'oeeAvailability', products, quarters, 0, 100)}>
                <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`availabilityproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const qualityData = getLastYear(fromLastYearData, 'oeeQuality', currQuarter, currYear, 0, 100)
    const quality = <GridColumnContent>
        {/* Quality evolution */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        Evolution <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-Quality`} width={'100%'} height={'80%'} data={qualityData}>
                    <XAxis allowDataOverflow dataKey="name" type="category" padding={{left: 20, right: 20}} interval={0} />
                    <Line name={`${currYear}`} type="monotone" dataKey={`${currYear}`} stroke={theme.palette.primary.main} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear}`} 
                            content={<ResponsiveLineLabel thisKey={currYear} otherKey={currYear-1} data={qualityData} />} 
                            fill={theme.palette.primary.main} 
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                    <Line name={`${currYear-1}`} type="monotone" dataKey={`${currYear-1}`} stroke={theme.palette.grey[500]} strokeWidth={1.5} animationDuration={1000}>
                        <LabelList 
                            dataKey={`${currYear-1}`} 
                            content={<ResponsiveLineLabel thisKey={currYear-1} otherKey={currYear} data={qualityData} />} 
                            fill={theme.palette.grey[500]}
                            formatter={(value) => `${value.toFixed(1)}`} 
                        />
                    </Line>
                </SmallGraph>
            </CustomPaper>
        </Box>
        {/* Quality evolution by product */}
        <Box height={`calc(50% - ${theme.spacing(1)})`}>
            <CustomPaper padding={1}>
                <Box height={'15%'}>
                    <Typography variant="body2" align="left">
                        By product <i>(%)</i>
                    </Typography>
                </Box>
                <SmallGraph key={`${key}-QualityByProduct`} width={'100%'} height={'80%'} data={getByCategory(last8Quarters(aggregatedData.byProduct), 'oeeQuality', products, quarters, 0, 100)}>
                    <XAxis xAxisId={'axis0'} allowDataOverflow dataKey="name" type="category" interval={0} />
                    <XAxis xAxisId={'axis1'} hide allowDataOverflow dataKey="name" type="category" interval={0} />
                    <ReferenceLine xAxisId={'axis0'} y={0} stroke="gray" strokeWidth={1.5} strokeOpacity={0.65} />
                    {[...quarters].splice(4, 4).map( (quarter, index) => 
                        <Fragment key={`qualityproduct-${quarter}`}>
                        {/* Bar of current year */}
                        <Bar name={`${quarter}`} dataKey={`${quarter}`} xAxisId={'axis0'} fill={colors[index].color} animationDuration={1000}>
                            <LabelList dataKey={`${quarter}`} position="middle" angle={270} fill={colors[index].contrastText} formatter={(value) => `${value.toFixed(1)}`} fontSize={10} />
                        </Bar>
                        {/* Diamond to indicate previous year */}
                        <Bar 
                            name={`${quarters[index]}`} 
                            dataKey={`${quarters[index]}`} 
                            xAxisId={'axis1'}
                            fill={theme.palette.grey[700]} 
                            fillOpacity={0.8}
                            animationDuration={1000} 
                            legendType='none'
                            shape={ (props) => { if (index === 3) return renderDiamond(props, theme) } }
                        />
                        </Fragment>
                    )}
                    <Line name={`Previous year`} dataKey={`nothing`} xAxisId={'axis0'} stroke={theme.palette.diamond.color+'99'} legendType='diamond' />
                </SmallGraph>
            </CustomPaper>
        </Box>
    </GridColumnContent>

    const grid = <GridContainer columns={columns} spacing={theme.spacing(2)}>
        {/* Performance */}
        <GridItem>
            <CardTitle title="Performance" />
            {performance}
        </GridItem>
        {/* Availability */}
        <GridItem>
            <CardTitle title="Availability" />
            {availability}
        </GridItem>
        {/* Quality */}
        <GridItem>
            <CardTitle title="Quality" />
            {quality}
        </GridItem>
    </GridContainer>


    return <MainContentBox>
        {grid}
    </MainContentBox>
}

export default SmartFactoryOEE;