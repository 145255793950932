import { useTheme } from "@mui/material";
import { useOutletContext } from "react-router";
import MainContentBox from "../../components/StyledElements/MainContentBox";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Line,
  Marker
} from "react-simple-maps";
import { ReactComponent as IconWarehouse } from "../../assets/icons/IconWarehouse.svg"
import { ReactComponent as IconFactory } from "../../assets/icons/IconFactory.svg"

function DynamicFulfillmentDynamicMap() {
    const { filteredData, aggregatedData } = useOutletContext();

    const theme = useTheme();

    const origins = Object.keys(aggregatedData.moveByOrigin)
    const destinations = Object.keys(aggregatedData.moveByDestination)

    const keyToVisualize = 'costsTransport'

    // TODO get domain from data
    const domain = getDataDomain(filteredData.moveSummary, keyToVisualize)
    const widthScale = scaleLinear()
        .domain(domain)
        .range(theme.dynamicMap.scale);

    const geoUrl = "/features.json";
        
    const map = <ComposableMap
            width={800}
            height={600}
            projection="geoMercator"
            projectionConfig={{
                rotate: [-10, 0, 0],
                scale: 0
            }}
        >
            {/* <Graticule stroke="#E4E5E6" strokeWidth={0.5} /> */}
            <Geographies geography={geoUrl}>
                {({ geographies }) =>
                    geographies.map((geo) => {
                        return (
                        <Geography
                            key={geo.rsmKey}
                            geography={geo}
                            stroke={theme.palette.grey[400]}
                            fill={theme.palette.grey[500]}
                        />
                        );
                    })
                }
            </Geographies>
            {filteredData.moveSummary.length > 0 ? 
                <>
                    {filteredData.moveSummary.filter(entry => entry.gameRound === filteredData.moveSummary.at(-1).gameRound).map( (connection) => 
                        <Line
                            from={[connection.originLongitude, connection.originLatitude]}
                            to={[connection.destLongitude, connection.destLatitude]}
                            stroke={
                                filteredData.moveSummary.find( element => 
                                        element.gameRound === connection.gameRound-1 && 
                                        element.originID === connection.originID && 
                                        element.destID === connection.destID
                                    )[keyToVisualize] < connection[keyToVisualize] ? theme.palette.kpi.success : theme.palette.kpi.fail}
                            strokeWidth={widthScale(connection[keyToVisualize])}
                            strokeOpacity={theme.dynamicMap.lineOpacity}
                            strokeLinecap="round"
                        />
                    )}
                    {origins.map( (origin) => {
                        // TODO improve this
                        let data = filteredData.moveSummary.filter(entry => entry.originID.toString() === origin.toString())[0];
                        return <Marker 
                            coordinates={[data.originLongitude, data.originLatitude]} 
                            style={{ 
                                default: { fill: theme.palette.common.white }, 
                                hover: { fill: theme.palette.primary.light }, 
                                pressed: { fill: theme.palette.primary.light }
                            }} 
                        >
                            {data.originType === "CUSTOMER" ? <g><circle r={5} fill="#292929" stroke="#86F200" stroke-width="1" /></g>
                            : data.originType === "FGWH" ? <g><circle r={15} fill="#292929" stroke="#86F200" stroke-width="1" /><IconWarehouse width={20} height={20} x={-10} y={-10} /></g> 
                            : <g><circle r={15} fill="#292929" stroke="#86F200" stroke-width="1" /><IconFactory width={20} height={20} x={-10} y={-10} /></g> }
                        </Marker>
                    })}
                    {destinations.map( (destination) => {
                        // TODO improve this
                        let data = filteredData.moveSummary.filter(entry => entry.destID.toString() === destination.toString())[0];
                        return <Marker coordinates={[data.destLongitude, data.destLatitude]} style={{ default: { fill: theme.palette.common.white }, pressed: { fill: theme.palette.primary.light },}} >
                            {data.destType === "CUSTOMER" ? <g><circle r={5} fill="#292929" stroke="#86F200" stroke-width="1" /></g>
                            : data.destType === "FGWH" ? <g><circle r={15} fill="#292929" stroke="#86F200" stroke-width="1" /><IconWarehouse width={20} height={20} x={-10} y={-12} /></g> 
                            : <g><circle r={15} fill="#292929" stroke="#86F200" stroke-width="1" /><IconFactory width={20} height={20} x={-9} y={-12} /></g> }
                        </Marker>
                    })}
                </>
            : null}
        </ComposableMap>

    return <MainContentBox>
        {map}
    </MainContentBox>
}

const getDataDomain = (data, key) => {
    if (data.length === 0) return [0,1]

    let domain = [Infinity, -Infinity]
    data.forEach((entry) => {
        domain[0] = Math.min(domain[0], entry[key])
        domain[1] = Math.max(domain[1], entry[key])
    })

    return domain
}

export default DynamicFulfillmentDynamicMap;