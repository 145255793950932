import { Box, CircularProgress, Typography, useTheme } from '@mui/material';
import { height } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Tooltip, Legend, ComposedChart, YAxis, PieChart, Pie, Cell } from 'recharts';
import SmallGraphContainer from './SmallGraphContainer';

function ServiceLevelDonutGraphMultiLayer(props) {
    
    const { height, width, arcWidth, arcPadding, data, currQuartile, currYear } = props
    
    const [popup, setPopup] = useState(-1)

    const fullWidth = arcWidth*data.products.length + arcPadding*(data.products.length-1)
    const innerRadius = (1-fullWidth)*100
    
    const theme = useTheme();
    
    // Get colors from theme
    const colors = theme.palette.graphColors

    // data.current = 0.85
    // data.prevQuartile = 0.75
    // data.prevYear = 0.84
    // data.target = 0.95

    if (!data.products[0].current) {
        return <CircularProgress />
    }

    const inside = (index) => innerRadius + (arcWidth + arcPadding) * index * 100
    const outside = (index) => inside(index) + arcWidth*100
    const getAngle = (percentage) => 0.5*Math.PI - 2*Math.PI*percentage
    const coordsFromAngle = (angle, scalar) => [Math.cos(angle) * scalar, -Math.sin(angle) * scalar]
    const coordsToString = ([x, y]) => `${x} ${y}`
    const posFromData = (percentage, scalar) => coordsToString(coordsFromAngle(getAngle(percentage), scalar))
    const diamond = (angle, inner, outer) => {
        const innerPoint = coordsFromAngle(angle, inner)
        const midPoint = coordsFromAngle(angle, (outer+inner)/2)
        const leftPoint = [midPoint[0] + (innerPoint[1]-midPoint[1]), midPoint[1] - (innerPoint[0]-midPoint[0])]
        const rightPoint = [midPoint[0] - (innerPoint[1]-midPoint[1]), midPoint[1] + (innerPoint[0]-midPoint[0])]

        return `M ${coordsToString(innerPoint)}
                L ${coordsToString(leftPoint)}
                L ${coordsToString(coordsFromAngle(angle, outer))}
                L ${coordsToString(rightPoint)}
                L ${coordsToString(innerPoint)}`
    }
    const arc = (angle, inner, outer, fill) => {
        const innerPoint = coordsFromAngle(angle, inner)
        const midPoint = coordsFromAngle(angle, (outer+inner)/2)
        const leftPoint = [midPoint[0] + (innerPoint[1]-midPoint[1]), midPoint[1] - (innerPoint[0]-midPoint[0])]
        const rightPoint = [midPoint[0] - (innerPoint[1]-midPoint[1]), midPoint[1] + (innerPoint[0]-midPoint[0])]

        return <path d={`M 0 -${outer}
            A ${outer} ${outer} 0 1 1 ${posFromData(angle, outer)}
            L ${posFromData(angle, inner)}
            A ${inner} ${inner} 0 1 0 0 -${inner}
            L 0 -${outer}`}
        fill={fill}
    />
    }

    return (<Box style={{ display: 'flex', flexDirection: "row", height: height, width: '100%', userSelect: "none" }} >
        <Box sx={{display: 'flex', flexDirection: 'column', textAlign: 'left', width: '40%'}}>
            { popup >= 0 ?
                <>
                    <Typography fontWeight={'bold'} color={colors[popup].color}>{data.products[popup].name}</Typography>
                    <Typography variant='body2'>This quarter: {(data.products[popup].current*100).toFixed(0)}%</Typography>
                    <Typography variant='body2'>Last quarter: {(data.products[popup].prevQuarter*100).toFixed(0)}%</Typography>
                    <Typography variant='body2'>Last year: {(data.products[popup].prevYear*100).toFixed(0)}%</Typography>
                </>
            :
                <>
                    <Typography fontWeight={'bold'}>&nbsp;</Typography>
                    <Typography variant='body2'>Average: {round(data.average, 1)}%</Typography>
                </>
            }
        </Box>
        <svg style={{
                display: 'flex',
                marginLeft: 'auto',
                width: '60%',
                fontFamily: theme.typography.fontFamily, 
                fontSize: theme.typography.body2.fontSize
            }} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={`-110 -110 220 220`} 
            onClick={() => setPopup(-1)}
        >
            {/* Current service level for each product */}
            { data.products.map( (product, index) => 
                <g key={`radial-${product.name}`} onClick={(event) => { setPopup(index); event.stopPropagation() }}>
                    {arc(product.current, inside(index), outside(index), colors[index].color)}
                    <path d={diamond(getAngle(product.prevQuarter), inside(index) + arcWidth*10, outside(index) - arcWidth*10)} 
                        fill={theme.palette.grey[500]} />
                    <path d={diamond(getAngle(product.prevYear), inside(index) + arcWidth*10, outside(index) - arcWidth*10)} 
                        fill={theme.palette.grey[600]} />
                </g>
            ) }

            {/* Average */}
            <path d={`M ${posFromData(data.average, 105)}
                    L ${posFromData(data.average, innerRadius-5)}`}
                stroke={theme.palette.grey[700]} 
                strokeWidth={3}
            />
        </svg>
    </Box>)
}

const round = (number, digits) => {
    return Math.round(number * Math.pow(10, digits))/Math.pow(10, digits)
}

export default ServiceLevelDonutGraphMultiLayer;