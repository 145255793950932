import { Box, CircularProgress, useTheme } from '@mui/material';
import React from 'react'

function SustainabilityTree(props) {
    
    const { height, width, data } = props

    const theme = useTheme();

    if (!data) {
        return <Box margin={'auto'}>
            <CircularProgress />
        </Box>
    }

    return (<Box style={{ userSelect: "none" }} >
        <svg style={{
                position: 'relative', 
                top: '0', left: '0', 
                fontFamily: theme.typography.fontFamily, 
                fontSize: `calc(${theme.typography.body2.fontSize}/3)`
            }} 
            width={width} 
            height={height} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={`0 0 100 150`} 
        >
            {/* Tree trunk */}
            <path d={`M 40 138 
                    L 65 138
                    C 59 109 50 84 52 54 
                    C 40 84 38 120 40 138`} 
                    fill={theme.palette.grey[700]} />
            {/* Leaf bottom left */}
            <path d={`M 50 53 
                    Q 17 37 0 67 
                    Q 32 80 50 55 
                    L 43 56 
                    L 50 53`} 
                    fill={data.co2[0] ? data.co2[1] ? theme.palette.treeColors.green : theme.palette.treeColors.orange : theme.palette.treeColors.red } />
            <text 
                x={25} 
                y={61}
                textAnchor="middle"
                fill={theme.palette.text.primary}
            >
                CO<tspan dy='1' fontSize={`calc(${theme.typography.caption.fontSize}/3)`}>2</tspan><tspan dy='-1'> footprint</tspan>
            </text>

            {/* Leaf top left */}
            <path d={`M 52 51 
                    Q 59 20 35 0 
                    Q 18 35 50 52 
                    L 48 44 
                    L 52 51`} 
                    fill={data.waste[0] ? data.waste[1] ? theme.palette.treeColors.green : theme.palette.treeColors.orange : theme.palette.treeColors.red } />
            <text 
                x={41} 
                y={28}
                textAnchor="middle"
                fill={theme.palette.text.primary}
            >
                Recycled
                <tspan x={41} y={33}>waste</tspan>
            </text>

            {/* Leaf top right */}
            <path d={`M 55 53 
                    Q 88 55 99 20 
                    Q 59 20 53 51 
                    L 58 48 
                    L 55 53 `} 
                    fill={data.social[0] ? data.social[1] ? theme.palette.treeColors.green : theme.palette.treeColors.orange : theme.palette.treeColors.red } />
            <text 
                x={75} 
                y={35}
                textAnchor="middle"
                fill={theme.palette.text.primary}
            >
                Social 
                <tspan x={73} y={40}>sustainability</tspan>
            </text>

            {/* Leaf bottom right */}
            <path d={`M 54 57 
                    Q 60 84 100 84 
                    Q 88 51 55 55 
                    L 59 59 
                    L 54 57`} 
                    fill={data.water[0] ? data.water[1] ? theme.palette.treeColors.green : theme.palette.treeColors.orange : theme.palette.treeColors.red } />
            <text 
                x={75} 
                y={68}
                textAnchor="middle"
                fill={theme.palette.text.primary}
            >
                Clean water
                <tspan x={75} y={73}>usage</tspan>
            </text>

            {/* Legend */}
            {/* Green leaf */}
            <path d={`M 2 144.5 
                    Q 7 140 12 145 
                    Q 7 150 2 145.5 
                    L 4 145 
                    L 2 144.5`} 
                    fill={theme.palette.treeColors.green} />
            <text 
                x={14} 
                y={145.5}
                textAnchor="left"
                fill={theme.palette.text.secondary}
                fontSize={3}
            >
                '30 and '50 goals
                <tspan x={14} y={148.5}>on track</tspan>
            </text>
            {/* Orange leaf */}
            <path d={`M 40 144.5 
                    Q 45 140 50 145 
                    Q 45 150 40 145.5 
                    L 42 145 
                    L 40 144.5`} 
                    fill={theme.palette.treeColors.orange} />
            <text 
                x={52} 
                y={145.5}
                textAnchor="left"
                fill={theme.palette.text.secondary}
                fontSize={3}
            >
                '30 goals
                <tspan x={52} y={148.5}>on track</tspan>
            </text>
            {/* Red leaf */}
            <path d={`M 70 144.5 
                    Q 75 140 80 145 
                    Q 75 150 70 145.5 
                    L 72 145 
                    L 70 144.5`} 
                    fill={theme.palette.treeColors.red} />
            <text 
                x={82} 
                y={145.5}
                textAnchor="left"
                fill={theme.palette.text.secondary}
                fontSize={3}
            >
                Both goals
                <tspan x={82} y={148.5}>off track</tspan>
            </text>
        </svg>
    </Box>)
}

export default SustainabilityTree;