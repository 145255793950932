import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"

const DropDown = (props) => 
<FormControl>
    <InputLabel>{props.type.toUpperCase()}</InputLabel>
    <Select
        id={`dropdown-${props.type}`}
        size='small'
        label={props.type.toUpperCase()}
        value={props.value}
        onChange={(event) => props.handleChange(props.type, event)}
    >
        {props.options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
                {option.label}
            </MenuItem>
        ))}
    </Select>
</FormControl>

export default DropDown