import { Box, CircularProgress, useTheme } from '@mui/material';
import React from 'react'
import { round } from '../../util/mathOperations';

function ProgressArc(props) {
    
    const { height, width, data, innerRadius = 35, outerRadius = 75 } = props

    const theme = useTheme();

    if (!data) {
        return <Box margin={'auto'}>
            <CircularProgress />
        </Box>
    }

    const getAngle = (percentage) => Math.PI - Math.PI*percentage
    const getAngleDegree = (percentage) => 180 - 180*percentage
    const coordsFromAngle = (angle, scalar) => [Math.cos(angle) * scalar, 100-Math.sin(angle) * scalar]
    const coordsToString = ([x, y]) => `${x} ${y}`
    const posFromData = (percentage, scalar) => coordsToString(coordsFromAngle(getAngle(Math.max(percentage, 0)), scalar))

    return (<Box style={{ userSelect: "none" }} >
        <svg style={{
                position: 'relative', 
                top: '0', left: '0', 
                fontFamily: theme.typography.fontFamily, 
                fontSize: `calc(${theme.typography.body2.fontSize}/2)`
            }} 
            width={width} 
            height={height} 
            xmlns="http://www.w3.org/2000/svg" 
            viewBox={`-105 0 210 120`} 
        >
            {/* Filler arc */}
            <path d={`M -${outerRadius} 100 
                    A 1 1 0 0 1 ${outerRadius} 100 
                    L ${innerRadius} 100 
                    A 1 1 0 0 0 -${innerRadius} 100 
                    L -${outerRadius} 100`} 
                    fill={theme.palette.grey[700]} />
            {/* Main arc */}
            <path d={`M -${outerRadius} 100 
                    A ${outerRadius} ${outerRadius} 0 0 1 ${posFromData(data.percentage, outerRadius)} 
                    L ${posFromData(data.percentage, innerRadius)} 
                    A ${innerRadius} ${innerRadius} 0 0 0 -${innerRadius} 100 
                    L -${outerRadius} 100`} 
                    fill={data.target30[1] ? data.target50[1] ? theme.palette.treeColors.green : theme.palette.treeColors.orange : theme.palette.treeColors.red } />
            {/* Center text */}
            <text 
                x={0} 
                y={95}
                textAnchor="middle"
                fill={theme.palette.text.secondary}
            >
                {round(data.percentage*100,1)}%
            </text>

            {/* Target 2030 */}
            <path d={`M ${posFromData(data.target30[0], innerRadius)} 
                    L ${posFromData(data.target30[0], outerRadius+5)}`} 
                    stroke={theme.palette.treeColors.orange} />
            <text 
                x={coordsFromAngle(getAngle(Math.max(data.target30[0], 0)), outerRadius + 15)[0]} 
                y={coordsFromAngle(getAngle(Math.max(data.target30[0], 0)), outerRadius + 15)[1] + 2.5}
                textAnchor="middle"
                fill={theme.palette.treeColors.orange}
            >
                {data.target30[0]*100}%
            </text>

            {/* Target 2050 */}
            <path d={`M ${posFromData(data.target50[0], innerRadius)} 
                    L ${posFromData(data.target50[0], outerRadius+5)}`} 
                    stroke={theme.palette.treeColors.green} />
            <text 
                x={coordsFromAngle(getAngle(Math.max(data.target50[0], 0)), outerRadius + 15)[0]} 
                y={coordsFromAngle(getAngle(Math.max(data.target50[0], 0)), outerRadius + 15)[1] + 2.5}
                textAnchor="middle"
                fill={theme.palette.treeColors.green}
            >
                {data.target50[0]*100}%
            </text>

            {/* Legend */}
            {/* 2030 goal */}
            <path d={`M -60 107.5 
                    L -50 107.5`} 
                    stroke={theme.palette.treeColors.orange} />
            <text 
                x={-45} 
                y={110}
                textAnchor="left"
                fill={theme.palette.text.secondary}
            >
                2030 target
            </text>
            {/* 2050 goal */}
            <path d={`M 10 107.5 
                    L 20 107.5`} 
                    stroke={theme.palette.treeColors.green} />
            <text 
                x={25} 
                y={110}
                textAnchor="left"
                fill={theme.palette.text.secondary}
            >
                2050 target
            </text>

        </svg>
    </Box>)
}

export default ProgressArc;