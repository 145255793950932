import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

function LoadingPage() {
    return <Box height='100%' padding='20% 0 0 0'>
        <Typography variant="h5">Currently loading the data, please wait...</Typography>
        <CircularProgress />
    </Box>
}

export default LoadingPage